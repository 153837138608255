import { useEffect, useState } from 'react';

const ONE_MIN_REFRESH_INTERVAL = 1000 * 60;

export const useRefresh = () => {
  const [oneMin, setOneMin] = useState(0);

  useEffect(() => {
    const interval = setInterval(async () => {
      setOneMin(prev => prev + 1);
    }, ONE_MIN_REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return { oneMinRefresh: oneMin };
};
