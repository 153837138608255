import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    overflow-y: overlay;
  }
  html,
  body {
    height: 100%;
    width: 100%;
    word-break: inherit;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: Jost, Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  button, div, input, select {
    font-family: inherit;
    font-size: inherit;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }
  
  ::-webkit-scrollbar-track {
    box-shadow: none; 
    border-radius: 8px;

    
  }

  // used to handle position of notifications container 
  #chakra-toast-manager-top-right{
    top: 152px !important
  }
   
  ::-webkit-scrollbar-thumb {
    background: #374151; 
    border-radius: 4px;
  }
 
`;
