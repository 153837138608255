import { Image } from '@chakra-ui/react';
import { useMemo } from 'react';
import { QuestionIcon } from 'app/assets/icons';

export default function ImageLogo({
  symbol,
  size = '50px',
  margin = '0 4px 0 0',
  type,
  nextPair = false,
}: {
  symbol: string | undefined;
  type?: string;
  size?: string;
  margin?: string;
  nextPair?: boolean;
}) {
  const srcs: string = useMemo(() => {
    if (type === 'network') return `/images/networks/${symbol}.png`;
    if (type === 'languages') return `/images/languages/${symbol}.png`;

    return `/images/tokens/${symbol}.png`;
  }, [symbol, type]);

  return (
    <Image
      w={size}
      position={nextPair ? 'relative' : '-moz-initial'}
      right="5"
      margin={margin}
      src={srcs}
      alt={`${symbol ?? 'token'} logo`}
      fallback={<QuestionIcon w={size} h={size} />}
    />
  );
}
