import {
  BASE_TOKEN_ADDRESS,
  CHAIN_ID,
  COVALENT_API_KEY,
} from 'constants/index';
import { Address, QuoteToken } from 'constants/types';
import { tokenData } from './types';
import { NETWORK } from 'constants/networks';
import { EcosystemFarmType } from 'app/interfaces/Farm';

export const request = async (_url: string) => {
  try {
    const controller = new AbortController();
    const { signal } = controller;

    const response = await fetch(_url, { signal });

    return response.json();
  } catch (e) {
    return { data: null };
  }
};

export interface PoolToken {
  contract_ticker_symbol: string;
  contract_name: string;
  volume_in_24h?: number;
  contract_address: string;
}

export interface PoolData {
  exchange: string;
  token_0: PoolToken;
  token_1: PoolToken;
  volume_in_24h: string;
  total_liquidity_quote: number;
  total_supply: string;
  fee_24h_quote: string;
  volume_24h_quote: string;
  lpApyNumber: number;
  lpApy: string;
  aprRange: string[];
}

export interface FarmConfigWithStatistics {
  pid: number;
  lpSymbol: string;
  lpAddresses: Address;
  tokenSymbol: string;
  tokenAddresses: Address;
  gaugeAddressH?: string;
  quoteTokenSymbol: QuoteToken;
  quoteTokenAdresses: Address;
  multiplier?: string;
  isTokenOnly?: boolean;
  isStakingPool?: boolean;
  isLPToken?: boolean;
  isCommunity?: boolean;
  isApe?: boolean;
  isPsc?: boolean;
  isOldPsc?: boolean;
  isGauge?: boolean;
  dual?: {
    rewardPerBlock: number;
    earnLabel: string;
    endBlock: number;
  };
  gaugeAddress?: string;
  statistics: PoolData;
  ecosystem?: EcosystemFarmType;
  rewardToken?: string;
}

export const getWalletData = async (_address: string, _chainId = CHAIN_ID) => {
  const source = `https://api.covalenthq.com/v1/${_chainId}/address/${_address}/balances_v2/?key=${COVALENT_API_KEY}`;
  const response = await request(source);

  return response.data;
};

export const getLiquidityPoolsData = async (chainId = CHAIN_ID) => {
  const source = `https://api.covalenthq.com/v1/${chainId}/xy=k/spiritswap/pools/?quote-currency=USD&&key=${COVALENT_API_KEY}`;
  const { data } = await request(source);

  if (data && data.items) {
    const response: PoolData[] = data.items;
    return response;
  }

  return [];
};

export const getLiquityPoolData = async (
  _lpAddress: string,
  chainId = CHAIN_ID,
) => {
  const source = `https://api.covalenthq.com/v1/${chainId}/xy=k/spiritswap/pools/address/${_lpAddress}/?key=${COVALENT_API_KEY}`;

  const { data } = await request(source);
  if (data && data.items) {
    const [pool] = data.items;

    return pool;
  }

  return undefined;
};

// Gets token and price data from address array provided
//
export const getTokensDetails = async (
  _addresses: string[],
  chainId = CHAIN_ID,
) => {
  const timeNow = Date.now();
  const oneDayAgo = timeNow - 1000 * 60 * 60 * 24;
  const format = (_date: Date) => {
    const yyyy = _date.getFullYear();
    const mm = _date.getMonth() + 1;
    const dd = _date.getDate();

    return `${yyyy}-${mm < 10 ? `0${mm}` : mm}-${dd < 10 ? `0${dd}` : dd}`;
  };

  const from = format(new Date(oneDayAgo));
  const to = format(new Date(timeNow));

  const source = `https://api.covalenthq.com/v1/pricing/historical_by_addresses_v2/${chainId}/USD/${_addresses}/?key=${COVALENT_API_KEY}&quote-currency=USD&format=JSON&from=${from}&to=${to}`;

  const { data } = await request(source);

  if (data && data.length > 0) {
    const walletItems: tokenData[] = data.map(tokenData => ({
      address: tokenData.contract_address,
      amount: '0',
      full_name: tokenData.contract_name,
      liquidity: false,
      name: tokenData.contract_ticker_symbol,
      rate: tokenData.items[0]?.price,
      rate_24: tokenData.items[1]?.price,
      staked: false,
      symbol: tokenData.contract_ticker_symbol,
      title: tokenData.contract_name,
      tokens: [tokenData.contract_name],
      usd: '0',
      usd_24: '0',
      percentaje_change_24:
        ((tokenData.items[0]?.price - tokenData.items[1]?.price) /
          tokenData.items[1]?.price) *
        100,
    }));

    return walletItems;
  }

  return [];
};

export const getTokenUsdPrice = async (
  tokenAddress: string,
  chainId = CHAIN_ID,
) => {
  let source;
  if (tokenAddress === BASE_TOKEN_ADDRESS) {
    source = `https://api.covalenthq.com/v1/pricing/historical_by_addresses_v2/${chainId}/USD/${NETWORK[chainId].wrappedToken.address}/?quote-currency=USD&format=JSON&key=${COVALENT_API_KEY}`;
  } else {
    source = `https://api.covalenthq.com/v1/pricing/historical_by_addresses_v2/${chainId}/USD/${tokenAddress}/?quote-currency=USD&format=JSON&key=${COVALENT_API_KEY}`;
  }

  const { data } = await request(source);

  if (data && data[0].prices[0]) {
    const response = data[0].prices[0].price;
    return response;
  }

  return null;
};
