import { FC, useEffect, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavigationDropdown } from 'app/components/NavigationDropdown';
import { ConnectWallet } from 'app/components/ConnectWallet';
import {
  Box,
  HStack,
  useDisclosure,
  Button,
  Text,
  Image,
  Skeleton,
} from '@chakra-ui/react';
import Metamask from 'app/assets/wallets/metamask.png';
import useLogin from 'app/connectors/EthersConnector/login';
import {
  WalletIcon,
  SettingsIcon,
  SoullyIcon,
  SpiritDesktopIcon,
  SpiritMobileIcon,
  WalletExitIcon,
} from 'app/assets/icons';

import type { NavMenuProps, Props } from './TopBar.d';
import {
  Wrapper,
  ContentWrapper,
  TopWrapper,
  StyledTokenInfoBar,
  BottomWrapper,
  MenuWrapper,
  MenuButton,
  MoreButtonWrapper,
  MoreButton,
  CaretDownImage,
  NavDropdownWrapper,
  StyledNavLink,
  StyledSettingsModal,
  MoneyHandIcon,
  BridgeIcon,
  SwapIcon,
  FarmsIcon,
  HomeIcon,
  InSpiritIcon,
} from './styles';

import {
  ANALYTICS,
  BRIDGE,
  DOCS,
  FARMS,
  HOME,
  INSPIRIT,
  LENDANDBORROW,
  LIQUIDITY,
  NFTS,
  STORE,
  SWAP,
  GOVERNANCE,
  DSYNTHS,
  APEMODE,
} from 'app/router/routes';
import { UseWallet } from 'app/utils';
import { openInNewTab } from 'app/utils/redirectTab';
import { useAppDispatch } from 'store/hooks';
import useMobile from 'utils/isMobile';
import { setUnexpectedError } from 'store/errors';
import { WFTM, SPIRIT } from 'constants/index';
import useGetTokensPrices from 'app/hooks/useGetTokensPrices';
import useMarketCapPrice from 'app/hooks/useMarketCapPrice';
import useTvlPrice from 'app/hooks/useTvlPrice';
import WalletConnect from 'app/assets/wallets/wallet-connect.png';
import { useRefresh } from 'app/hooks/useRefresh';

const navMenus = [
  { ...HOME, icon: <HomeIcon /> },
  { ...SWAP, icon: <SwapIcon /> },
  { ...BRIDGE, icon: <BridgeIcon /> },
  { ...LIQUIDITY, icon: <MoneyHandIcon /> },
  { ...FARMS, icon: <FarmsIcon /> },
  { ...INSPIRIT, icon: <InSpiritIcon /> },
];

const navDropdownMenus = [
  LENDANDBORROW,
  DSYNTHS,
  STORE,
  ANALYTICS,
  NFTS,
  DOCS,
  GOVERNANCE,
  APEMODE,
];

const NavMenuItem: FC<NavMenuProps> = ({ menu, isActive }) => {
  const dispatch = useAppDispatch();
  const handleResetError = () => dispatch(setUnexpectedError(false));
  return (
    <StyledNavLink to={menu.path} onClick={handleResetError}>
      <MenuButton isActive={isActive}>
        {menu.title}
        {isActive && menu.icon}
      </MenuButton>
    </StyledNavLink>
  );
};

const TopBar: FC<Props> = () => {
  const translationPath = 'common.topBar';
  const menuTranslationPath = 'common.menu';
  const { account, isLoggedIn } = UseWallet();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { handleLogout, _connector } = useLogin();
  const isMobile = useMobile();
  const [scroll, setScroll] = useState<boolean>(true);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [outsideClicked, setOutsideClicked] = useState<boolean>(false);
  const [menuIndex, setMenuIndex] = useState<number>(0);
  const [selectedLanguageId, setSelectedLanguageId] = useState<string>(
    i18n.language,
  );

  const { oneMinRefresh } = useRefresh();
  const { marketCap, loadingMarketCap } = useMarketCapPrice();
  const { TVL, loadingTVL } = useTvlPrice();

  const { tokensPrices, loadingPrices } = useGetTokensPrices({
    tokenAddresses: [WFTM.address, SPIRIT.address],
    refresh: oneMinRefresh,
  });

  const tokenInfos = () => {
    let info = [
      {
        name: 'FTM',
        priceCurrency: '$',
        price: loadingPrices ? null : tokensPrices?.FTM.rate ?? 0,
        rate: loadingPrices
          ? null
          : tokensPrices?.FTM.percentaje_change_24 ?? 0,
      },
      { name: 'TVL', priceCurrency: '$', price: loadingTVL ? null : TVL ?? 0 },
      {
        name: 'MARKET CAP',
        priceCurrency: '$',
        price: loadingMarketCap ? null : marketCap,
      },
    ];

    if (isMobile) {
      const i = info.findIndex(item => item.name === 'FTM');
      info.splice(i, 1);
      info.unshift({
        name: 'SPIRIT',
        priceCurrency: '$',
        price: loadingPrices ? null : tokensPrices?.SPIRIT.rate ?? 0,
        rate: loadingPrices
          ? null
          : tokensPrices?.SPIRIT.percentaje_change_24 ?? 0,
      });
    }

    return info;
  };

  const handleScroll = useCallback(() => {
    isMobile ? setScroll(window.scrollY < 16) : setScroll(window.scrollY < 72);
  }, [isMobile]);

  const accountEllipsis = account
    ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}`
    : null;

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const translatedMenus = navMenus.map((menu: any) => ({
    title: t(`${menuTranslationPath}.${menu.key}`),
    path: menu.path,
    icon: menu.icon,
  }));

  const translatedDropdownMenus = navDropdownMenus.map(menu => ({
    title: t(`${menuTranslationPath}.${menu.key}`),
    path: menu.path,
    url: menu.url,
  }));

  const onHideDropdown = useCallback(() => {
    setShowDropdown(false);
    setOutsideClicked(true);
  }, [setShowDropdown]);

  const onSelectLanguage = useCallback(
    id => {
      setSelectedLanguageId(id);
      i18n.changeLanguage(id);
    },
    [i18n],
  );

  const onMoreButtonClick = () => {
    !outsideClicked && setShowDropdown(!showDropdown);
  };

  const onSettingsButtonClick = () => {
    setShowSettingsModal(!showSettingsModal);
  };

  const navigateHome = () => {
    navigate('/home');
    window.scrollTo(0, 0);
  };

  const openConnectWalletModal = () => {
    onOpen();
  };

  const wallets = {
    injected: Metamask,
    WalletConnect: WalletConnect,
  };

  useEffect(() => {
    setMenuIndex(
      [...navMenus, ...navDropdownMenus].findIndex(
        menu => location.pathname === menu.path,
      ),
    );
  }, [location]);

  useEffect(() => {
    outsideClicked &&
      setTimeout(() => {
        setOutsideClicked(false);
      }, 200);
  }, [outsideClicked]);

  return (
    <Wrapper scroll={scroll}>
      <ContentWrapper>
        <TopWrapper>
          {tokenInfos().map((info, key) => (
            <StyledTokenInfoBar
              key={`${info.name}-${key}`}
              tokenName={info.name}
              tokenPriceCurrency={info.priceCurrency}
              tokenPrice={info.price}
              tokenRate={info.rate}
            />
          ))}

          {!isMobile && (
            <Button
              variant="topBar"
              fontSize="14px"
              paddingInline="8px"
              onClick={() => openInNewTab('https://app.spiritswap.finance/#/')}
            >
              {t(`${translationPath}.switchV1`)}
            </Button>
          )}
        </TopWrapper>
        <BottomWrapper>
          <Box
            onClick={navigateHome}
            display="flex"
            alignItems="center"
            pr="spacing06"
            marginLeft={isMobile ? '5px' : '0'}
            _hover={{ cursor: 'pointer' }}
          >
            {isMobile ? <SpiritMobileIcon /> : <SpiritDesktopIcon />}
          </Box>
          <MenuWrapper>
            {translatedMenus.map((menu, index) => {
              const active = Math.abs(menuIndex) === index;
              return (
                <NavMenuItem
                  key={`${menu.path}-${index}`}
                  menu={menu}
                  isActive={active}
                />
              );
            })}
            <MoreButtonWrapper>
              <MoreButton clicked={showDropdown} onClick={onMoreButtonClick}>
                {t(`${menuTranslationPath}.more`)}
                <CaretDownImage />
              </MoreButton>
              <NavDropdownWrapper>
                {showDropdown && (
                  <NavigationDropdown
                    items={translatedDropdownMenus}
                    onClickOutside={onHideDropdown}
                  />
                )}
              </NavDropdownWrapper>
            </MoreButtonWrapper>
          </MenuWrapper>
          <HStack spacing="spacing03" justify="end" flex="1" mr="spacing02">
            {!isMobile && (
              <StyledNavLink to="/swap">
                <Button variant="inverted" fontSize="base">
                  <SoullyIcon />
                  {loadingPrices ? (
                    <Skeleton
                      startColor="grayBorderBox"
                      endColor="bgBoxLighter"
                      w="49px"
                      h="24px"
                    />
                  ) : (
                    <Text>${tokensPrices?.SPIRIT.rate.toFixed(3)}</Text>
                  )}
                </Button>
              </StyledNavLink>
            )}

            {isLoggedIn ? (
              <Button
                onClick={handleLogout}
                backgroundColor="bgBox"
                border="none"
                fontSize="base"
              >
                <Image src={wallets[_connector]} w="20px" mr="8px" />
                <Text mr="8px">{accountEllipsis}</Text>
                <WalletExitIcon />
              </Button>
            ) : (
              <Button
                onClick={openConnectWalletModal}
                variant="inverted"
                fontSize="base"
              >
                <WalletIcon mr="spacing03" />
                {t(`${translationPath}.connect`)}
              </Button>
            )}
            <Button
              backgroundColor="bgBox"
              border="none"
              onClick={onSettingsButtonClick}
            >
              <SettingsIcon color="white" />
            </Button>
            {showSettingsModal && (
              <StyledSettingsModal
                selectedLanguageId={selectedLanguageId}
                onClose={() => {
                  setTimeout(() => {
                    setShowSettingsModal(false);
                  }, 30);
                }}
                onSelectLanguage={onSelectLanguage}
              />
            )}
          </HStack>
        </BottomWrapper>
      </ContentWrapper>
      <ConnectWallet isOpen={isOpen} dismiss={onClose} />
    </Wrapper>
  );
};

export default TopBar;
