import BigNum from 'bignumber.js';
import { BigNumber, ethers, utils } from 'ethers';
import addresses from 'constants/contracts';
import gauges from 'constants/gauges';
import { CHAIN_ID } from 'constants/index';
import { Multicall, Call, Contract, DEFAULT_GAS_LIMIT } from 'utils/web3';
import { getLiquityPoolData } from './covalent';
import { BoostedFarmData, BoostedFarmVoteData } from './types';
import { timestampToDate } from './formatters';

const { formatUnits } = utils;

const farmEmpty = [
  {
    pid: 1,
    isPsc: true,
    lpSymbol: 'Spirit LP',
    lpAddresses: {
      4002: '',
      250: '',
    },
    gaugeAddress: '',
  },
];

export const getInspiritStatistics = async (timeframe = 'week') => {
  const DAY = 86400;
  // Last two weeks
  const WEEKBIGNUMBER = BigNumber.from(7 * DAY * 2);

  // Timerange for date sensitive statistics
  let period = WEEKBIGNUMBER;

  if (timeframe === 'month') {
    const MONTH = 86400 * 30;

    period = BigNumber.from(MONTH);
  }

  if (timeframe === 'year') {
    const YEAR = 86400 * 365;

    period = BigNumber.from(YEAR);
  }

  const inspiritAddress = addresses.inspirit[CHAIN_ID];
  const usdcSpiritData = await getLiquityPoolData(
    addresses.inspiritLp[CHAIN_ID],
  );

  const spiritPrice = usdcSpiritData?.token_1.quote_rate;

  const balanceParams: Array<Call> = [
    {
      address: inspiritAddress,
      name: 'totalSupply',
      params: [],
      data: {},
    },
    {
      address: inspiritAddress,
      name: 'supply',
      params: [],
      data: {},
    },
  ];

  const multicall = await Multicall(balanceParams, 'inspirit');

  const totalSupply = formatUnits(multicall[0]?.response[0], 18);
  const totalLocked = formatUnits(multicall[1]?.response[0], 18);

  const feeDistributorAddress = addresses.feedistributor[CHAIN_ID];

  const feeDisCalls = [
    {
      address: `${feeDistributorAddress}`,
      name: 'time_cursor',
      params: [],
      data: {},
    },
  ];

  const feeDistributorMulticall = await Multicall(
    feeDisCalls,
    'feedistributor',
  );

  const [timeCursor] = feeDistributorMulticall;
  const beginningPeriod = BigNumber.from(timeCursor.response[0])
    .sub(period)
    .toNumber();

  const tokensPerWeekCall = [
    {
      address: feeDistributorAddress,
      name: 'tokens_per_week',
      params: [`${beginningPeriod}`],
    },
  ];

  const [lastDistributionCall] = await Multicall(
    tokensPerWeekCall,
    'feedistributor',
  );

  const lastDistribution = lastDistributionCall.response[0];

  const avgTime = (
    (1460 / parseFloat(totalLocked)) *
    parseFloat(totalSupply)
  ).toFixed(0);

  const totalLockedValue = spiritPrice * parseFloat(totalSupply) || 0;
  const totalSpiritValue = spiritPrice * parseFloat(totalLocked) || 0;

  const nextDistribution = timeCursor.response[0].toNumber();
  const lastDistributionSpirits = parseFloat(formatUnits(lastDistribution, 18));
  const lastDistributionValue = spiritPrice * lastDistributionSpirits;
  const aprLDbased =
    (lastDistributionSpirits * 52 * 100) / parseFloat(totalSupply);
  const inspiritPerSpirit = lastDistributionSpirits / parseFloat(totalSupply);

  return {
    totalSupply,
    totalLocked,
    spiritPrice,
    avgTime,
    totalSpiritValue,
    totalLockedValue,
    nextDistribution,
    lastDistribution: lastDistributionSpirits,
    lastDistributionValue,
    aprLDbased,
    inspiritPerSpirit,
    beginningPeriod: beginningPeriod * 1000,
  };
};

export const getUserInspiritBalances = async (userAddress: string) => {
  const inspiritAddress = addresses.inspirit[CHAIN_ID];

  const balanceParams: Array<Call> = [
    {
      address: inspiritAddress,
      name: 'locked',
      params: [userAddress],
      data: {},
    },
    {
      address: inspiritAddress,
      name: 'balanceOf',
      params: [userAddress],
      data: {},
    },
  ];

  const multicall = await Multicall(balanceParams, 'inspirit');
  const userLocked = multicall[0]
    ? formatUnits(multicall[0]?.response[0], 18)
    : 0;
  const userLockEndDate = multicall[0] ? multicall[0]?.response[1] : 0;
  const userBalance = multicall[1]
    ? formatUnits(multicall[1]?.response[0], 18)
    : 0;

  const feeDistributorAddress = addresses.feedistributor[CHAIN_ID];

  const feeDisributorContract = await Contract(
    feeDistributorAddress,
    'feedistributor',
  );

  const userClaim = await feeDisributorContract.callStatic['claim(address)'](
    userAddress,
    {
      gasLimit: 1000000,
    },
  );

  const userClaimableAmount = formatUnits(userClaim, 18);

  return {
    userLocked,
    userBalance,
    userClaimableAmount,
    userLockEndDate,
  };
};

export const getGaugeData = async (lpAddress: string, gaugeAddress: string) => {
  const durationRewardParams: Array<Call> = [];

  durationRewardParams.push(
    {
      address: lpAddress,
      name: 'totalSupply',
    },
    {
      address: gaugeAddress,
      name: 'totalSupply',
    },
    {
      address: gaugeAddress,
      name: 'derivedSupply',
    },
    {
      address: gaugeAddress,
      name: 'rewardRate',
    },
    {
      address: gaugeAddress,
      name: 'getRewardForDuration',
    },
  );
  const durationRewardMulticall = await Multicall(
    durationRewardParams,
    'gauge',
  );

  const [lpTotalSupply] = durationRewardMulticall[0].response;
  const [lpTotalInGauge] = durationRewardMulticall[1].response;
  const [derivedSupply] = durationRewardMulticall[2].response;
  const [rewardRate] = durationRewardMulticall[3].response;
  const [getRewardForDuration] = durationRewardMulticall[4].response;

  // ------ Just in case we need a different ratio for Gauges --------------------------------- =>
  const ratio = new BigNum(lpTotalInGauge._hex).div(
    new BigNum(lpTotalSupply._hex),
  );

  return {
    lpTotalSupply,
    lpTotalInGauge,
    derivedSupply,
    rewardRate,
    getRewardForDuration,
    ratio,
  };
};

export const getBoostedFarms = async ({ version = 1 } = {}) => {
  // const gauges = [];
  let gaugeProxy = 'gaugeproxy';
  let gaugeAddress = addresses.gauge[CHAIN_ID];

  /* TODO Better version handling */
  if (version === 2) {
    gaugeProxy = 'gaugeproxyV2';
    gaugeAddress = addresses.gaugeV2[CHAIN_ID];
  }
  const gaugeContract = await Contract(gaugeAddress, gaugeProxy);

  // get gauge pools
  const tokens = await gaugeContract.tokens();

  // We'll do multicalls for each category
  const gaugesParams: Array<Call> = [];
  const weightParams: Array<Call> = [];
  const erc20Params: Array<Call> = [];

  tokens.forEach(farmAddress => {
    gaugesParams.push({
      address: gaugeAddress,
      name: 'gauges',
      params: [farmAddress],
    });

    weightParams.push({
      address: gaugeAddress,
      name: 'weights',
      params: [farmAddress],
    });

    erc20Params.push({
      address: farmAddress,
      name: 'name',
    });
  });

  // We retrieve all data in 3 calls rather than 90+ like in previous one
  const gaugesMulticall = await Multicall(gaugesParams, gaugeProxy);
  const weightsMulticall = await Multicall(weightParams, gaugeProxy);
  const namesMulticall = await Multicall(erc20Params, 'erc20');

  // All data is returned based in the index. We now loop and mix it
  const farms: Array<BoostedFarmData> = tokens.map((farmAddress, i) => {
    const [gauge] = gaugesMulticall[i]?.response;
    const [weight] = weightsMulticall[i]?.response;
    const [name] = namesMulticall[i]?.response;
    const base = {
      name,
      address: gauge,
      tokenAddress: farmAddress,
      weight: ethers.utils.formatEther(weight.toString()),
      logo: '',
      userVotes: '',
      value: '',
    };
    const [farmData] = gauges.filter(
      farm => farm.gaugeAddress === base.address,
    );

    if (farmData) {
      return { ...base, ...farmData };
    }

    return { ...base, ...farmEmpty[0] };
  });

  return farms;
};

export const getBoostedFarmVotes = async ({ userAddress, version = 1 }) => {
  // const gauges = [];
  let gaugeProxy = 'gaugeproxy';
  let gaugeAddress = addresses.gauge[CHAIN_ID];

  if (version === 2) {
    gaugeProxy = 'gaugeproxyV2';
    gaugeAddress = addresses.gaugeV2[CHAIN_ID];
  }

  const gaugeContract = await Contract(gaugeAddress, gaugeProxy);

  // get gauge pools
  const tokens = await gaugeContract.tokens();

  // We'll do multicalls for each category
  const gaugesParams: Array<Call> = [];
  const votesParams: Array<Call> = [];

  tokens.forEach(farmAddress => {
    gaugesParams.push({
      address: gaugeAddress,
      name: 'gauges',
      params: [farmAddress],
    });
    votesParams.push({
      address: gaugeAddress,
      name: 'votes',
      params: [userAddress, farmAddress],
    });
  });

  // We retrieve all data in 2 calls rather than 60+ like in previous one
  const gaugesMulticall = await Multicall(gaugesParams, gaugeProxy);
  const votesMulticall = await Multicall(votesParams, gaugeProxy);

  let totalUserVote = 0;

  const votes: Array<BoostedFarmVoteData> = tokens.map((farmAddress, i) => {
    const [gauge] = gaugesMulticall[i]?.response;
    const [userVotes] = votesMulticall[i]?.response;
    totalUserVote += parseFloat(ethers.utils.formatEther(userVotes).toString());

    const base = {
      address: gauge,
      tokenAddress: farmAddress,
      userVotes: ethers.utils.formatEther(userVotes).toString(),
      value: '',
    };
    const [farmData] = gauges.filter(
      farm => farm.gaugeAddress === base.address,
    );

    if (farmData) {
      return { ...base, ...farmData };
    }

    return { ...base, ...farmEmpty[0] };
  });

  // All data is returned based in the index. We now loop and mix it
  if (totalUserVote > 0) {
    votes.forEach(vote => {
      vote.value = ((parseFloat(vote.userVotes) * 100) / totalUserVote)
        .toFixed(0)
        .toString();
    });
  }

  return votes;
};

export const getInspiritEstimate = (
  _inspiritAmount: number,
  _lockEnd: number,
  _lockedEndDate: number,
) => {
  // const MAXTIME = 4 * 365 * 86400;

  if (!_inspiritAmount) {
    return {
      date: 0,
      amount: 0,
    };
  }

  let nextDate;

  if (_lockedEndDate !== 0) {
    nextDate = new Date(_lockedEndDate * 1000 + _lockEnd);
  } else {
    nextDate = new Date(Date.now() + _lockEnd);
  }

  if (!nextDate && !_lockedEndDate) {
    return {
      date: undefined,
      amount: 0,
    };
  }

  const getEpochSecondForDay = date => {
    return Math.ceil(date.getTime() / (1000 * 60 * 60 * 24)) * 60 * 60 * 24;
  };

  const WEEK = 7 * 86400;
  const MAXTIME = 4 * 365 * 86400;
  const rounded = Math.floor(getEpochSecondForDay(nextDate) / WEEK) * WEEK;

  const estimatedAmount =
    ((rounded - +new Date() / 1000) / MAXTIME) * _inspiritAmount;

  return {
    date: nextDate.getTime(),
    amount: estimatedAmount,
  };
};

export const calcTimeUntilNextBlock = (date: Date | string | number) => {
  const now = Date.now;
  let startTimestamp: number;

  if (typeof date === 'string') {
    startTimestamp = new Date(date).getTime();
  } else if (date instanceof Date) {
    startTimestamp = date.getTime();
  } else {
    startTimestamp = date;
  }

  const timeLeft = startTimestamp - now();
  const clampedPrecision = Math.min(20, Math.max(0));
  const total = Math.round(
    parseFloat((Math.max(0, timeLeft) / 1000).toFixed(clampedPrecision)) * 1000,
  );

  const seconds = Math.abs(total) / 1000;

  return {
    days: Math.floor(seconds / (3600 * 24)),
    hours: Math.floor((seconds / 3600) % 24),
    minutes: Math.floor((seconds / 60) % 60),
    completed: total <= 0,
  };
};

export const canUnlockInspirit = (unlockDate: number) => {
  const timeElapsed = Date.now();
  const [, month, day, year] = new Date(timeElapsed).toDateString().split(' ');

  const [lockedDay, lockedMonth, lockedYear] =
    timestampToDate(unlockDate).split(' ');
  if (Number(lockedYear) <= Number(year)) {
    const months = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEP: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12,
    };
    const actualMonthNumber = months[month.toUpperCase()];
    const lockedMonthNumber = months[lockedMonth.toUpperCase()];
    if (lockedMonthNumber === actualMonthNumber) {
      const todayNumber = Number(day);
      const sufix = lockedDay.slice(-2);
      const [lockedDayNumber] = lockedDay.split(sufix);

      if (Number(lockedDayNumber) < todayNumber) return true;
      return false;
    } else if (lockedMonthNumber < actualMonthNumber) return true;
    return false;
  }

  return false;
};

export const getGaugeV2Contract = async () => {
  const gaugeV2Address = addresses.gaugeV2[CHAIN_ID];
  return await Contract(
    gaugeV2Address,
    'gaugeproxyV2',
    window.ethereum,
    CHAIN_ID,
  );
};

export const getBribeContract = async (bribeAddress: string) => {
  return await Contract(bribeAddress, 'bribe', window.ethereum, CHAIN_ID);
};

export const getGaugeV2Address = async (lpAddress: string) => {
  const gaugeV2Contract = await getGaugeV2Contract();
  return await gaugeV2Contract.getGauge(lpAddress);
};

export const getBribeAddress = async (gaugeAddress: string) => {
  const gaugeV2Contract = await getGaugeV2Contract();
  return await gaugeV2Contract.bribes(gaugeAddress);
};

export const getBribeContractByLpAddress = async (lpAddress: string) => {
  const gaugeAddress = await getGaugeV2Address(lpAddress);
  const bribeAddress = await getBribeAddress(gaugeAddress);
  const bribeContract = await getBribeContract(bribeAddress);

  return bribeContract;
};

export const submitBribe = async (
  bribeAddress: string,
  tokenAddress: string,
  amount: BigNumber,
) => {
  const bribeContract = await getBribeContract(bribeAddress);

  return await bribeContract.notifyRewardAmount(tokenAddress, amount, {
    gasLimit: DEFAULT_GAS_LIMIT,
  });
};

export const claimBribes = async (
  bribes: string[],
  rewardTokens: string[][],
  userAddress: string,
) => {
  const gaugeV2Contract = await getGaugeV2Contract();
  return await gaugeV2Contract.claimBribes(bribes, rewardTokens, userAddress, {
    gasLimit: DEFAULT_GAS_LIMIT,
  });
};

export const getClaimableBribeRewards = async (userWalletAddress: string) => {
  const boostedFarmVotes = await getBoostedFarmVotes({
    userAddress: userWalletAddress,
    version: 2,
  });

  let totalEarned = 0;

  await Promise.all(
    boostedFarmVotes.map(async ({ tokenAddress: farmLpAddress }) => {
      const bribeContract = await getBribeContractByLpAddress(farmLpAddress);
      const earned: BigNumber = await bribeContract.rewardPerTokenStored(
        userWalletAddress,
      );
      totalEarned += earned.toNumber();
    }),
  );

  return totalEarned;
};
