import web3Listener from './_web3Listener';
import { transaction } from 'utils/web3/actions';
import { removePendingTransactions } from 'store/user';
import { Web3TxData } from 'utils/web3/types';
import getNotificationIcon from 'app/connectors/getNotificationIcon';
import { toast } from '@chakra-ui/react';
import { NOTIFICATIONS_STATE } from 'constants/index';
import { setEcosystemFarmAddress } from 'store/farms';
import { ethers } from 'ethers';

export const verifyTransaction = async (
  tx: Web3TxData,
  dispatch: Function,
  notification?: Function,
) => {
  const {
    actionType,
    hash,
    successTitle,
    errorTitle,
    inputSymbol,
    inputValue,
    uniqueMessage,
    outputSymbol,
    outputValue,
    link,
    type,
  } = tx;

  if (!hash) {
    // No hash, we cleaned up the queue
    return `${hash}`;
  }
  const checks = await transaction(hash);

  const receipt = checks.external || checks.internal;

  if (receipt) {
    if (type === 'ecosystemFarm') {
      let abi = [
        'event NewFarm(address indexed farm, address indexed lpToken)',
      ];
      let iface = new ethers.utils.Interface(abi);
      const decodedLog = iface.parseLog(receipt.logs[1]);
      const farmAddress = decodedLog.args['farm'];

      dispatch(setEcosystemFarmAddress(farmAddress));
    }

    toast.close(tx.hash);
    if (notification) {
      notification({
        title: receipt.status ? successTitle : errorTitle,
        id: receipt.status ? `confirmed-${hash}` : `failed-${hash}`,
        type: receipt.status
          ? NOTIFICATIONS_STATE.SUCCESS
          : NOTIFICATIONS_STATE.ERROR,
        inputSymbol,
        inputValue,
        uniqueMessage,
        outputSymbol,
        outputValue,
        duration: 5000,
        icon: getNotificationIcon(actionType || ''),
        link,
      });
    }

    return `${hash}`;
  }

  return null;
};

export const verifyTransactions = async (
  transactions,
  dispatch,
  notification,
) => {
  const promises: Promise<string | null>[] = [];

  transactions.forEach(tx =>
    promises.push(verifyTransaction(tx, dispatch, notification)),
  );

  const results = await Promise.all(promises);

  dispatch(removePendingTransactions(results));
};

/**
 * Main Method - Sets up connection to rpc server and callback functions meant to update state of app
 *  - userWalletAddress: Address of user in the blockchain
 *  - dispatch: callback hook method that allows update of app state
 */
export const pendingTxListener = (txsToMonitor, dispatch, notification) => {
  return web3Listener({
    id: 'pendingTransactions',
    update: () => null,
    monitor: txsToMonitor,
    onMonitor: () => verifyTransactions(txsToMonitor, dispatch, notification),
  });
};

export default pendingTxListener;
