/**
 * app/utils/methods.ts
 *
 * Describe common functions
 *
 */

import { Sign, Currency, ChartProps } from './types';
import { CurrencySymbol } from './constants';
import { tokens, TOKEN_EMPTY, WFTM, SPIRIT } from 'constants/tokens';
import { formatUnits } from 'ethers/lib/utils';
import { BigNumber, FixedNumber } from 'ethers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectAddress,
  selectIsLoggedIn,
  selectWallet,
  selectTokens,
  selectLiquidity,
  selectPortfolioValue,
  selectSpiritClaimableAmount,
  selectBribesClaimableAmount,
  selectInspiritUserBalance,
  selectLockedInsSpiritEndDate,
  selectLockedInSpiritAmount,
  selectPendingTransactions,
  selectStaked,
  selectLimitOrders,
  selectLiquidityWallet,
  selectSobLiquidityWallet,
  selectTrackedTokens,
  selectBridgeChains,
  selectBridgeTokensFrom,
  selectBridgeTokensTo,
} from 'store/user/selectors';
import {
  selectTotalSpiritLocked,
  selectAverageSpiritUnlockTime,
  selectNextSpiritDistribution,
  selectLastSpiritDistribution,
  selectAprPercentage,
  selectInSpiritPerSpirit,
  selectSpiritPrice,
  selectBoostedFarms,
} from 'store/general/selectors';
import { formatPortfolioToWallet } from 'utils/web3';
import useLogin from 'app/connectors/EthersConnector/login';
import { setBridgeChains, setNewTrackedToken } from 'store/user';
import { getTokensDetails } from 'utils/data';
import { Token } from 'app/interfaces/General';
import { BASE_TOKEN_ADDRESS, CHAIN_ID } from 'constants/index';

import allFarms from 'constants/farms';

const FARM = allFarms;
FARM.shift();

export function convertTokenPrice(value: number, fixed?: number): string {
  let units = ['', 'K', 'M', 'B', 'T', 'Q'];
  let numberOfPlaces = Math.ceil(Math.log10(value));

  let unit = Math.floor((numberOfPlaces - 1) / 3);
  if (numberOfPlaces < 1) unit = 0;
  if (unit >= units.length) unit = units.length - 1;

  let x;
  x = value / Math.pow(10, unit * 3);

  if (fixed) {
    x = x.toFixed(fixed);
  }

  return Math.round(x * 1000) / 1000 + units[unit];
}

export const convertAmount = (value: string | number): string => {
  if (!value) return '';
  const params = {
    style: 'currency',
    currency: 'USD',
  };
  if (typeof value === 'string') {
    return Number(value).toLocaleString('en-US', params);
  }
  return value.toLocaleString('en-US', params);
};

export function getSign(value: number | string): Sign {
  let number = 0;
  switch (typeof value) {
    case 'number':
      number = value;
      break;
    case 'string':
      number = Number(value);
      break;
  }

  if (number > 0) {
    return Sign.POSITIVE;
  }
  if (number < 0) {
    return Sign.NEGATIVE;
  }
  return Sign.NEUTRAL;
}

export function formatNumber(value: number): string {
  const intl = Intl.NumberFormat('en-US');
  return intl.format(value);
}

const limitInputBasedOnDecimals = (value: string, decimals: number) => {
  const separateValue = value.split('.');
  const intergerPart = separateValue[0];
  const decimalPart = separateValue[1];
  if (decimalPart.length <= decimals) return value;

  return intergerPart[0] + '.' + decimalPart.substring(0, decimals);
};

export const validateInput = (string: string, tokenDecimals?: number) => {
  string = string.replaceAll('+', '');
  string = string.replaceAll('-', '');
  string = string.replaceAll('e', '');
  if (string === '') return '';
  if (string === '.') return '0.';
  if (string.startsWith('0') && string.length === 1) return string;
  if (string.startsWith('0') && string[1] !== '.') return string.substring(1);

  if (string.includes('.')) {
    const limitedInput = limitInputBasedOnDecimals(string, tokenDecimals ?? 18);
    const stringArr = limitedInput.split('');
    const dots = stringArr.filter(letter => letter === '.');
    return dots.length === 1 ? limitedInput : limitedInput.slice(0, -1);
  }
  return string;
};

export function formatCurrency(currency: Currency, amount: number): string {
  const currencySymbol = CurrencySymbol[currency];
  return `${currencySymbol} ${formatNumber(amount)}`;
}

export function formatCryptoNumber(
  value: number,
  maxDecimals: number = 18,
): string {
  const intl = Intl.NumberFormat('en-US');
  const floorValue = Math.floor(value);
  const decimals = value - floorValue;
  const decimalsString = decimals.toString();
  const floorValueString = intl.format(floorValue + 0.1);

  if (floorValue === value) {
    return floorValue.toString();
  }

  return (
    floorValueString.substring(0, floorValueString.length - 1) +
    decimalsString.substr(2, Math.min(maxDecimals, decimalsString.length - 2))
  );
}

export const formatAmount = (
  amount: string,
  decimals: number,
  trunc: number = decimals,
) => {
  if (amount.includes('.')) {
    const indexOf = amount.indexOf('.');
    const integer = amount.slice(0, indexOf);
    const decimals = amount.slice(indexOf, trunc + 2);

    return integer.concat(decimals);
  }

  const amountFormatted = formatUnits(amount, decimals);
  return FixedNumber.fromString(amountFormatted).round(trunc).toString();
};

export const getRoundedSFs = (num: string, SFCount: number = 2) => {
  let matches = num.toString().match(/^-?(0+)\.(0*)/);
  if (Number.isInteger(parseFloat(num))) return parseFloat(num).toFixed(2);

  if (matches) {
    let firstIndex = matches[0].length;
    let prefix = matches[0];
    let sf = Number(
      num.toString().substring(firstIndex, firstIndex + SFCount + 1),
    );

    const newSf = prefix + sf.toString();
    return Number(newSf).toFixed(matches[2].length + SFCount);
  } else {
    return parseFloat(num).toFixed(2);
  }
};

export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  const displayBalance = BigNumber.from(balance).div(
    BigNumber.from(10).pow(decimals),
  );
  return displayBalance.toNumber();
};

export const getAmountUSD = (
  amount: string,
  price: string,
  decimals: number,
) => {
  const amountFormatted = formatUnits(amount, decimals);
  const fixedPrice = FixedNumber.fromString(price);
  return FixedNumber.fromString(amountFormatted)
    .mulUnsafe(fixedPrice)
    .round(2)
    .toString();
};

export const mathBalance = (balance: string, percentage: string) => {
  const fixedNumber = FixedNumber.from(balance);
  const fixedPercentage = FixedNumber.from(percentage);
  return fixedNumber.mulUnsafe(fixedPercentage).toString();
};

export const truncateTokenValue = (
  value: string | number,
  tokenValueInUSD?: string | number,
) => {
  // tokenValueInUSD is the price of 1 token in USD, it should always be passed unless the value is itself USD
  if (value === '0' || value === '') {
    return '0';
  }

  let numberOfIntegerDigits;

  if (!tokenValueInUSD) {
    numberOfIntegerDigits = 1;
  } else {
    if (typeof tokenValueInUSD === 'string') {
      tokenValueInUSD = parseFloat(tokenValueInUSD);
    }

    numberOfIntegerDigits =
      tokenValueInUSD < 1 ? 1 : Math.floor(Math.log10(tokenValueInUSD)) + 1;
  }
  if (typeof value === 'number') {
    return value.toFixed(numberOfIntegerDigits + 1);
  }

  return (
    value.split('.')[0] +
    '.' +
    value.split('.')[1].substring(0, numberOfIntegerDigits + 1)
  );
};

export const GetWalletBalance = () => {
  const balances = useAppSelector(selectWallet);
  const userWallet = formatPortfolioToWallet(balances);
  const userLiquidity = useAppSelector(selectLiquidityWallet);

  return { balances, userWallet, userLiquidity };
};

export const UseWallet = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const wallet = useAppSelector(selectWallet);
  const account = useAppSelector(selectAddress);
  const { handleLogin } = useLogin();
  const tokens = useAppSelector(selectTokens);
  const liquidity = useAppSelector(selectLiquidity);
  const walletLiquidity = useAppSelector(selectLiquidityWallet);
  const sobWalletLiquidity = useAppSelector(selectSobLiquidityWallet);

  const portfolioAmountValue = useAppSelector(selectPortfolioValue);
  const pendingTransactions = useAppSelector(selectPendingTransactions);
  const stakedBalances = useAppSelector(selectStaked);

  return {
    account,
    wallet,
    isLoggedIn,
    tokens,
    liquidity,
    walletLiquidity,
    sobWalletLiquidity,
    portfolioAmountValue,
    staked: stakedBalances,
    pending: pendingTransactions,
    login: handleLogin,
  };
};

export const TrackedTokens = () => {
  const tracked = useAppSelector(selectTrackedTokens);

  return tracked;
};

export const GetBridgeWallets = () => {
  const originWallet = useAppSelector(selectBridgeTokensFrom);
  const destinationWallet = useAppSelector(selectBridgeTokensTo);

  return {
    originWallet,
    destinationWallet,
  };
};

export const GetBalanceByToken = (
  token: Token,
  bridge?: 'from' | 'to' | undefined,
) => {
  const walletBalance = useAppSelector(selectWallet);
  const walletLiquidity = useAppSelector(selectStaked);
  const trackedTokens = useAppSelector(selectTrackedTokens);
  const dispatch = useAppDispatch();

  let target = walletBalance;

  if (bridge) {
    const { originWallet, destinationWallet } = GetBridgeWallets();
    target = bridge && bridge === 'from' ? originWallet : destinationWallet;
  }

  if (target || walletLiquidity) {
    const findToken = target?.find(
      walletToken => walletToken.symbol === token.symbol,
    );

    const liquidityBalance = walletLiquidity.find(
      LpToken => LpToken.address === token.address,
    );

    if (findToken) {
      const balance = formatAmount(findToken.amount, token.decimals);
      return { balance: balance, balanceUSD: findToken.usd.toString() };
    }

    if (liquidityBalance) {
      const balance = formatAmount(liquidityBalance.amount, token.decimals);
      return {
        balance,
        balanceUSD: liquidityBalance?.usd?.toString(),
      };
    }
  }

  // We reach this point if token is not in user's wallet
  // Here we can add it to the list of tokens we keep track in the app
  if (!trackedTokens || !trackedTokens.includes(token)) {
    dispatch(setNewTrackedToken(token));
  }

  return { balance: '0', balanceUSD: '0' };
};

export const GetTokenBySymbol = (tokenSymbol: string) => {
  const walletBalance = useAppSelector(selectWallet);

  const findToken = walletBalance.find(
    walletToken => walletToken.symbol === tokenSymbol,
  );

  if (findToken) {
    return findToken;
  }

  return null;
};

export const GetWhiteListedTokenFromAddres = (address: string) => {
  let token = tokens.find(token => token.address === address);
  if (!token) {
    token = TOKEN_EMPTY;
  }
  return token;
};

export const GetInspiritData = () => {
  const spiritPrice = useAppSelector(selectSpiritPrice);
  const balance = useAppSelector(selectInspiritUserBalance);
  const spiritLocked = useAppSelector(selectLockedInSpiritAmount);
  const spiritLockedValue = (spiritLocked * spiritPrice).toFixed(2);

  const totalSpiritLocked = useAppSelector(selectTotalSpiritLocked);
  const spiritPerInspirit = useAppSelector(selectInSpiritPerSpirit);
  const lastSpiritDistribution = useAppSelector(selectLastSpiritDistribution);
  const nextSpiritDistribution = useAppSelector(selectNextSpiritDistribution);
  const spiritClaimable = useAppSelector(selectSpiritClaimableAmount);
  const bribesClaimable = useAppSelector(selectBribesClaimableAmount);
  const lockedEnd = useAppSelector(selectLockedInsSpiritEndDate);
  const aprValue = useAppSelector(selectAprPercentage);
  const averageSpiritUnlockTime = useAppSelector(selectAverageSpiritUnlockTime);

  return {
    balance,
    spiritLocked,
    spiritLockedValue,
    totalSpiritLocked,
    spiritPerInspirit,
    lastSpiritDistribution,
    nextSpiritDistribution,
    spiritClaimable,
    bribesClaimable,
    lockedEnd,
    aprValue,
    averageSpiritUnlockTime,
  };
};

export const GetLimitOrders = () => {
  const userLimitOrders = useAppSelector(selectLimitOrders);

  return userLimitOrders;
};

export const GetFarms = () => {
  const boosted = useAppSelector(selectBoostedFarms);

  return boosted;
};

export const GetBridgeTargets = () => {
  const chainIds = useAppSelector(selectBridgeChains);

  return chainIds;
};

export const UpdateBridgeNetwork = () => {
  const dispatch = useAppDispatch();
  const updateChains = (_networks: string[] | number[]) =>
    dispatch(setBridgeChains(_networks));

  return { updateChains };
};

export const getDiffPrice = async (
  inputValue: number,
  outputValue: number,
  tokenInput: Token,
  tokenOutput: Token,
) => {
  const inputTokenValue = inputValue || 0;
  const outputTokenValue = outputValue || 0;

  const inputAddress =
    tokenInput.address === BASE_TOKEN_ADDRESS
      ? WFTM.address
      : tokenInput.address;
  const outputAddress =
    tokenOutput.address === BASE_TOKEN_ADDRESS
      ? WFTM.address
      : tokenOutput.address;

  try {
    const [firstTokenUSDRate] = await getTokensDetails(
      [inputAddress],
      tokenInput.chainId,
    );
    const [secondTokenUSDRate] = await getTokensDetails(
      [outputAddress],
      tokenOutput.chainId,
    );

    if (firstTokenUSDRate.rate && secondTokenUSDRate.rate) {
      const inputUSDValue = inputTokenValue * firstTokenUSDRate.rate;
      const outputUSDValue = outputTokenValue * secondTokenUSDRate.rate;
      const diff = ((outputUSDValue - inputUSDValue) / inputUSDValue) * 100;
      if (diff === Infinity || isNaN(diff)) return undefined;

      return diff;
    }

    return 0;
  } catch (error) {}
};

export const getChartUrl = ({
  pairAddress,
  inTokenAddress,
  outTokenAddress,
}: ChartProps) => {
  let url = '';
  const defaultUrl = `https://kek.tools/t/${WFTM.address}/chart?pair=0x30748322B6E34545DBe0788C421886AEB5297789&exchange=spiritswap&accent=0D0F22&theme=dark&fallback=
  ${SPIRIT.address}`;

  if (inTokenAddress && outTokenAddress) {
    const inputAddress =
      inTokenAddress === BASE_TOKEN_ADDRESS ? WFTM.address : inTokenAddress;
    const outputAddress =
      outTokenAddress === BASE_TOKEN_ADDRESS ? WFTM.address : outTokenAddress;

    url = `https://kek.tools/t/${outputAddress}/chart?pair=${pairAddress}&exchange=spiritswap&accent=0D0F22&theme=dark&fallback=
    ${inputAddress}`;
  } else return defaultUrl;

  return url;
};

export const getLpAddress = (
  outTokenAddress: string,
  inTokenAddress: string,
  chainID = CHAIN_ID,
) => {
  const inputAddress =
    inTokenAddress === BASE_TOKEN_ADDRESS ? WFTM.address : inTokenAddress;
  const outputAddress =
    outTokenAddress === BASE_TOKEN_ADDRESS ? WFTM.address : outTokenAddress;

  const lpFound = FARM.find(farm => {
    const quoteTokenAddress = farm.quoteTokenAdresses[chainID];
    const tokenAddress = farm.tokenAddresses[chainID];

    const condition1 =
      inputAddress === quoteTokenAddress || inputAddress === tokenAddress;

    const condition2 =
      outputAddress === quoteTokenAddress || outputAddress === tokenAddress;

    return condition1 && condition2;
  });

  if (lpFound) return lpFound.lpAddresses[chainID];
  else return undefined;
};

export const checkAddress = (valueA: string, valueB: string): boolean =>
  valueA.toLowerCase() === valueB.toLowerCase();
