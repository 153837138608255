import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageItems } from 'app/utils';
import { ReactComponent as Language } from 'app/assets/images/language.svg';
import { SelectWithDropdown } from '../SelectWithDropdown';
import { Modal } from 'app/components/Modal';
import { Button, Flex, useOutsideClick } from '@chakra-ui/react';
import { Props } from './SettingsModal.d';
import { BodyContainer, LanguageLabel } from './styles';
import { openInNewTab } from 'app/utils/redirectTab';

const SettingsModal = ({
  selectedLanguageId,
  onClose,
  onSelectLanguage,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const translationPath = 'common.settingsModal';
  const buttonTranslationPath = 'common.topBar';
  useOutsideClick({
    ref,
    handler: () => onClose(),
  });

  return (
    <Modal title={t(`${translationPath}.title`)} onClose={onClose} {...props}>
      <BodyContainer ref={ref}>
        <Flex w="full" justify="space-between" align="center">
          <LanguageLabel>{t(`${translationPath}.language`)}</LanguageLabel>
          <SelectWithDropdown
            items={LanguageItems}
            selectedId={selectedLanguageId}
            icon={<Language />}
            onSelect={id => {
              onSelectLanguage(id);
            }}
          />
        </Flex>
        <Button
          w="full"
          bg="grayBorderBox"
          h="28px"
          mt="spacing03"
          onClick={() => openInNewTab('https://app.spiritswap.finance/#/')}
        >
          {t(`${buttonTranslationPath}.switchV1`)}
        </Button>
      </BodyContainer>
    </Modal>
  );
};

export default SettingsModal;
