import addresses from 'constants/contracts';
import { utils } from 'ethers';
import { BigNumber as BigNum } from '@ethersproject/bignumber';
import { CHAIN_ID } from 'constants/index';
import { TokensTableRow } from 'app/pages/Inspirit/components/Voting/components/TokensTable/TokensTable.d';
import { DEFAULT_GAS_LIMIT, approve } from './general';
import { Contract } from '../contracts';
import { gaugeContractProxy } from './farm';
import { transactionResponse } from './utils';
import { timestampToDate } from 'utils/data';
import { ERROR_NOT_SUM_100 } from 'constants/errors';
import { getProvider } from 'app/connectors/EthersConnector/login';

export const feeDistributorContract = async () => {
  const _connector = getProvider();
  const feeDistributorContract = await Contract(
    addresses.feedistributor[CHAIN_ID],
    'feedistributor',
    _connector,
    CHAIN_ID,
  );

  return feeDistributorContract;
};

export const inspiritContract = async () => {
  const _connector = getProvider();
  const inspiritContract = await Contract(
    addresses.inspirit[CHAIN_ID],
    'inspirit',
    _connector,
    CHAIN_ID,
  );

  return inspiritContract;
};

export const approveSpirit = async (
  _address: string,
  _allowableAmount: string,
  _callback?: Function | undefined,
  _chainId = CHAIN_ID,
) => {
  if (!_allowableAmount) {
    throw new Error('Allowable amount needs to be provided');
  }
  const lockAmount = utils.parseEther(`${_allowableAmount}`);

  const tx = await approve(
    addresses.spirit[_chainId],
    _address,
    lockAmount,
    'spirit',
    _chainId,
  );

  return transactionResponse('inspirit.allowance', {
    tx: tx,
    uniqueMessage: { text: 'Approving', secondText: 'SPIRIT' },
  });
};

// lockEnd = milliseconds in unixTime
export const createInspiritLock = async (
  _userAddress: string,
  _lockAmount: number | string,
  _lockEnd: number | null, // timestamp
) => {
  if (!_lockEnd) {
    throw new Error('Invalid lock time selected');
  }

  if (!_lockAmount) {
    throw new Error('Invalid lock amount');
  }
  const contract = await inspiritContract();

  // Check the current amount locked
  const lockedAmount = await contract.locked(_userAddress);

  if (!lockedAmount.amount.eq(0)) {
    throw new Error('Existing lock already tied to account');
  }

  const tx = await contract.create_lock(
    utils.parseEther(`${_lockAmount}`),
    parseInt(`${_lockEnd / 1000}`, 10),
    {
      gasLimit: DEFAULT_GAS_LIMIT,
    },
  );

  return transactionResponse('inspirit.lock', { tx: tx });
};

export const increaseLockAmount = async (
  _address: string,
  _amount: number | string,
) => {
  if (!_amount) {
    throw new Error('Invalid additional amount to lock');
  }

  const contract = await inspiritContract();

  // Check the current amount locked
  const lockedAmount = await contract.locked(_address);

  if (lockedAmount.amount.eq(0)) {
    throw new Error('Address does not have existing lock amount');
  }

  const lockAmount = utils.parseEther(`${_amount}`);

  const tx = await contract.increase_amount(lockAmount, {
    gasLimit: DEFAULT_GAS_LIMIT,
  });

  return transactionResponse('inspirit.additional', {
    tx: tx,
    uniqueMessage: { text: _amount?.toString(), secondText: 'SPIRIT' },
  });
};

export const increaseLockTime = async (
  _userAddress: string,
  _newTimeStamp: number | null, // timestamp
) => {
  if (!_newTimeStamp) {
    throw new Error('Invalid lock time');
  }

  const contract = await inspiritContract();

  // Check the current amount locked
  const lockedAmount = await contract.locked(_userAddress);

  if (lockedAmount.amount.eq(0)) {
    throw new Error('Address does not have existing lock amount');
  }

  const tx = await contract.increase_unlock_time(_newTimeStamp / 1000, {
    gasLimit: DEFAULT_GAS_LIMIT,
  });

  const nextLockedDate = timestampToDate(_newTimeStamp / 1000);

  return transactionResponse('inspirit.extend', {
    tx: tx,
    uniqueMessage: { text: `Locking until ${nextLockedDate}` },
  });
};

export const unlockInspirit = async () => {
  const contract = await inspiritContract();
  const tx = await contract.withdraw({ gasLimit: 500000 });

  return transactionResponse('inspirit.unlock', {
    tx: tx,
    uniqueMessage: {
      text: `Unloking SPIRITS`,
      secondText: 'SPIRIT',
    },
  });
};

export const claimSpirit = async (claimableSpiritRewards: string) => {
  const contract = await feeDistributorContract();

  const tx = await contract['claim()']();

  return transactionResponse('inspirit.claim', {
    tx: tx,
    uniqueMessage: {
      text: `Claiming ${claimableSpiritRewards}`,
      secondText: 'SPIRIT',
    },
  });
};

export const voteForBoostedDistributions = async (vaults: TokensTableRow[]) => {
  const tokenList: string[] = [];
  const valueList: BigNum[] = [];
  let votingWeight = 0;

  vaults.forEach(vault => {
    const { yourVote, fulldata } = vault;
    const weight = parseFloat(`${yourVote}`.replace(' %', ''));
    const address = fulldata?.tokenAddress || '';

    if (weight && address) {
      tokenList.push(address);
      valueList.push(utils.parseEther(`${weight}`));
      votingWeight += weight;
    }
  });

  if (votingWeight !== 100) {
    throw new Error(ERROR_NOT_SUM_100);
  }

  const contract = await gaugeContractProxy();

  const tx = await contract.vote(tokenList, valueList);

  return transactionResponse('inspirit.vote', {
    tx: tx,
    uniqueMessage: { text: 'Voting for boosted farms ' },
  });
};
