import contracts from '../contracts';
import { FarmConfig, QuoteToken } from '../types';

const farmRouterV2: FarmConfig[] = [
  {
    pid: 1,
    isGauge: true,
    lpSymbol: 'SPIRIT-BEETS LP',
    lpAddresses: {
      4002: '',
      250: '0x17eF19712CF665A408d35690d35de023eE6E02DA',
    },
    gaugeAddress: '0xEFe02cB895b6E061FA227de683C04F3Ce19f3A62',
    tokenSymbol: 'BEETS',
    tokenAddresses: {
      4002: '',
      250: '0xF24Bcf4d1e507740041C9cFd2DddB29585aDCe1e',
    },
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
    isRouterV2: true,
  },
];

export default farmRouterV2;
