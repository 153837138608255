import { CHAIN_ID, BASE_TOKEN_ADDRESS } from 'constants/index';
// import BigNumber from 'bignumber.js';
import allFarms, { farms, farmsV2 } from 'constants/farms';
import addresses from 'constants/contracts';
import { tokens } from 'constants/tokens';
import { tokens as bridgeTokens } from 'constants/bridgeTokens';
import { getLiquityPoolStatistics } from 'utils/data';
import {
  formatFrom,
  getNativeTokenBalance,
  getPooledData,
  Multicall,
} from 'utils/web3';
import { Call, balanceReturnData, tokenData } from './types';
import safeExecute from 'utils/safeExecute';
import {
  fiat,
  formatAmountChange,
  getTokenGroupStatistics,
} from './formatters';
import { getTokensDetails, getWalletData } from './covalent';
import { FarmConfig } from 'constants/types';
import { getSobPoolsData } from './pools';
import { formatUnits } from 'ethers/lib/utils';
import { UNIDEX_ETH_ADDRESS } from 'utils/swap';

const FARMS = allFarms;

// Removes first element of the array.
// We do this because the first two farms are technically the same.
FARMS.shift();

// Used to quickly verify if an address matches whitelisted token
export const whitelistedTokenData = () => {
  const addressMapping = {};

  tokens.forEach(a => {
    addressMapping[`${a.address}`.toLowerCase()] = a;
  });

  return addressMapping;
};

export const getUsersLPTokens = async (
  _user,
  _filteredAddress: string[],
  _network = CHAIN_ID,
) => {
  const calls: Call[] = [];
  const filteredFarms: FarmConfig[] = [];

  FARMS.forEach(farm => {
    let makeCall = true;

    if (
      _filteredAddress &&
      _filteredAddress.length > 0 &&
      !_filteredAddress.includes(farm.lpAddresses[_network].toLowerCase())
    ) {
      makeCall = false;
    }

    if (makeCall && farm.lpAddresses[_network]) {
      calls.push({
        name: 'balanceOf',
        address: farm.lpAddresses[_network],
        params: [_user],
        data: farm,
      });

      filteredFarms.push(farm);
    }
  });

  if (!calls.length) {
    const returnData: balanceReturnData = {
      farmList: [],
      diffAmount: '',
      diffPercent: '',
      diffPercentValue: 0,
      totalValue: '',
      total24Value: '',
      totalValueNumber: 0,
      total24ValueNumber: 0,
    };
    return returnData;
  }

  const lpBalances = await Multicall(calls, 'erc20');

  filteredFarms.forEach((farm, i) => {
    const item = lpBalances[i];
    item.address = farm.lpAddresses[_network];
    item.symbol = farm.lpSymbol;
    item.balance = formatFrom(item.response[0]);
  });

  const liquidity = getTokenGroupStatistics(lpBalances, 'farmList', {});

  return liquidity;
};

export const getUserStakedBalance = async (_user, _network = CHAIN_ID) => {
  const calls: Call[] = [];

  farms.forEach(farm => {
    calls.push({
      name: 'userInfo',
      params: [farm.pid, _user],
      data: farm,
    });
  });

  const data = await Multicall(calls, 'masterchef');

  // We include the lpAddress
  data.forEach((userInfo, index) => {
    const thisFarm = farms[index];
    const symbol = thisFarm?.lpSymbol ?? '';
    userInfo.address = thisFarm.lpAddresses[_network];
    userInfo.symbol = symbol.replace('LP', '');
    userInfo.balance = formatFrom(userInfo.response[0]);
  });

  const callsTwo: Call[] = [];
  farmsV2.forEach(farm => {
    callsTwo.push({
      name: 'balanceOf',
      params: [_user],
      address: farm.gaugeAddress,
      data: farm,
    });
  });

  const v2Data = await Multicall(callsTwo, 'gauge');

  // We include the lpAddress
  v2Data.forEach((callResponse, index) => {
    const thisFarm = farmsV2[index];
    const symbol = thisFarm?.lpSymbol ?? '';
    callResponse.address = thisFarm.lpAddresses[_network];
    callResponse.symbol = symbol;
    callResponse.balance = formatFrom(callResponse.response[0]);
  });

  return data.concat(v2Data);
};

export const getStakedBalances = async (
  _address: string,
  dataToMerge: balanceReturnData,
) => {
  // We then loop through the farm balances. Their value gets added to the value of portfolio
  const farmData = await getUserStakedBalance(_address);
  const liquidity = getTokenGroupStatistics(farmData, 'stakeList', {});

  if (dataToMerge && dataToMerge.farmList && liquidity.farmList) {
    // Calculate values to include both staked and unstaked liquidity tokens
    liquidity.farmList = dataToMerge.farmList.concat(liquidity.farmList);
  }
  // We get our liquidity pool data here
  // This is where we get pricing data we'll use to assign value to the liquidity pools
  const lpStatistics = await getLiquityPoolStatistics();
  let tokenCurrentTotal = 0;

  // Get the pricing information into liquidity data
  // TODO: Covalent does not provide 24h data for LPs. Need new source.
  liquidity.farmList?.forEach(poolToken => {
    const lpTokenBalance = parseFloat(`${poolToken.amount || 0}`);
    const lpData = lpStatistics[`${poolToken.address}`.toLowerCase()];
    const statistics = lpData?.statistics;

    if (statistics && lpTokenBalance) {
      const totalUsd = lpTokenBalance * statistics.quote_rate;
      poolToken.rate = statistics.quote_rate;
      poolToken.usd = parseFloat(`${totalUsd.toFixed(2)}`);
      tokenCurrentTotal += parseFloat(`${totalUsd}`);
    }
  });

  liquidity.totalValueNumber = tokenCurrentTotal;
  liquidity.totalValue = fiat(tokenCurrentTotal, '');

  return liquidity;
};

export const getUserBalances = async (
  _address: string,
  _network = CHAIN_ID,
) => {
  const data = await safeExecute(() => getWalletData(_address, _network));

  if (!data) {
    return null;
  }

  const contractAddresses = data.items.map(token =>
    token.contract_address.toLowerCase(),
  );

  const tokensData = getTokenGroupStatistics(
    data.items,
    'tokenList',
    _network === CHAIN_ID ? whitelistedTokenData() : {}, // We only whitelist for FTM
  );

  const liquidity = await getUsersLPTokens(_address, contractAddresses);

  const promises: Promise<any>[] = [];

  let liquidityWithPooledData = liquidity;

  if (liquidity.farmList && liquidity.farmList.length) {
    liquidityWithPooledData.farmList?.forEach(lpToken => {
      promises.push(getPooledData(lpToken.address, lpToken.amount));
    });

    const details = await Promise.all(promises);

    liquidityWithPooledData.farmList?.forEach((lpToken, i) => {
      const extraData = details[i];

      lpToken.token0 = tokens.filter(
        token =>
          `${extraData.token0}`.toLowerCase() ===
          `${token.address}`.toLowerCase(),
      )[0];
      lpToken.token1 = tokens.filter(
        token =>
          `${extraData.token1}`.toLowerCase() ===
          `${token.address}`.toLowerCase(),
      )[0];
      lpToken.pooled0 = extraData.pooled0;
      lpToken.pooled1 = extraData.pooled1;
      lpToken.lpSupply = extraData.lpSupply;
    });
  }

  const sobLiquidity = (await getSobPoolsData(_address)) as tokenData[];

  return {
    tokens: tokensData,
    liquidity: liquidityWithPooledData,
    sobLiquidity,
  };
};

export const getBalancesFromChain = async (
  _userWalletAddress,
  _tokens: tokenData[],
  chainId = CHAIN_ID,
) => {
  const tokensData = JSON.parse(JSON.stringify(_tokens)) || [];
  const calls: Call[] = [];
  const decimalCalls: Call[] = [];
  const [defaultToken] = bridgeTokens[chainId].filter(
    token => token.address !== '0x0000000000000000000000000000000000000000',
  );

  const isNativeToken = (token: tokenData) =>
    ['FTM'].includes(token.symbol) ||
    [
      `${UNIDEX_ETH_ADDRESS}`.toLowerCase(),
      `${BASE_TOKEN_ADDRESS}`.toLowerCase(),
    ].includes(`${token.address}`.toLowerCase());

  tokensData.forEach(token => {
    calls.push({
      name: 'balanceOf',
      // Native token will fail erc-20 call, we give it an address that won't fail the erc-20 balance check
      address: isNativeToken(token) ? defaultToken.address : token.address,
      params: [_userWalletAddress],
    });

    decimalCalls.push({
      name: 'decimals',
      address: isNativeToken(token) ? defaultToken.address : token.address,
    });
  });

  const balances = await Multicall(calls, 'erc20', chainId);
  const decimals = await Multicall(decimalCalls, 'erc20', chainId);
  const nativeBalance = await getNativeTokenBalance(
    _userWalletAddress,
    chainId,
  );

  // Now that we have the balances, we tie them to the wallet data so they have latest balances
  let totalValue = 0;
  let totalValue24 = 0;

  tokensData.forEach((token, index) => {
    const tokenDecimals = parseInt(decimals[index]?.response[0].toString(), 10);
    const multiCallBalance = balances[index]?.response
      ? formatUnits(balances[index]?.response[0], tokenDecimals)
      : '0';
    const balance = isNativeToken(token) ? nativeBalance : multiCallBalance;

    token.amount = `${balance}`;
    token.usd = token.rate * parseFloat(balance);
    totalValue += token.usd ? parseFloat(token.usd) : 0;
    totalValue24 += parseFloat(token.usd_24);
  });

  const tokenDifference = totalValue - totalValue24;

  const summary: balanceReturnData = {
    tokenList: tokensData,
    totalValue: fiat(totalValue, ''),
    total24Value: fiat(totalValue24, ''),
    totalValueNumber: totalValue,
    total24ValueNumber: totalValue24,
    diffAmount: formatAmountChange(tokenDifference, ''),
    diffPercent: tokenDifference
      ? formatAmountChange(tokenDifference / totalValue24, '')
      : '0',
    diffAmountValue: tokenDifference,
    diffPercentValue: tokenDifference ? tokenDifference / totalValue24 : 0,
  };

  return summary;
};

export const reconciliateBalances = async (
  _userWalletAddress,
  _wallet,
  _trackedTokens,
  chainId = CHAIN_ID,
) => {
  const { tokenList } = _wallet.tokens ?? undefined;
  const tokensToAdd: string[] = [];

  _trackedTokens?.forEach(tToken => {
    const [exists] = tokenList?.filter(
      token => `${tToken.address}` === `${token.address}`,
    );

    if (!exists && `${tToken.chainId}` === `${chainId}`) {
      tokensToAdd.push(tToken.address);
    }
  });

  let newTokenList = _wallet.tokens.tokenList;

  if (tokensToAdd.length) {
    const tokensDetails = await getTokensDetails(tokensToAdd);
    newTokenList = tokenList.concat(tokensDetails);
  }

  const updateBalances = await getBalancesFromChain(
    _userWalletAddress,
    newTokenList,
    chainId,
  );

  const reconciliatedData = {
    tokens: updateBalances,
    liquidity: _wallet.liquidity, // Liquidity data is already derived from blockchain calls
  };

  return reconciliatedData;
};

export const getV2Earnings = async (_address: string, _network = CHAIN_ID) => {
  const calls: Call[] = farmsV2.map(farm => {
    const call: Call = {
      address: farm.gaugeAddress,
      name: 'earned',
      params: [_address],
      data: farm,
    };

    return call;
  });

  const rawEarnings = await Multicall(calls, 'gauge');

  return rawEarnings;
};

export const getRegularEarnings = async (
  _userAddress: string,
  _network = CHAIN_ID,
) => {
  const calls: Call[] = farms.map(farm => {
    const call: Call = {
      address: addresses.masterchef[CHAIN_ID],
      name: 'pendingSpirit',
      params: [farm.pid, _userAddress],
      data: farm,
    };

    return call;
  });

  const earningsMulticall = await Multicall(calls, 'masterchef');
};

export const getPendingRewards = async (
  _userAddress: string,
  _lpAddress: string,
  _network = CHAIN_ID,
) => {
  // TODO: We can redo this as an array of addresses
  let [farm] = farmsV2.filter(
    farm => farm.lpAddresses[_network] === _lpAddress,
  );

  if (farm) {
    const response = await Multicall(
      [
        {
          address: farm.gaugeAddress,
          name: 'earned',
          params: [_userAddress],
        },
      ],
      'gauge',
    );

    return {
      pid: farm.pid,
      lpAddress: farm.lpAddresses[_network],
      gaugeAddress: farm.gaugeAddress,
      earned: response ? response[0]?.response.toString() : 0,
    };
  }

  [farm] = farms.filter(farm => farm.lpAddresses[_network]);

  if (farm) {
    const response = await Multicall(
      [
        {
          address: addresses.masterchef[_network],
          name: 'pendingSpirit',
          params: [farm.pid, _userAddress],
        },
      ],
      'masterchef',
    );

    return {
      pid: farm.pid,
      lpAddress: farm.lpAddresses[_network],
      earned: response ? response[0]?.response.toString() : 0,
    };
  }

  return null;
};
