import BigNumber from 'bignumber.js';
import { CHAIN_ID } from 'constants/index';
import contracts from 'constants/contracts';
import PAIR_ABI from '../abis/IUniswapV2Pair.json';
import { Contract } from '../contracts';
import { getProvider } from 'app/connectors/EthersConnector/login';
import { ethers } from 'ethers';

export const gaugeContract = async (
  _farmGaugeAddress: string,
  _chainId = CHAIN_ID,
) => {
  const _connector = getProvider();
  const instance = await Contract(_farmGaugeAddress, 'gauge', _connector);

  return instance;
};

export const gaugeContractProxy = async () => {
  const _connector = getProvider();
  const gaugeContract = await Contract(
    contracts.gauge[CHAIN_ID],
    'gaugeproxy',
    _connector,
    CHAIN_ID,
  );

  return gaugeContract;
};

export const masterChefContract = async (_version = 0, _chainId = CHAIN_ID) => {
  const _connector = getProvider();
  const instance = await Contract(
    contracts.masterchef[CHAIN_ID],
    !_version ? 'masterchef' : 'masterchef2',
    _connector,
    _chainId,
  );

  return instance;
};

export const stakePoolToken = async (_pid: number, _amount: string) => {
  const masterchef = await masterChefContract();

  const depositAmount = new BigNumber(_amount)
    .times(new BigNumber(10).pow(18))
    .toString();

  const tx = await masterchef.deposit(_pid, depositAmount);

  return tx;
};

export const stakeGaugePoolToken = async (
  _farmAddress: string,
  _amount: string,
  _chainId = CHAIN_ID,
) => {
  const gauge = await gaugeContract(_farmAddress, _chainId);

  const depositAmount = new BigNumber(_amount)
    .times(new BigNumber(10).pow(18))
    .toString();

  const tx = await gauge.deposit(depositAmount);

  return tx;
};

export const unstakePoolToken = async (_pid: number, _amount: string) => {
  const masterchef = await masterChefContract();

  const withdrawAmount = new BigNumber(_amount)
    .times(new BigNumber(10).pow(18))
    .toString();

  const tx = await masterchef.withdraw(_pid, withdrawAmount);

  return tx;
};

export const unstakeGaugePoolToken = async (
  _farmAddress: string,
  _amount: string,
  _chainId = CHAIN_ID,
) => {
  const gauge = await gaugeContract(_farmAddress, _chainId);

  const withdrawAmount = new BigNumber(_amount)
    .times(new BigNumber(10).pow(18))
    .toString();

  const tx = await gauge.withdraw(withdrawAmount);

  return tx;
};

export const unstakeGaugePoolTokenAll = async (
  _farmAddress: string,
  _connector = window.ethereum,
  _chainId = CHAIN_ID,
) => {
  const gauge = await gaugeContract(_farmAddress, _chainId);
  const tx = await gauge.exit();

  return tx;
};

export const harvest = async (_id: number, _chainId = CHAIN_ID) => {
  const masterchef = await masterChefContract();

  // Empty deposits harvest rewards in v1 masterchef
  const tx = await masterchef.deposit(_id, '0');

  return tx;
};

export const gaugeHarvest = async (
  _farmAddress: string,
  _chainId = CHAIN_ID,
) => {
  const gauge = await gaugeContract(_farmAddress, _chainId);

  const tx = await gauge.getReward();

  return tx;
};

export const farmStatus = async (
  _farmAddress: string,
  _gaugeAddress: string,
  account: string,
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = await provider.getSigner();
  const lpContract = new ethers.Contract(_farmAddress, PAIR_ABI, signer);

  const allowance = await lpContract.allowance(account, _gaugeAddress);

  return allowance;
};

export const approveFarm = async (
  _farmAddress: string,
  _gaugeAddress: string,
  _chainId = CHAIN_ID,
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = await provider.getSigner();
  const lpContract = new ethers.Contract(_farmAddress, PAIR_ABI, signer);
  const approve = await lpContract.approve(
    _gaugeAddress,
    ethers.constants.MaxUint256.toString(),
  );
  return approve;
};
