import { CHAIN_ID } from 'constants/index';
import { useEffect, useState } from 'react';
import { getTokensDetails } from 'utils/data/covalent';
import { tokenData } from 'utils/data/types';

interface Props {
  tokenAddresses: string[];
  chainId?: number;
  refresh?: number;
}

const useGetTokensPrices = ({
  tokenAddresses,
  refresh = 0,
  chainId = CHAIN_ID,
}: Props) => {
  const [tokensPrices, setTokensPrices] =
    useState<{ [symbol: string]: tokenData }>();
  const [loadingPrices, setLoadingPrices] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingPrices(true);
        const data = await getTokensDetails(tokenAddresses, chainId);
        let tokenObjectPrices = {};
        data.forEach(token => {
          tokenObjectPrices[token.symbol] = token;
        });
        setTokensPrices(tokenObjectPrices);
        setLoadingPrices(false);
      } catch (error) {
        setLoadingPrices(false);
      }
    };
    tokenAddresses && chainId && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, refresh]);

  return {
    tokensPrices,
    loadingPrices,
  };
};

export default useGetTokensPrices;
