import addresses from 'constants/contracts';
import { BigNumber, Contract as ContractItf } from 'ethers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { MaxUint256 } from '@ethersproject/constants';
import { BASE_TOKEN_ADDRESS, CHAIN_ID } from 'constants/index';
import { DEFAULT_GAS_LIMIT } from './general';
import { Contract } from '../contracts';
import {
  Token,
  TokenAmount,
  SobToken,
  SobSwap,
  SobTokenPool,
} from 'app/interfaces/General';
import { DEFAULT_DEADLINE_FROM_NOW } from 'constants/index';
import tokens, { WFTM } from 'constants/tokens';
import AllFarms, { farmsRouterV2 } from 'constants/farms';
import {
  transactionResponse,
  calculateGasMargin,
  calculateSlippageAmount,
} from './utils';
import { formatAmount } from 'app/utils';
import { getProvider } from 'app/connectors/EthersConnector/login';
import contracts from 'constants/contracts';

const BATCH_SWAP_TYPE_IN = 0;

export const sobVaultContract = async () => {
  const _connector = getProvider();
  const sobVContract = await Contract(
    addresses.sobVault[CHAIN_ID],
    'sobVault',
    _connector,
    CHAIN_ID,
  );

  return sobVContract;
};

export const addSobLiquidity = async (
  poolSelected,
  swapAmounts,
  tokensWithAmount,
  account: string,
) => {
  const contract = await sobVaultContract();

  const tokenAddresses = buildTokenaddresses(poolSelected);

  const tokenAmountMap = buildTokenAmountMap(tokensWithAmount);

  const swaps = buildSwaps(poolSelected, tokenAmountMap, tokenAddresses);

  const funds = getFundManagement(account);

  const limits = swapAmounts.map(swapAmount => swapAmount.toString());

  const tx = await contract.batchSwap(
    BATCH_SWAP_TYPE_IN,
    swaps,
    tokenAddresses,
    funds,
    limits,
    MaxUint256,
    {
      gasLimit: DEFAULT_GAS_LIMIT,
    },
  );

  return transactionResponse('liquidity.add', {
    operation: 'LIQUIDITY',
    tx: tx,
  });
};

export const removeSobLiquidity = async (
  poolSelected,
  swapAmounts,
  tokensWithAmount,
  account: string,
) => {
  const contract = await sobVaultContract();

  const tokenAddresses = buildTokenaddresses(poolSelected);

  const tokenAmountMap = buildTokenAmountMap(tokensWithAmount);

  const swaps = buildSwaps(poolSelected, tokenAmountMap, tokenAddresses);

  const funds = getFundManagement(account);

  const limits = swapAmounts.map(swapAmount => swapAmount.toString());

  const tx = await contract.batchSwap(
    BATCH_SWAP_TYPE_IN,
    swaps,
    tokenAddresses,
    funds,
    limits,
    MaxUint256,
    {
      gasLimit: DEFAULT_GAS_LIMIT,
    },
  );
  return transactionResponse('Liquidity Added', tx);
};

export const getBatchSwap = async (
  poolSelected: SobTokenPool | undefined,
  tokensWithAmount: TokenAmount[] | undefined,
  account: string,
): Promise<BigNumber[]> => {
  let assets: string[] = [];
  if (poolSelected && tokensWithAmount) {
    assets = buildTokenaddresses(poolSelected);

    const tokenAmountMap = buildTokenAmountMap(tokensWithAmount);

    const swaps = buildSwaps(poolSelected, tokenAmountMap, assets);

    const contract = await sobVaultContract();

    const funds = await getFundManagement(account.toLowerCase());

    return await contract.callStatic.queryBatchSwap(
      BATCH_SWAP_TYPE_IN,
      swaps,
      assets,
      funds,
    );
  } else {
    return [];
  }
};

const buildTokenaddresses = (poolToken: SobTokenPool): string[] => {
  let addresses: string[] = [];
  const sobTokens = poolToken.sobTokens as SobToken[];
  sobTokens?.forEach(token => {
    addresses = [...addresses, token.tokenIn.address, token.address];
  });
  addresses = [...addresses, poolToken.address];
  return addresses;
};

const buildTokenAmountMap = (tokenAmounts: TokenAmount[]) => {
  const tokenAmountsMap = new Map();
  tokenAmounts.forEach(t => {
    tokenAmountsMap.set(
      t.token.address,
      parseUnits(t.amount, t.token.decimals).toString(),
    );
  });
  return tokenAmountsMap;
};

declare type FundManagement = {
  sender: string;
  fromInternalBalance: boolean;
  recipient: string;
  toInternalBalance: boolean;
};

const getFundManagement = (account: string): FundManagement => {
  const funds: FundManagement = {
    sender: account,
    recipient: account,
    fromInternalBalance: false,
    toInternalBalance: false,
  };
  return funds;
};

const buildSwaps = (poolSelected, tokenAmountMap, assets) => {
  let swaps: SobSwap[] = [];
  poolSelected.sobTokens.forEach(sobToken => {
    const swapA = {
      poolId: sobToken.poolId,
      assetInIndex: assets.indexOf(sobToken.tokenIn.address),
      assetOutIndex: assets.indexOf(sobToken.address),
      amount: tokenAmountMap.get(sobToken.tokenIn.address) ?? '0',
      userData: '0x',
    };
    const swapB = {
      poolId: poolSelected.poolId,
      assetInIndex: assets.indexOf(sobToken.address),
      assetOutIndex: assets.indexOf(poolSelected.address),
      amount: tokenAmountMap.get(sobToken.address) ?? '0',
      userData: '0x',
    };
    swaps = [...swaps, swapA, swapB];
  });
  return swaps;
};

export interface AddLiquidityTrade {
  lpSymbol: string;
  lpAddress: string;
  tokenA: Token;
  tokenB: Token;
  amountA: string;
  optimizedAmountA: string;
  slippageA: string[];
  amountB: string;
  optimizedAmountB: string;
  slippageB: string[];
  reservesA: string;
  reservesB: string;
  totalSupply: string;
  method: string;
  liquidity: number;
  minimumLiquidity: string;
  ownership: number;
  slippage?: string;
  deadline?: string;
}

export interface PairTradeData {
  buyToken: Token;
  sellToken: Token;
  buyAmount: string;
  sellAmount: string;
  buyReserve: string;
  sellReserve: string;
  price: string;
}

export interface PairData {
  pair: ContractItf;
  tokenAisToken0: boolean;
  reserveOne: BigNumber;
  reserveTwo: BigNumber;
  totalSupply: BigNumber;
  price?: number;
  pooledOne?: number;
  pooledTwo?: number;
  poolShare?: number;
}

export const routerContract = async (
  _connector = getProvider(),
  _chainId = CHAIN_ID,
) => {
  const routerInstance = await Contract(
    addresses.router[CHAIN_ID],
    'router',
    _connector,
    _chainId,
  );

  return routerInstance;
};

export const routerV2Contract = async (
  _connector = getProvider(),
  _chainId = CHAIN_ID,
) => {
  const routerInstance = await Contract(
    addresses.routerV2[CHAIN_ID],
    'router',
    _connector,
    _chainId,
  );

  return routerInstance;
};

export const pairContract = async (
  _pairAddress: string,
  _connector = 'rpc',
  _chainId = CHAIN_ID,
) => {
  const pairInstance = await Contract(
    _pairAddress,
    'pair',
    _connector,
    _chainId,
  );

  return pairInstance;
};

export const getMintFee = (_reserveA: number, _reserveB: number) => {};

export const getLiquidityMinted = (
  _totalSupplyOfPair: BigNumber,
  _tokenAmountA: BigNumber,
  _tokenAmountB: BigNumber,
  _reserveA: BigNumber,
  _reserveB: BigNumber,
  _minimumLiquidity?: BigNumber,
) => {
  // let liquidity: number = 0;
  let liquidity: BigNumber = BigNumber.from(0);
  const amountA = _tokenAmountA;
  const amountB = _tokenAmountB;

  if (_totalSupplyOfPair.toString() === '0') {
    if (!_minimumLiquidity) {
      throw new Error('LIQUIDITY: Minimum liquidity needs to be provided');
    }
    // liquidity = Math.sqrt(amountA.mul(amountB).toString()) - _minimumLiquidity;
  } else {
    /*
    liquidity = Math.min(
      parseFloat(amountA.mul(_totalSupplyOfPair).div(_reserveA).toString()),
      parseFloat(amountB.mul(_totalSupplyOfPair).div(_reserveB).toString()),
    ); */
    const a = amountA.mul(_totalSupplyOfPair).div(_reserveA);
    const b = amountB.mul(_totalSupplyOfPair).div(_reserveB);
    liquidity = a.gt(b) ? b : a;
  }

  // if (liquidity <= 0) {
  if (liquidity.lt(0)) {
    // return 0;
    return BigNumber.from(0);
  }

  return liquidity;
};

export const quote = (
  minimumA: BigNumber,
  reserveA: BigNumber,
  reserveB: BigNumber,
) => {
  let equivalentAmount = minimumA.mul(reserveB).div(reserveA);
  return equivalentAmount;
};

export const getPairData = async (
  _tokenA: Token,
  _tokenB: Token,
  _lpTokenAmount = 0,
  _chainId = CHAIN_ID,
) => {
  let pairAddress: string = '';

  const [pool] = AllFarms.filter(farm => {
    const lpSymbol = farm.lpSymbol.replace(' LP', '').split('-');

    return (
      lpSymbol.includes(_tokenA.symbol) && lpSymbol.includes(_tokenB.symbol)
    );
  });

  // Returns undefined if the pool does not exists
  if (pool) {
    pairAddress = pool.lpAddresses[_chainId];
  } else {
    const factoryContract = await Contract(
      contracts.factory[CHAIN_ID],
      'factory',
    );
    const lpPair = await factoryContract.getPair(
      _tokenA.address,
      _tokenB.address,
    );

    if (!lpPair) return undefined;
    else pairAddress = lpPair;
  }

  // We call the pair contract to get data for calculations
  const pair = await pairContract(pairAddress);
  const token0 = await pair.token0();

  const totalSupply = await pair.totalSupply();
  const [reserveOne, reserveTwo] = await pair.getReserves();

  const tokenA =
    `${_tokenA.address}` === `${BASE_TOKEN_ADDRESS}`
      ? WFTM.address
      : _tokenA.address;

  const tokenAisToken0 =
    `${tokenA}`.toLowerCase() === `${token0}`.toLowerCase();

  // We get the minimum amounts here
  let pooledOne = 0;
  let pooledTwo = 0;
  let poolShare = 0;

  if (_lpTokenAmount) {
    pooledOne = reserveOne.mul(_lpTokenAmount).div(totalSupply);
    pooledTwo = reserveTwo.mul(_lpTokenAmount).div(totalSupply);
    poolShare = _lpTokenAmount / totalSupply;
  }

  const response: PairData = {
    pair,
    tokenAisToken0,
    reserveOne,
    reserveTwo,
    totalSupply,
    pooledOne,
    pooledTwo,
    poolShare,
  };

  return response;
};

export const getPooledData = async (
  _lpAddress: string,
  _lpTokenAmount = '0',
  _chainId = CHAIN_ID,
) => {
  // We call the pair contract to get data for calculations
  const pair = await pairContract(_lpAddress);

  const token0 = await pair.token0();
  const token1 = await pair.token1();
  const totalSupply = await pair.totalSupply();
  const [reserveOne, reserveTwo] = await pair.getReserves();

  // We get the minimum amounts here
  let pooled0 = 0;
  let pooled1 = 0;
  let poolShare = 0;

  if (parseFloat(_lpTokenAmount) > 0.000001) {
    const lpTokenAmount = parseUnits(_lpTokenAmount, 18);
    pooled0 = parseFloat(
      reserveOne.mul(lpTokenAmount).div(totalSupply).toString(),
    );
    pooled1 = parseFloat(
      reserveTwo.mul(lpTokenAmount).div(totalSupply).toString(),
    );
  }

  const response = {
    token0,
    token1,
    pooled0,
    pooled1,
    poolShare,
    lpSupply: parseFloat(totalSupply.toString()) / 10 ** 18,
  };

  return response;
};

export const pairTradingData = async params => {
  // TODO: Remove usage of quoteRate API, not really necessary
  const { buyToken, sellToken } = params;
  const pairData = await getPairData(params.buyToken, params.sellToken);

  if (!pairData) {
    return null;
  }

  const { tokenAisToken0, reserveOne, reserveTwo } = pairData;

  const buyReserve = tokenAisToken0 ? reserveOne : reserveTwo;
  const sellReserve = tokenAisToken0 ? reserveTwo : reserveOne;
  let buyAmount = BigNumber.from(params.buyAmount);
  let sellAmount = BigNumber.from(params.sellAmount);

  if (buyAmount.eq(0)) {
    buyAmount = quote(sellAmount, sellReserve, buyReserve);
  }

  if (sellAmount.eq(0)) {
    sellAmount = quote(buyAmount, buyReserve, sellReserve);
  }

  const price =
    parseFloat(sellAmount.toString()) / parseFloat(buyAmount.toString());

  const response: PairTradeData = {
    price: price.toString(),
    buyToken,
    sellToken,
    buyAmount: buyAmount.toString(),
    sellAmount: sellAmount.toString(),
    buyReserve: buyReserve.toString(),
    sellReserve: sellReserve.toString(),
  };

  return response;
};

export const getLiquidityData = async (
  _tokenA: Token,
  _tokenB: Token,
  _amountA: string,
  _amountB: string,
  _balanceA: string,
  _balanceB: string,
  _slippage: number = 10, // Slippage amount
  _chainId = CHAIN_ID,
  isV2?: boolean,
) => {
  // Finds if the requested pool is whitelisted
  let pool;
  if (isV2) {
    [pool] = farmsRouterV2.filter(farm => {
      const lpSymbol = farm.lpSymbol.replace(' LP', '').split('-');
      return (
        lpSymbol.includes(_tokenA.symbol) && lpSymbol.includes(_tokenB.symbol)
      );
    });
  } else {
    [pool] = AllFarms.filter(farm => {
      const lpSymbol = farm.lpSymbol.replace(' LP', '').split('-');
      return (
        lpSymbol.includes(_tokenA.symbol) && lpSymbol.includes(_tokenB.symbol)
      );
    });
  }

  // Returns undefined if the pool does not exists
  if (!pool) {
    return undefined;
  }

  const pairAddress = pool.lpAddresses[_chainId];

  // We call the pair contract to get data for calculations
  const pair = await pairContract(pairAddress);
  const token0 = await pair.token0();
  const totalSupply = await pair.totalSupply();
  const [reserveOne, reserveTwo] = await pair.getReserves();
  const minimumLiquidity = await pair.MINIMUM_LIQUIDITY();
  const decimals = await pair.decimals();

  // We organize our data based on whether tokenA is the first token of the pair
  const tokenAisToken0 = _tokenA.address === token0;

  const amountA = tokenAisToken0 ? _amountA : _amountB;
  const amountB = tokenAisToken0 ? _amountB : _amountA;

  const reservesA = tokenAisToken0 ? reserveOne : reserveTwo;
  const decimalsA = tokenAisToken0 ? _tokenA.decimals : _tokenB.decimals;

  const reservesB = tokenAisToken0 ? reserveTwo : reserveOne;
  const decimalsB = tokenAisToken0 ? _tokenB.decimals : _tokenA.decimals;

  const BigNumberA = parseUnits(amountA, decimalsA);
  const BigNumberB = parseUnits(amountB, decimalsB);

  // We calculate the optimized amounts for trade here, we treat amountA and amountB as minimums
  const optimizedAmountA = quote(BigNumberB, reservesB, reservesA);
  const optimizedAmountB = quote(BigNumberA, reservesA, reservesB);

  const liquidity = getLiquidityMinted(
    totalSupply,
    BigNumberA,
    BigNumberB,
    reservesA,
    reservesB,
    minimumLiquidity,
  );

  const liquidityF = parseFloat(liquidity.toString());

  const data: AddLiquidityTrade = {
    lpSymbol: pool.lpSymbol,
    lpAddress: pairAddress,
    tokenA: tokenAisToken0 ? _tokenA : _tokenB,
    tokenB: tokenAisToken0 ? _tokenB : _tokenA,
    amountA,
    amountB,
    reservesA: formatUnits(reservesA, decimalsA),
    reservesB: formatUnits(reservesB, decimalsB),
    totalSupply: formatUnits(totalSupply, decimals),
    // TODO: Make this blockchain dependent
    method:
      _tokenA.symbol === 'FTM' || _tokenB.symbol === 'FTM'
        ? 'addLiquidityETH'
        : 'addLiquidity',
    liquidity: parseFloat(`${formatUnits(`${liquidity}`, decimals)}`),
    ownership: liquidityF / (liquidityF + parseFloat(totalSupply)) || 0,
    minimumLiquidity: formatUnits(minimumLiquidity, decimals),
    slippageA: calculateSlippageAmount(BigNumberA, _slippage),
    slippageB: calculateSlippageAmount(BigNumberB, _slippage),
    optimizedAmountA: formatUnits(optimizedAmountA, decimalsA),
    optimizedAmountB: formatUnits(optimizedAmountB, decimalsB),
  };

  return data;
};

export const addLiquidity = async (
  _userAddress: string,
  _trade: AddLiquidityTrade | undefined,
  isV2?: boolean,
) => {
  if (!_trade) {
    throw new Error('Need to provide trade data');
  }
  const deadlineFromNow =
    Math.ceil(Date.now() / 1000) +
    (parseInt(`${_trade.deadline}`) || DEFAULT_DEADLINE_FROM_NOW);

  // We load our routes and blokchain data here
  let router;
  if (isV2) {
    router = await routerV2Contract();
  } else {
    router = await routerContract();
  }
  const method = router[_trade.method || 'addLiquidity'];
  const estimate = router.estimateGas[_trade.method || 'addLiquidity'];

  const decimalsA = _trade.tokenA.decimals;
  const decimalsB = _trade.tokenB.decimals;

  const desiredAmountA = parseUnits(_trade.amountA, decimalsA).toString();
  const desiredAmountB = parseUnits(_trade.amountB, decimalsB).toString();
  const minimumAmountA = _trade.slippageA[0].toString();
  const minimumAmountB = _trade.slippageB[0].toString();

  let args = [
    _trade.tokenA.address,
    _trade.tokenB.address,
    desiredAmountA,
    desiredAmountB,
    minimumAmountA,
    minimumAmountB,
    _userAddress,
    deadlineFromNow,
  ];

  let value: BigNumber = BigNumber.from('0');
  const firstIsTarget = _trade.tokenA.symbol !== 'FTM';

  if (`${_trade.method}` === 'addLiquidityETH') {
    const targetAddress = firstIsTarget
      ? _trade.tokenA.address
      : _trade.tokenB.address;
    const targetDesiredAmount = firstIsTarget ? desiredAmountA : desiredAmountB;
    const targetMinimumAmount = firstIsTarget ? minimumAmountA : minimumAmountB;
    const minimumFTM = firstIsTarget ? minimumAmountB : minimumAmountA;

    args = [
      targetAddress, // Address of target
      targetDesiredAmount,
      targetMinimumAmount,
      minimumFTM,
      _userAddress,
      deadlineFromNow,
    ];

    const valueAmount = firstIsTarget ? _trade.amountB : _trade.amountA;
    value = parseUnits(valueAmount, 18);
  }

  const estimatedGas = await estimate(...args, value ? { value } : {});

  const tx = await method(...args, {
    value,
    gasLimit: calculateGasMargin(estimatedGas),
  });

  return transactionResponse('liquidity.add', {
    tx: tx,
    operation: 'LIQUIDITY',
    inputSymbol: _trade.tokenA.symbol,
    inputValue: formatAmount(_trade.amountA, _trade.tokenA.decimals),
    outputSymbol: _trade.tokenB.symbol,
    outputValue: formatAmount(_trade.amountB, _trade.tokenB.decimals),
  });
};

export const removeLiquidity = async (
  _userAddress: string,
  _pair: Token,
  _amount: string,
  _slippage: number = 10, // Slippage amount
  _deadline: number = DEFAULT_DEADLINE_FROM_NOW,
  _chainId = CHAIN_ID,
) => {
  const symbols = _pair.symbol.replace(' LP', '').split('-');
  const pairHasETH = symbols.includes('FTM');
  const router = await routerContract();

  const pair = await pairContract(_pair.address);
  const token0 = await pair.token0();
  const token1 = await pair.token1();

  const [reserveOne, reserveTwo] = await pair.getReserves();
  const totalSupply = await pair.totalSupply();

  const liquidityAmount = parseUnits(_amount, 18);

  // We get the minimum amounts here
  const returnAmountA = reserveOne.mul(liquidityAmount).div(totalSupply);
  const returnAmountB = reserveTwo.mul(liquidityAmount).div(totalSupply);

  const [tokenA] = tokens.filter(
    token => `${token.address}`.toLowerCase() === `${token0}`.toLowerCase(),
  );
  const [tokenB] = tokens.filter(
    token => `${token.address}`.toLowerCase() === `${token1}`.toLowerCase(),
  );

  let methods: string[];
  let args;

  const deadlineFromNow =
    Math.ceil(Date.now() / 1000) + (_deadline || DEFAULT_DEADLINE_FROM_NOW);

  if (pairHasETH) {
    methods = [
      'removeLiquidityETH',
      'removeLiquidityETHSupportingFeeOnTransferTokens',
    ];

    const tokenADesired = calculateSlippageAmount(
      tokenA?.symbol !== 'WFTM' ? returnAmountA : returnAmountB,
      _slippage,
    )[0].toString();

    const tokenBDesired = calculateSlippageAmount(
      tokenA?.symbol !== 'WFTM' ? returnAmountB : returnAmountA,
      _slippage,
    )[0].toString();

    args = [
      tokenA.symbol !== 'WFTM' ? tokenA.address : tokenB.address,
      liquidityAmount.toString(),
      tokenADesired,
      tokenBDesired,
      _userAddress,
      deadlineFromNow,
    ];
  } else {
    methods = ['removeLiquidity'];
    args = [
      tokenA.address,
      tokenB.address,
      liquidityAmount.toString(),
      calculateSlippageAmount(returnAmountA, _slippage)[0].toString(),
      calculateSlippageAmount(returnAmountB, _slippage)[0].toString(),
      _userAddress,
      deadlineFromNow,
    ];
  }

  const safeGasEstimates = await Promise.all(
    methods.map(method => router.estimateGas[method](...args)),
  );

  const indexOfSuccessfulEstimation = safeGasEstimates.findIndex(
    safeGasEstimate => BigNumber.isBigNumber(safeGasEstimate),
  );

  const methodName = methods[indexOfSuccessfulEstimation];
  const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation];

  const tx = await router[methodName](...args, {
    gasLimit: safeGasEstimate,
  });

  return transactionResponse('liquidity.remove', {
    operation: 'LIQUIDITY',
    tx: tx,
    inputSymbol: tokenA.symbol,
    outputSymbol: tokenB.symbol,
    inputValue: formatAmount(returnAmountA.toString(), tokenA.decimals),
    outputValue: formatAmount(returnAmountB.toString(), tokenB.decimals),
  });
};

export const estimateRemoveLiquidity = async (
  _userAddress: string,
  _lpAddress: string,
  _amount: string,
  _chainId = CHAIN_ID,
) => {
  const pair = await pairContract(_lpAddress);
  const token0 = await pair.token0();
  const token1 = await pair.token1();

  const [reserveOne, reserveTwo] = await pair.getReserves();
  const totalSupply = await pair.totalSupply();

  const liquidityAmount = parseUnits(_amount, 18);

  // We get the minimum amounts here
  const returnAmountA = reserveOne.mul(liquidityAmount).div(totalSupply);
  const returnAmountB = reserveTwo.mul(liquidityAmount).div(totalSupply);

  // TODO: prepare this file to not whitelisted tokens - creating the pair instead of filtering (this is in other functions too)
  const [tokenA] = tokens.filter(
    token => `${token.address}`.toLowerCase() === `${token0}`.toLowerCase(),
  );
  const [tokenB] = tokens.filter(
    token => `${token.address}`.toLowerCase() === `${token1}`.toLowerCase(),
  );

  const tokenAmountA = {
    token: tokenA,
    amount: formatAmount(returnAmountA.toString(), tokenA?.decimals),
  };

  const tokenAmountB = {
    token: tokenB,
    amount: formatAmount(returnAmountB.toString(), tokenB?.decimals),
  };

  const result = new Map();
  result.set(tokenAmountA.token?.address, tokenAmountA);
  result.set(tokenAmountB.token?.address, tokenAmountB);

  return result;
};
