import { CHAIN_ID } from 'constants/index';
import contracts from 'constants/contracts';
import { useEffect, useState } from 'react';
import { Multicall } from 'utils/web3/contracts';
import { BigNumber } from 'ethers';
import { useAppSelector } from 'store/hooks';
import {
  selectSpiritPrice,
  selectTotalSpiritLocked,
} from 'store/general/selectors';
import { Call } from 'utils/web3';
import { getBalanceNumber } from 'app/utils/methods';
import { useRefresh } from './useRefresh';

const useMarketCapPrice = () => {
  const spiritPrice = useAppSelector(selectSpiritPrice);
  const spiritLocked = useAppSelector(selectTotalSpiritLocked);
  const [marketCap, setMarketCap] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const { oneMinRefresh } = useRefresh();

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);

        const calls: Call[] = [
          {
            address: contracts.spirit[CHAIN_ID],
            name: 'balanceOf',
            params: ['0x4D5362dd18Ea4Ba880c829B0152B7Ba371741E59'],
          },
          {
            address: contracts.spirit[CHAIN_ID],
            name: 'balanceOf',
            params: ['0x07E820762910672613636D44c6e70954A6C6052a'],
          },
          {
            address: contracts.spirit[CHAIN_ID],
            name: 'balanceOf',
            params: ['0x513a6e7C37B010B9a4d6fb73204759934d7e8c46'],
          },
          {
            address: contracts.spirit[CHAIN_ID],
            name: 'balanceOf',
            params: ['0xE2c37B0741FCb431A32973e5337dc0388a8666b7'],
          },
          {
            address: contracts.spirit[CHAIN_ID],
            name: 'balanceOf',
            params: [contracts.spiritSwapBuner[CHAIN_ID]],
          },
          {
            address: contracts.spirit[CHAIN_ID],
            name: 'totalSupply',
          },
        ];

        const response = await Multicall(calls, 'erc20');

        const mainWalletBalance: BigNumber = response[0].response.balance;
        const vestingFund: BigNumber = response[1].response.balance;
        const teamFund: BigNumber = response[2].response.balance;
        const daoFund: BigNumber = response[3].response.balance;
        const totalBurnedBalance: BigNumber = response[4].response.balance;
        const [totalSupply] = response[5].response;

        const circSupply = totalSupply
          ? totalSupply.sub(totalBurnedBalance)
          : BigNumber.from(0);

        const marketCapSupply = getBalanceNumber(
          circSupply
            .sub(mainWalletBalance)
            .sub(teamFund)
            .sub(daoFund)
            .sub(vestingFund),
        );

        const marketCap = spiritPrice * (marketCapSupply - spiritLocked);

        setLoading(false);
        setMarketCap(marketCap);
      } catch (error) {
        setLoading(false);
      }
    };

    init();
  }, [spiritPrice, spiritLocked, oneMinRefresh]);

  return {
    marketCap: marketCap,
    loadingMarketCap: loading,
  };
};

export default useMarketCapPrice;
