import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import {
  listenForWalletEvents,
  removeWalletListeners,
  checkWalletConnection,
} from 'utils/web3';
import { useProgressToast } from 'app/hooks/Toasts/useProgressToast';
import { safeExecuteNotAsync } from 'utils/safeExecute';
import pendingTxListener from './listeners/pendingTransactions';
import userBalanceListeners from './listeners/userBalances';
import bridgeListener from './listeners/bridge';
import generalListeners from './listeners/generalStatistics';
import useLogin, { getProvider } from './login';
import { TrackedTokens, GetBridgeTargets, UseWallet } from 'app/utils';

const EthersConnector: FC = ({ children }) => {
  const { account, isLoggedIn, pending } = UseWallet();
  const targets = GetBridgeTargets();
  const dispatch = useAppDispatch();
  const [eventUpdate, setEventUpdate] = useState('');
  const { refreshWallet, handleLogout } = useLogin();
  const { showToast } = useProgressToast();
  const trackedTokens = TrackedTokens();
  const [lastUserBlockUpdate, setLastUserUpdate] = useState<number>(1);
  const [lastGeneralBlockUpdate, setLastGeneralUpdate] = useState<number>(1);

  // Wallet listener for metamask and other wallets
  useEffect(() => {
    const fetch = () => {
      try {
        // If we receive an event update, we refresh our wallet
        if (eventUpdate === 'accountsChanged') {
          refreshWallet();
          return setEventUpdate('');
        }
        listenForWalletEvents(setEventUpdate);
      } catch (error) {}
    };

    fetch();
    return () => {
      removeWalletListeners();
    };
  }, [eventUpdate, setEventUpdate]);

  // Checks pending balances
  useEffect(() => {
    let interval;
    let pendingSocket;
    const fetch = () => {
      try {
        setEventUpdate('');
        if (isLoggedIn) {
          pendingSocket = safeExecuteNotAsync(
            // Initial Attempt to execute balance listener methods immediately
            () => pendingTxListener(pending, dispatch, showToast),
            // Fall method if above initialization fails. Launched 5 seconds af failure of first function
            // Await result and if successful, cancels the interval as we have a healthy connection websocket
            () => {
              interval = setInterval(async () => {
                await pendingTxListener(pending, dispatch, showToast);
                // Clear the interval if function succeds
                clearInterval(interval);
              }, 5000);
              // interval = undefined;
            },
          );
        }
      } catch (error) {}
    };

    fetch();
    return () => {
      if (interval) {
        clearInterval(interval);
      }
      if (pendingSocket) {
        pendingSocket.destroy();
      }
    };
  }, [pending, dispatch, isLoggedIn]);

  useEffect(() => {
    let interval;
    let networkSocketOne;
    let networkSocketTwo;
    const fetch = () => {
      try {
        const chainOne = targets ? targets[0] : '';
        const chainTwo = targets ? targets[1] : '';
        if ((!chainOne && !chainTwo) || !account) {
          // No need to do anything if no chainId. By now previous listener would have been unmounted
          return () => null;
        }

        if (chainOne) {
          networkSocketOne = safeExecuteNotAsync(
            () => bridgeListener(account, trackedTokens, chainOne, 0, dispatch),
            () => {
              interval = setInterval(async () => {
                await bridgeListener(
                  account,
                  trackedTokens,
                  chainOne,
                  0,
                  dispatch,
                );
                clearInterval(interval);
              }, 5000);
            },
          );
        }

        if (chainTwo) {
          networkSocketTwo = safeExecuteNotAsync(
            () => bridgeListener(account, trackedTokens, chainTwo, 1, dispatch),
            () => {
              interval = setInterval(async () => {
                await bridgeListener(
                  account,
                  trackedTokens,
                  chainTwo,
                  1,
                  dispatch,
                );
                clearInterval(interval);
              }, 5000);
            },
          );
        }
      } catch (error) {}
    };

    fetch();
    return () => {
      if (interval) {
        clearInterval(interval);
      }
      if (networkSocketOne) {
        networkSocketOne.destroy();
      }
      if (networkSocketTwo) {
        networkSocketTwo.destroy();
      }
    };
  }, [targets, account, dispatch, trackedTokens]);

  useEffect(() => {
    let generalSocket;
    let generalInterval;

    const fetch = () => {
      try {
        generalSocket = safeExecuteNotAsync(
          // Initial Attempt to execute balance listener methods immediately
          () =>
            generalListeners(
              dispatch,
              lastGeneralBlockUpdate,
              setLastGeneralUpdate,
            ),
          // Fall method if above initialization fails. Launched 5 seconds af failure of first function
          // Await result and if successful, cancels the interval as we have a healthy connection websocket
          () => {
            generalInterval = setInterval(async () => {
              await generalListeners(
                dispatch,
                lastGeneralBlockUpdate,
                setLastGeneralUpdate,
              );
              // Clear the interval if function succeds
              clearInterval(generalInterval);
            }, 5000);
            // interval = undefined;
          },
        );
      } catch (error) {}
    };
    fetch();
    return () => {
      if (generalInterval) {
        clearInterval(generalInterval);
      }

      if (generalSocket) {
        generalSocket.destroy();
      }
    };
  }, [dispatch, lastGeneralBlockUpdate, setLastGeneralUpdate]);

  // Receives callback indicating to indicate what event was triggered
  useEffect(() => {
    let interval;
    let userSocket;
    const fetch = () => {
      try {
        const checkUnlockableStatus = async () => {
          const connector = getProvider();
          const status = await checkWalletConnection(connector);

          if (isLoggedIn && (!status.connected || !status.unlocked)) {
            handleLogout();
          }
        };

        checkUnlockableStatus();

        // We only proceed if user is logged in and if the wallet listener is on
        if (isLoggedIn) {
          // We verify wallet is still unlocked
          checkUnlockableStatus();

          // We change back the state of eventUpdate
          setEventUpdate('');
          userSocket = safeExecuteNotAsync(
            // Initial Attempt to execute balance listener methods immediately
            () =>
              userBalanceListeners(
                account,
                dispatch,
                trackedTokens,
                lastUserBlockUpdate,
                setLastUserUpdate,
              ),
            // Fall method if above initialization fails. Launched 5 seconds af failure of first function
            // Await result and if successful, cancels the interval as we have a healthy connection websocket
            () => {
              interval = setInterval(async () => {
                await userBalanceListeners(
                  account,
                  dispatch,
                  trackedTokens,
                  lastUserBlockUpdate,
                  setLastUserUpdate,
                );
                // Clear the interval if function succeds
                clearInterval(interval);
              }, 5000);
              // interval = undefined;
            },
          );
        }
      } catch (error) {}
    };
    fetch();
    return () => {
      if (interval) {
        clearInterval(interval);
      }

      if (userSocket) {
        userSocket.destroy();
      }
    };
  }, [
    account,
    isLoggedIn,
    dispatch,
    pending,
    trackedTokens,
    lastUserBlockUpdate,
    setLastUserUpdate,
  ]);

  return <>{children}</>;
};

export default EthersConnector;
