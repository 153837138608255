import { MulticallSingleResponse } from 'utils/web3';
import { formatFrom } from 'utils/web3';
import { tokenData, CovalentBalanceItem, balanceReturnData } from './types';
import uniqBy from 'lodash/uniqBy';

/**
 * Returns formatting for amount that has increased or decreased
 */
export const formatAmountChange = (_amount: number, _symbol = ' $') => {
  const formatted =
    _amount > 0
      ? `+${_symbol}${parseFloat(`${_amount}`).toFixed(2)}`
      : `-${_symbol}${parseFloat(`${Math.abs(_amount)}`).toFixed(2)}`;

  return formatted;
};

/**
 * Returns fiat representation of a number
 */
export const fiat = (_amount: number, _symbol = '$') => {
  const fixedAmount = Number(_amount.toFixed(2));
  return `${_symbol}${parseFloat(`${fixedAmount}`).toLocaleString('en-US')}`;
};

/**
 * Returns formatted date from timestamp
 */
export function timestampToDate(UNIX_timestamp) {
  const a = new Date(UNIX_timestamp * 1000);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const year = a.getFullYear();
  const month = months[a.getUTCMonth()];
  const date = a.getUTCDate();
  const strDate = `${date}`;
  const lenght = strDate.length - 1;
  const number = strDate[lenght];
  let sufix = 'th';
  if (number === '1' && strDate !== '11') sufix = 'st';
  if (number === '2' && strDate !== '12') sufix = 'nd';
  if (number === '3' && strDate !== '13') sufix = 'rd';
  const time = `${date}${sufix} ${month} ${year}`;
  return time;
}

export const formatReturnData = (item, itemType = 'covalent') => {
  if (itemType !== 'covalent') {
    const tdata: tokenData = {
      name: (item?.symbol && item.symbol.replace('-', '/')) || '',
      title: item?.symbol.replace(' LP', '').replace('-', ' + ') || '',
      tokens: item?.symbol.replace(' LP', '').toUpperCase().split('-') || [],
      full_name: `${item.symbol}`,
      amount: `${item.balance}`,
      symbol: `${item.symbol}`,
      address: `${item?.address}`,
      liquidity: true,
      staked: true,
      rate: 0,
      rate_24: 0,
      usd: 0,
      usd_24: 0,
    };
    return tdata;
  }

  const { contract_name, contract_ticker_symbol } = item;

  const tdata: tokenData = {
    name: contract_ticker_symbol
      ? contract_ticker_symbol.replace('-', '/')
      : '',
    full_name: item?.contract_name,
    title: item?.contract_name?.replace(' LP', '')?.replace('-', ' + ') || '',
    tokens: item?.contract_name?.replace(' LP', '')?.split('-') || [],
    amount: formatFrom(item?.balance || 0, item?.contract_decimals),
    symbol: item?.contract_ticker_symbol,
    usd: item?.quote?.toFixed(2) || 0,
    usd_24: item?.quote_24h?.toFixed(2) || 0,
    rate: item?.quote_rate,
    rate_24: item?.quote_rate_24h,
    address: item?.contract_address,
    liquidity: contract_name ? contract_name.includes('LP') : false,
    staked: true,
  };

  return tdata;
};

export const getTokenGroupStatistics = (
  _dataArray: MulticallSingleResponse[] | CovalentBalanceItem[],
  _dataType: 'tokenList' | 'farmList' | 'stakeList',
  _whitelist = {},
) => {
  // Represents our valid tokens list
  const validTokens: tokenData[] = [];

  // This section determines if all of the _dataArray is formated
  const whitelistExists = Object.keys(_whitelist).length > 0;

  let tokenCurrentTotal = 0;
  let token24Total = 0;

  for (let x = 0; x < _dataArray.length; x += 1) {
    const item: MulticallSingleResponse | CovalentBalanceItem = _dataArray[x];
    // Determines if data should be added to valid token array
    // False by default. Ruls below indicate if it turns to true
    let addToData = false;
    // Verifies if record has balance and value of tokens is greater than zero
    // necessary for covalent data
    const hasValidBalance = item.balance && item.quote && item.quote > 0;

    // Our token data
    const returnType = ['tokenList'].includes(_dataType)
      ? 'covalent'
      : 'native';
    const tdata = formatReturnData(item, returnType);

    // Handle of data coming directly from covalent
    // It defines rates if provided the variable that contains it
    if (
      ['tokenList'].includes(_dataType) &&
      hasValidBalance &&
      !tdata.liquidity
    ) {
      if (
        whitelistExists &&
        _whitelist[`${tdata.address}`.toLocaleLowerCase()]
      ) {
        addToData = true;
      } else if (!whitelistExists) {
        addToData = true;
        // We always want to add FTM
      } else if (tdata.symbol === 'FTM') {
        addToData = true;
      }

      if (addToData) {
        tokenCurrentTotal += item.quote || 0;
        token24Total += item.quote_24h || 0;
        validTokens.push(tdata);
      }
    }

    if (
      tdata.liquidity &&
      item.balance &&
      item.balance !== '0' &&
      item.balance !== '0.0'
    ) {
      tdata.staked = _dataType !== 'stakeList';

      addToData = true;

      if (addToData) {
        validTokens.push(tdata);
      }
    }
  }

  const tokenDifference = tokenCurrentTotal - token24Total;

  // For some reason, we need to stringgivy and parse the validTokensList to
  // make sure correct data is passed
  const copy = JSON.parse(JSON.stringify(validTokens));
  let results = [];

  if (_dataType === 'tokenList') {
    results = copy.filter(token => !token.liquidity);
  } else {
    results = copy;
  }

  const uniqFilteredArray = uniqBy(results, 'address');
  const summary: balanceReturnData = {
    [_dataType === 'stakeList' ? 'farmList' : _dataType]: uniqFilteredArray,
    totalValue: fiat(tokenCurrentTotal, ''),
    total24Value: fiat(token24Total, ''),
    totalValueNumber: tokenCurrentTotal,
    total24ValueNumber: token24Total,
    diffAmount: formatAmountChange(tokenDifference, ''),
    diffPercent: tokenDifference
      ? formatAmountChange(tokenDifference / token24Total, '')
      : '0',
    diffAmountValue: tokenDifference,
    diffPercentValue: tokenDifference ? tokenDifference / token24Total : 0,
  };

  return summary;
};
