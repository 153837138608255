import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const baseStyle = css`
  text-decoration: none;
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.h4};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  padding: ${({ theme }) =>
    `${theme.spacing.spacing02} ${theme.spacing.spacing03}`};
  line-height: ${({ theme }) => theme.lineHeight.baseLg};

  &.active {
    color: ${({ theme }) => theme.color.ci};
    background: ${({ theme }) => theme.color.ciTrans15};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
  }
  &:hover {
    background: ${({ theme }) => theme.color.grayBorderBox};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
  }
`;

export const CommonLink = styled.a`
  ${baseStyle}
`;

export const StyledNavLink = styled(NavLink)`
  ${baseStyle}
`;

export const TopWrapper = styled.div`
  width: max-content;
  padding: ${({ theme }) => theme.spacing.spacing02};
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.color.grayBorderToggle};
  box-shadow: 0 2px 8px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;
