import { Token } from 'app/interfaces/General';

export const WFTM = {
  name: 'Wrapped Fantom',
  symbol: 'WFTM',
  address: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
  chainId: 250,
  decimals: 18,
};
export const MIM = {
  name: 'Magic Internet Money',
  symbol: 'MIM',
  address: '0x82f0b8b456c1a451378467398982d4834b6829c1',
  chainId: 250,
  decimals: 18,
};

export const FRAX = {
  name: 'Frax',
  symbol: 'FRAX',
  address: '0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355',
  chainId: 250,
  decimals: 18,
};

export const USDC = {
  name: 'USD Coin',
  symbol: 'USDC',
  address: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
  chainId: 250,
  decimals: 6,
};

export const FUSD = {
  name: 'Fantom USD',
  symbol: 'fUSD',
  address: '0xad84341756bf337f5a0164515b1f6f993d194e1f',
  chainId: 250,
  decimals: 18,
};

export const FUSDT = {
  name: 'Frapped USDT',
  symbol: 'fUSDT',
  address: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
  chainId: 250,
  decimals: 6,
};

export const DAI = {
  name: 'Dai Stablecoin',
  symbol: 'DAI',
  address: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
  chainId: 250,
  decimals: 18,
};

export const FTM = {
  name: 'Fantom',
  symbol: 'FTM',
  address: '0x0000000000000000000000000000000000000000',
  chainId: 250,
  decimals: 18,
};

export const USDN = {
  name: 'Neutrino USD',
  symbol: 'USDN',
  address: '0x0000000000000000000000000000000000000000',
  chainId: 250,
  decimals: 18,
};

export const USDK = {
  name: 'USDK',
  symbol: 'USDK',
  address: '0x0000000000000000000000000000000000000000',
  chainId: 250,
  decimals: 18,
};

export const USDT = {
  name: 'USDT',
  symbol: 'USDT',
  address: '0x0000000000000000000000000000000000000000',
  chainId: 250,
  decimals: 18,
};

export const USDX = {
  name: 'USDX',
  symbol: 'USDX',
  address: '0x0000000000000000000000000000000000000000',
  chainId: 250,
  decimals: 18,
};

export const AVAX = {
  name: 'Avalanche',
  symbol: 'AVAX',
  address: '0x0000000000000000000000000000000000000000',
  chainId: 250,
  decimals: 18,
};

export const DEUS = {
  name: 'DEUS',
  symbol: 'DEUS',
  address: '0x0000000000000000000000000000000000000000',
  chainId: 250,
  decimals: 18,
};

export const UOS = {
  name: 'Ultra',
  symbol: 'UOS',
  address: '0x0000000000000000000000000000000000000000',
  chainId: 250,
  decimals: 18,
};

export const TOKEN_EMPTY = {
  name: '',
  symbol: '',
  address: '',
  chainId: 0,
  decimals: 0,
};

export const SPIRIT = {
  name: 'SpiritSwap Token',
  symbol: 'SPIRIT',
  address: '0x5Cc61A78F164885776AA610fb0FE1257df78E59B',
  chainId: 250,
  decimals: 18,
};

export const tokens: Token[] = [
  FTM,
  SPIRIT,
  WFTM,
  {
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    address: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    chainId: 250,
    decimals: 8,
  },
  {
    name: 'Wrapped ETH',
    symbol: 'WETH',
    address: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Cover Protocol Governance',
    symbol: 'COVER',
    address: '0xB01E8419d842beebf1b70A7b5f7142abbaf7159D',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Cream',
    symbol: 'CREAM',
    address: '0x657A1861c15A3deD9AF0B6799a195a249ebdCbc6',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Curve DAO',
    symbol: 'CRV',
    address: '0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Sushi',
    symbol: 'SUSHI',
    address: '0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Synthetix Network',
    symbol: 'SNX',
    address: '0x56ee926bD8c72B2d5fa1aF4d9E4Cbb515a1E3Adc',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'ChainLink',
    symbol: 'LINK',
    address: '0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'yearn.finance',
    symbol: 'YFI',
    address: '0x29b0Da86e484E1C0029B56e817912d778aC0EC69',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Fantom USD',
    symbol: 'fUSD',
    address: '0xad84341756bf337f5a0164515b1f6f993d194e1f',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'ZOO',
    symbol: 'ZOO',
    address: '0x09e145a1d53c0045f41aeef25d8ff982ae74dd56',
    chainId: 250,
    decimals: 0,
  },
  {
    name: 'IceToken',
    symbol: 'ICE',
    address: '0xf16e81dce15b08f326220742020379b855b87df9',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'BitBean',
    symbol: 'BITB',
    address: '0xbac5d43a56696e5d0cb631609e85798f564b513b',
    chainId: 250,
    decimals: 0,
  },
  {
    name: 'Frax Share',
    symbol: 'FXS',
    address: '0x7d016eec9c25232b01F23EF992D98ca97fc2AF5a',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'ANY Token',
    symbol: 'ANY',
    address: '0xdDcb3fFD12750B45d32E084887fdf1aABAb34239',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'CzTears',
    symbol: 'CZTEARS',
    address: '0x907f1A48918Bb5DE07c12443CAB0e6EEfCC611BC',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Binance',
    symbol: 'BNB',
    address: '0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Woofy',
    symbol: 'WOOFY',
    address: '0xD0660cD418a64a1d44E9214ad8e459324D8157f1',
    chainId: 250,
    decimals: 12,
  },
  {
    name: 'Supra Token',
    symbol: 'SUPRA',
    address: '0xee90fAF3216dFAE5E8aC1f3F48f10527f38fFf78',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'MMToken',
    symbol: 'MM',
    address: '0xbFaf328Fe059c53D936876141f38089df0D1503D',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Binance USD',
    symbol: 'BUSD',
    address: '0xC931f61B1534EB21D8c11B24f3f5Ab2471d4aB50',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Graviton',
    symbol: 'GTON',
    address: '0xC1Be9a4D5D45BeeACAE296a7BD5fADBfc14602C4',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Beefy.Finance',
    symbol: 'BIFI',
    address: '0xd6070ae98b8069de6B494332d1A1a81B6179D960',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Steak Token',
    symbol: 'STEAK',
    address: '0x05848B832E872d9eDd84AC5718D58f21fD9c9649',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'iFUSD',
    symbol: 'IFUSD',
    address: '0x9fC071cE771c7B27b7d9A57C32c0a84c18200F8a',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'ProjectMars Token',
    symbol: 'MARS',
    address: '0xbe41772587872a92184873d55b09c6bb6f59f895',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'Shiba',
    symbol: 'SHIBA',
    address: '0x9ba3e4f84a34df4e08c112e1a0ff148b81655615',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'Just Yours',
    symbol: 'JUST',
    address: '0x37c045be4641328dfeb625f1dde610d061613497',
    chainId: 250,
    decimals: 10,
  },
  {
    name: 'TosDis',
    symbol: 'DIS',
    address: '0x0e121961DD741C9D49C9A04379da944A9D2FAc7a',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Liquid Driver',
    symbol: 'LQDR',
    address: '0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Shade Cash',
    symbol: 'SHADE',
    address: '0x3a3841f5fa9f2c283ea567d5aeea3af022dd2262',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'GrimToken',
    symbol: 'GRIM',
    address: '0x7eC94C4327dC757601B4273cD67014d7760Be97E',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'ReaperToken',
    symbol: 'REAPER',
    address: '0x117dB78176C8eDe4F12fCd29d85Cd96b91A4cbBb',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Elk',
    symbol: 'ELK',
    address: '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'xSteak',
    symbol: 'XSTEAK',
    address: '0xb632c5d42bd4a44a617608ad1c7d38f597e22e3c',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'UniDex',
    symbol: 'UNIDX',
    address: '0x2130d2a1e51112d349ccf78d2a1ee65843ba36e0',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Spooky Token',
    symbol: 'BOO',
    address: '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Tomb',
    symbol: 'TOMB',
    address: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Yel Token',
    symbol: 'YEL',
    address: '0xd3b71117e6c1558c1553305b44988cd944e97300',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'AtariToken',
    symbol: 'ATRI',
    address: '0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b',
    chainId: 250,
    decimals: 0,
  },
  {
    name: 'Echo',
    symbol: 'ECHO',
    address: '0x54477a1d1bb8c1139eef754fd2efd4ddee7933dd',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'GorskisDELI',
    symbol: 'GDELI',
    address: '0xfa1807bd24a438b389ca33072375dfbba74dfaab',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'Scream',
    symbol: 'SCREAM',
    address: '0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'CATS Token',
    symbol: 'TCATS',
    address: '0x26D572B0aecB57abF53EF27770E4003f3daf7150',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Starlink',
    symbol: 'SLINK',
    address: '0x46c642d20a21879cf0e54662dc6bbc19ef15e86e',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Mensa',
    symbol: 'MSA',
    address: '0x540d9ef5cd2d5f03694f85aece84132c8f2220a4',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'RAI',
    symbol: 'RAI',
    address: '0xa71353bb71dda105d383b02fc2dd172c4d39ef8b',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'RNDM',
    symbol: 'RNDM',
    address: '0x87d57F92852D7357Cf32Ac4F6952204f2B0c1A27',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'STARTER',
    symbol: 'START',
    address: '0x8ca2ecbCE34c322fceA6Db912d9DbfD2DdA5920D',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'TOTEM',
    symbol: 'TOTEM',
    address: '0x31a37aedc0c18aa139e120e1cdc673bbb2063e6f',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'TAROT',
    symbol: 'TAROT',
    address: '0xC5e2B037D30a390e62180970B3aa4E91868764cD',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'SCORUM',
    symbol: 'BSCR',
    address: '0x133bf038277fda5eaa2b5fc4cc4e6e15bc5cace3',
    chainId: 250,
    decimals: 8,
  },
  {
    name: 'NIPS',
    symbol: 'NIPS',
    address: '0x3a6a2f68F2d556446348028Bc215d2F17Cb5cc49',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'Hashkings Buds',
    symbol: 'BUDS',
    address: '0x87fce3a8a93860f4c6f1e5f8ce3346caf968ae01',
    chainId: 250,
    decimals: 0,
  },
  {
    name: 'KittenFinance',
    symbol: 'KIF',
    address: '0x9D3E3624b3de482331F4f091Fd8b76D3F344bd8C',
    chainId: 250,
    decimals: 0,
  },
  {
    name: 'GinSpirit',
    symbol: 'GINSPIRIT',
    address: '0x2787bea3366335068bf8b4a253044d09ea4e1c96',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'PUMPKINS',
    symbol: 'KINS',
    address: '0x6eced8e16eda61e65292f019b165542a5906ecd6',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'HODL on Fantom',
    symbol: 'HODL',
    address: '0xb2da66c4a89d0f93935d1efdb9c9c8d1d3ba9343',
    chainId: 250,
    decimals: 6,
  },
  {
    name: 'Death',
    symbol: 'DEATH',
    address: '0xeaf45b62d9d0bdc1d763baf306af5edd7c0d7e55',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'Eleven.finance',
    symbol: 'ELE',
    address: '0xacd7b3d9c10e97d0efa418903c0c7669e702e4c0',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Spell',
    symbol: 'SPELL',
    address: '0x468003B688943977e6130F4F68F23aad939a1040',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'SunFinance',
    symbol: 'SUN',
    address: '0x60e91f89A2986975822De3BfE50df002Ef46EaAD',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'PAPR',
    symbol: 'PAPR',
    address: '0xC5e7A99A20941cBF56E0D4De608332cDB792e23e',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'PRNTR',
    symbol: 'PRNTR',
    address: '0xEFF11197247Ac09E0239ec9F83F7De13C186caA6',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Acre Token',
    symbol: 'ACRE',
    address: '0x12245af2d6d298d98019c15fb1001d8712d27a7d',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Animal',
    symbol: 'ANIMAL',
    address: '0x1f365ac62aabf64ef52a2c2fd86df9dd9e03665f',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Timechain Swap Token',
    symbol: 'TCS',
    address: '0xfbfae0dd49882e503982f8eb4b8b1e464eca0b91',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'BeethovenxToken',
    symbol: 'BEETS',
    address: '0xf24bcf4d1e507740041c9cfd2dddb29585adce1e',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'PaintSwap Token',
    symbol: 'BRUSH',
    address: '0x85dec8c4b2680793661bca91a8f129607571863d',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'HappinessToken',
    symbol: 'HPS',
    address: '0xac1f25aee575d35c668b0a4d336f20e3e92adcd2',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'FANG Token',
    symbol: 'FANG',
    address: '0x49894fcc07233957c35462cfc3418ef0cc26129f',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Wrapped MEMO',
    symbol: 'WMEMO',
    address: '0xddc0385169797937066bbd8ef409b5b3c0dfeb52',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'WalletNow',
    symbol: 'WNOW',
    address: '0xA9CAd0165C155f3998b0001b3eF30bCa0aa6B591',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Bison',
    symbol: 'BISON',
    address: '0xd2f38621c3C65300ECabA7020c05d1350f9C265c',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Synapse',
    symbol: 'SYN',
    address: '0xe55e19fb4f2d85af758950957714292dac1e25b2',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'AUSD',
    symbol: 'AUSD',
    address: '0x41e3dF7f716aB5AF28c1497B354D79342923196a',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Staked Spell Tokens',
    symbol: 'sSPELL',
    address: '0xbB29D2A58d880Af8AA5859e30470134dEAf84F2B',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'SoulPower',
    symbol: 'SOUL',
    address: '0xe2fb177009FF39F52C0134E8007FA0e4BaAcBd07',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'SeanceCircle',
    symbol: 'SEANCE',
    address: '0x124B06C5ce47De7A6e9EFDA71a946717130079E6',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Enchantment',
    symbol: 'ENCHANT',
    address: '0x6a1a8368D607c7a808F7BbA4F7aEd1D9EbDE147a',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'FATCAKE',
    symbol: 'FATCAKE',
    address: '0x4a3DCE89cA816D4F10864664B6503df95a92d879',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'YOSHI.exchange',
    symbol: 'YOSHI',
    address: '0x3dc57B391262e3aAe37a08D91241f9bA9d58b570',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'FROG',
    symbol: 'FROG',
    address: '0x11319cD0bA5e4ce6c302B275b613962B5cC00aDe',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'linSPIRIT',
    symbol: 'LINSPIRIT',
    address: '0xc5713b6a0f26bf0fdc1c52b90cd184d950be515c',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'METRIC',
    symbol: 'METRIC',
    address: '0x44293e446d4fe519f177ee221055cb9e5dc4ac5b',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'MAI',
    symbol: 'MAI',
    address: '0xfb98b335551a418cd0737375a2ea0ded62ea213b',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'BLOOM',
    symbol: 'BLOOM',
    address: '0x9B2e37cDC711CfcAC1E1482B5741c74dd3924199',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'HectorDAO',
    symbol: 'HEC',
    address: '0x5C4FDfc5233f935f20D2aDbA572F770c2E377Ab0',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'Midas',
    symbol: 'MIDAS',
    address: '0xb37528da6b4d378305d000a66ad91bd88e626761',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'PILLS Token',
    symbol: 'PILLS',
    address: '0xB66b5D38E183De42F21e92aBcAF3c712dd5d6286',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Dope Token DeGen Haus',
    symbol: 'DOPE',
    address: '0x9F3c6e1bD483cd977DF9B36734E1cD684f1Be621',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'Governance OHM',
    symbol: 'GOHM',
    address: '0x91fa20244Fb509e8289CA630E5db3E9166233FDc',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Wrapped sHEC',
    symbol: 'WSHEC',
    address: '0x94ccf60f700146bea8ef7832820800e2dfa92eda',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Jewels',
    symbol: 'JEWEL',
    address: '0xD97F9674E2597e7a252de4875985f4385B9608fB',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'SecureDAO',
    symbol: 'SCR',
    address: '0x8183C18887aC4386CE09Dbdf5dF7c398DAcB2B5a',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'Comb Finance',
    symbol: 'COMB',
    address: '0xaE45a827625116d6C0C40B5D7359EcF68F8e9AFD',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'ScaryChainCapital',
    symbol: 'SCC',
    address: '0xa231D452e4bCA86672FD6109de94688d1E17Aae5',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'GSCARAB',
    symbol: 'GSCARAB',
    address: '0x6ab5660f0B1f174CFA84e9977c15645e4848F5D6',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'SCARAB',
    symbol: 'SCARAB',
    address: '0x2e79205648B85485731CFE3025d66cF2d3B059c4',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Multichain',
    symbol: 'MULTI',
    address: '0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'SquidDAO',
    symbol: 'WSSQUID',
    address: '0xb280458B3cf0FAcC33671D52FB0E894447C2539A',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'AllBridge',
    symbol: 'ABR',
    address: '0x543Acd673960041eEe1305500893260F1887B679',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'OWL DAO',
    symbol: 'OWL',
    address: '0x9564fBfD3B3819D6Fb2DbC88C75E112400bE8F0C',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'PlasmaGunk',
    symbol: 'PGUNK',
    address: '0xf8fc059dafdce4ef2edfc72cbbaf410d7531e610',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'BlackGunk',
    symbol: 'BGUNK',
    address: '0xff042a6f2d50f00413f9f1ee8f55b2bb7e0cab2c',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'EntGunk',
    symbol: 'EGUNK',
    address: '0x19840d508904587e08f262f518da078bd4c560bf',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'SUPA Foundation',
    symbol: 'SUPA',
    address: '0x59d07a115fe3ffe5db3d52029d43cc0ef5e9ba08',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Tapioca',
    symbol: 'TAPIOCA',
    address: '0xf29DE415dB17Bfb803FA2Af75151F0c6f9c06b10',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'veDAO Token',
    symbol: 'WEVE',
    address: '0x911da02c1232a3c3e1418b834a311921143b04d7',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'Cyber Tokens',
    symbol: 'CYBER',
    address: '0xB2d65f66B69BF3ac78ad2396d6356F4F0e1036B7',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'GIZA',
    symbol: 'GIZA',
    address: '0x3389492f36642f27f7bf4a7749fb3fc2c8fbb7ee',
    chainId: 250,
    decimals: 9,
  },
  {
    name: 'DEI',
    symbol: 'DEI',
    address: '0xDE12c7959E1a72bbe8a5f7A1dc8f8EeF9Ab011B3',
    chainId: 250,
    decimals: 18,
  },
  {
    name: 'DEUS',
    symbol: 'DEUS',
    address: '0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44',
    chainId: 250,
    decimals: 18,
  },
  MIM,
  FRAX,
  USDC,
  FUSD,
  FUSDT,
  DAI,
];

export default tokens;
