/**
 * app/utils/constants.ts
 *
 * Describe common constant values
 *
 */
import { Currency } from './types';

export const CurrencySymbol: { [key in keyof typeof Currency]: string } = {
  [Currency.EUR]: '€',
  [Currency.USD]: '$',
};

export const LanguageItems = [
  { id: 'en', value: 'English', type: 'languages' },
  // { id: 'de', value: 'Deutsch', type: 'languages' },
  // { id: 'zh', value: '中文', type: 'languages' },
  // { id: 'fr', value: 'Français', type: 'languages' },
  // { id: 'ru', value: 'Русский', type: 'languages' },
  { id: 'es', value: 'Español', type: 'languages' },
  // { id: 'ar', value: 'العربية', type: 'languages' },
  // { id: 'pt', value: 'Português', type: 'languages' },
];

export const InfoGraphics = {
  swap: ['swap0', 'swap1', 'swap2', 'swap3'],
  farms: ['farms0', 'farms1', 'farms2', 'farms3', 'farm4'],
  bridge: ['bridge0', 'bridge1', 'bridge2'],
  inSpirit: [
    'inSpirit0',
    'inSpirit1',
    'inSpirit2',
    'inSpirit3',
    'inSpirit4',
    'inSpirit5',
  ],
  leverage: ['leverage0', 'leverage1', 'leverage2', 'leverage3'],
  lend: ['lend0', 'lend1', 'lend2', 'lend3', 'lend4', 'lend5'],
};
