import farmsList from './farms';
import farmsV2List from './farmsV2';
import farmsRouterV2List from './farmsRouterV2';

export const farms = farmsList;

export const farmsV2 = farmsV2List;

export const farmsRouterV2 = farmsRouterV2List;

const allFarms = farms.concat(farmsV2).concat(farmsRouterV2);

export default allFarms;
