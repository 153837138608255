import web3Listener from './_web3Listener';
import { getUserBalances, reconciliateBalances } from 'utils/data';
import { setBridgeWalletFrom, setBridgeWalletTo } from 'store/user';
import { safeExecuteNotAsync as safeExecute } from 'utils/safeExecute';

/**
 * Helper method - Updates portfolio related data
 *  - userWalletAddress: Address of user in the blockchain
 *  - dispatch: callback hook method that allows update of app state
 */
export const updateBridgeBalance = async (
  userWalletAddress,
  trackedTokens,
  chainId,
  index,
  dispatch,
) => {
  const updateMethod = !index ? setBridgeWalletFrom : setBridgeWalletTo;

  const covalentRawData = await getUserBalances(userWalletAddress, chainId);

  if (!covalentRawData) {
    return null;
  }

  // Verifies tracked tokens are part of the portfolio data from covalent
  // Also multicalls blockchain for balances
  const portfolio = await reconciliateBalances(
    userWalletAddress,
    covalentRawData,
    trackedTokens,
    chainId,
  );

  const { tokens } = portfolio;
  dispatch(updateMethod(tokens.tokenList));
};

/**
 * Main Method - Sets up connection to rpc server and callback functions meant to update state of app
 *  - userWalletAddress: Address of user in the blockchain
 *  - dispatch: callback hook method that allows update of app state
 */
export const bridgeListener = (
  userWalletAddress,
  trackedTokens,
  chainId,
  index,
  dispatch,
) => {
  const updateCallback = (eventName: string) => {
    safeExecute(
      // Immediate attempt
      () =>
        updateBridgeBalance(
          userWalletAddress,
          trackedTokens,
          chainId,
          index,
          dispatch,
        ),
      // onFail function
      () => {
        setTimeout(() => {
          updateBridgeBalance(
            userWalletAddress,
            trackedTokens,
            chainId,
            index,
            dispatch,
          );
        }, 5000);
      },
    );
  };

  return web3Listener({
    id: 'BridgeListener',
    address: userWalletAddress,
    update: updateCallback,
    chainId,
  });
};

export default bridgeListener;
