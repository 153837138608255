import styled from '@emotion/styled';

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.space.spacing03};
  min-width: 300px;
`;

export const LanguageLabel = styled.label`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.base};
  line-height: ${({ theme }) => theme.lineHeights.h5};
  color: ${({ theme }) => theme.colors.gray};
  margin: 14px 0;
`;
