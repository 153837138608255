import gql from 'graphql-tag';

export const GET_TOTAL_VALUE = gql`
query spiritswapFactories {
  spiritswapFactories(
   where: { id: "${process.env.REACT_APP_FACTORY_ADDRESS}" }) {
    id
    totalLiquidityUSD
  }}
  `;
