const gauges = [
  {
    pid: 1,
    isPsc: true,
    lpSymbol: 'SPIRIT-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x30748322B6E34545DBe0788C421886AEB5297789',
    },
    gaugeAddress: '0xEFe02cB895b6E061FA227de683C04F3Ce19f3A62',
  },
  {
    pid: 2,
    isPsc: true,
    lpSymbol: 'WBTC-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x279b2c897737a50405ED2091694F225D83F2D3bA',
    },
    gaugeAddress: '0xDccAFCE93E6e57f0464b4639d4aFD7B9Ad006F61',
  },
  {
    pid: 3,
    isPsc: true,
    lpSymbol: 'WETH-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x613BF4E46b4817015c01c6Bb31C7ae9edAadc26e',
    },
    gaugeAddress: '0xE86CeE843a5CE2F40575544B1fFc43CB1701D9ae',
  },
  {
    pid: 4,
    isPsc: true,
    lpSymbol: 'gOHM-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xae9BBa22E87866e48ccAcFf0689AFaa41eB94995',
    },
    gaugeAddress: '0xb3AfA9CB6c53d061bC2263cE15357A691D0D60d4',
  },
  {
    pid: 5,
    isPsc: true,
    lpSymbol: 'FRAX-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x7ed0cdDB9BB6c6dfEa6fB63E117c8305479B8D7D',
    },
    gaugeAddress: '0x805f756d7B2592637725a1b797088c29c9D6A1F8',
  },
  {
    pid: 6,
    isPsc: true,
    lpSymbol: 'wsHEC-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xE1fd274Ef08D50C3ecdaEe90c322b6c2342AE5DE',
    },
    gaugeAddress: '0xaAdd9A7155Dbd447c62C1EB574E2FE3967af2E81',
  },
  {
    pid: 7,
    isPsc: true,
    lpSymbol: 'MIM-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xB32b31DfAfbD53E310390F641C7119b5B9Ea0488',
    },
    gaugeAddress: '0x0B905475bEa057060D066f3D1F85E6902Ae62557',
  },
  {
    pid: 8,
    isPsc: true,
    lpSymbol: 'MAI-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x51Eb93ECfEFFbB2f6fE6106c4491B5a0B944E8bd',
    },
    gaugeAddress: '0x27Dc7cc7175F8Ac26dc7421a3a92DAcdc1a9EF0D',
  },
  {
    pid: 9,
    isPsc: true,
    lpSymbol: 'JEWEL-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x782b3e90d85b72fDD3A15dE534fD0DC9D5Ae46E7',
    },
    gaugeAddress: '0xF399D101fB4D3466f70e2eC25467721eaEC8b460',
  },
  {
    pid: 10,
    isPsc: true,
    lpSymbol: 'ICE-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x936D23C83c2469f6a14B9f5bEaec13879598A5aC',
    },
    gaugeAddress: '0xA6A6f26426FB5FE15b33fAe65d1335B02dC54372',
  },
  {
    pid: 11,
    isPsc: true,
    lpSymbol: 'LQDR-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x4Fe6f19031239F105F753D1DF8A0d24857D0cAA2',
    },
    gaugeAddress: '0x717BDE1AA46a0Fcd937af339f95361331412C74C',
  },
  {
    pid: 12,
    isPsc: true,
    lpSymbol: 'USDC-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xe7E90f5a767406efF87Fdad7EB07ef407922EC1D',
    },
    gaugeAddress: '0xa3C6D55397Dcddaf9f600B082F7a6A918f2F4A5C',
  },
  {
    pid: 13,
    isPsc: true,
    lpSymbol: 'fUSDT-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xd14Dd3c56D9bc306322d4cEa0E1C49e9dDf045D4',
    },
    gaugeAddress: '0xED912897138f8aF455B8F95F75850B11979806D8',
  },

  {
    pid: 14,
    isPsc: true,
    lpSymbol: 'DAI-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xdbc490b47508D31c9EC44aFB6e132AD01C61A02c',
    },
    gaugeAddress: '0x1B6cA59BF8A911eE56e58Eb5E5A97F69356EC6C3',
  },

  {
    pid: 15,
    isPsc: true,
    lpSymbol: 'SPELL-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x19d4092635740699B6E4735701742740e235165A',
    },
    gaugeAddress: '0x02ADc9b582E39dc4Cb727a64d8584830CF1bb9bC',
  },

  {
    pid: 16,
    isPsc: true,
    lpSymbol: 'YFI-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x4fc38a2735C7da1d71ccAbf6DeC235a7DA4Ec52C',
    },
    gaugeAddress: '0x237E7E20bf10a61C8DeD780398AA0D5e69DdfF9c',
  },

  {
    pid: 17,
    isPsc: true,
    lpSymbol: 'SUSHI-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x9Fe4c0CE5F533e96C2b72d852f190961AD5a7bB3',
    },
    gaugeAddress: '0x3FD04eEb74204F8FAa5ea539cd5275EC1a3Aa70C',
  },
  {
    pid: 18,
    isPsc: true,
    lpSymbol: 'LINK-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xd061c6586670792331E14a80f3b3Bb267189C681',
    },
    gaugeAddress: '0x1360E082C01C897339B82eF098ab4e8B271252C8',
  },
  {
    pid: 19,
    isPsc: true,
    lpSymbol: 'CRV-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x374C8ACb146407Ef0AE8F82BaAFcF8f4EC1708CF',
    },
    gaugeAddress: '0x73eCAaD4Fff43619f31D47D66d841dE41A933488',
  },
  {
    pid: 20,
    isPsc: true,
    lpSymbol: 'MULTI-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x15aFDbDb27767d58A58459ae159814b6bBe6f506',
    },
    gaugeAddress: '0xfF1E257F9b482567dE88fcE9788502CbD4cC95F2',
  },
  {
    pid: 21,
    isPsc: true,
    lpSymbol: 'wsSQUID-gOHM LP',
    lpAddresses: {
      4002: '',
      250: '0x292e3CF358C40c38156F874ac4Fc726F72543E92',
    },
    gaugeAddress: '0x0ccb407510C529EfF71F02348E57E26a406Ac0E1',
  },

  {
    pid: 22,
    isPsc: true,
    lpSymbol: 'JUST-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x0133660D0578Bf9D085033Ea753a27F5Aa2b9de1',
    },
    gaugeAddress: '0x8A500EB01085776918F90438555d45E35fE863C9',
  },
  {
    pid: 23,
    isPsc: true,
    lpSymbol: 'FANG-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x871DD566AB3De61E5Cc8fb16fEE82595b17e9cc6',
    },
    gaugeAddress: '0x3020F2A9d7003923377dE267ac0d6A7F8748e541',
  },
  {
    pid: 24,
    isPsc: true,
    lpSymbol: 'PILLS-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x9C775D3D66167685B2A3F4567B548567D2875350',
    },
    gaugeAddress: '0x3A514Ce911E86164064F30Bf9134085Ae0E514aC',
  },
  {
    pid: 25,
    isPsc: true,
    lpSymbol: 'ZOO-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xDF18DD2631f02D930071DF7d6FF89bbc3718C62F',
    },
    gaugeAddress: '0xd8b503F5Bb44166194B6fB3438918F50341aD63E',
  },
  {
    pid: 26,
    isPsc: true,
    lpSymbol: 'GRIM-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x2c18c39622b90318B0124eCFd6d4aC81efcC51db',
    },
    gaugeAddress: '0x6f45A990D727bdBb447078422CfDD8B53c765741',
  },
  {
    pid: 27,
    isPsc: true,
    lpSymbol: 'TAROT-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xF050133847bb537C7476D054B8bE6e30253Fbd05',
    },
    gaugeAddress: '0xF7d3dE134c9d09998f94a3de5E0D7F3317Dd97be',
  },
  {
    pid: 28,
    isPsc: true,
    lpSymbol: 'wMEMO-MIM LP',
    lpAddresses: {
      4002: '',
      250: '0xC9B98e4A4e306DFc24bc5b5F66e271e19Fd74c5A',
    },
    gaugeAddress: '0x86762289Ffb97F8DB441a4fAf5ecd335165e8E08',
  },
  {
    pid: 29,
    isPsc: true,
    lpSymbol: 'YOSHI-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x9D2489d0DA3436445a0a5ef8515Dc10B2D8b4eaA',
    },
    gaugeAddress: '0xc1AE6EdBf55214B3FA690Cc376838785cDb6D8FB',
  },
  {
    pid: 30,
    isPsc: true,
    lpSymbol: 'DEUS-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x2599Eba5fD1e49F294C76D034557948034d6C96E',
    },
    gaugeAddress: '0x7a91957097e85bb933828d4cC7db287F573D0B2f',
  },
  {
    pid: 31,
    isPsc: true,
    lpSymbol: 'DEI-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x8eFD36aA4Afa9F4E157bec759F1744A7FeBaEA0e',
    },
    gaugeAddress: '0x6cb0CA6635027623684Ebd3387A6F5188fE90ea2',
  },
];

export default gauges;
