import web3Listener from './_web3Listener';
import {
  getInspiritStatistics,
  getBoostedFarms,
  getLiquidityPoolsData,
} from 'utils/data';
import {
  setSpiritPrice,
  setSpiritSupply,
  setSpiritValue,
  setAverageSpiritUnlockTime,
  setLastSpiritDistribution,
  setLastSpiritDistributionValue,
  setNextSpiritDistribution,
  setTotalSpiritLocked,
  setTotalSpiritLockedValue,
  setAprPercentage,
  setStatisticsFrom,
  setInSpiritPerSpirit,
  setBoostedFarms,
} from 'store/general';
import { connect } from 'utils/web3/connection';

const updateSpiritStatistics = async dispatch => {
  const spiritStatistics = await getInspiritStatistics();
  dispatch(setSpiritPrice(spiritStatistics.spiritPrice));
  dispatch(setAverageSpiritUnlockTime(spiritStatistics.avgTime));
  dispatch(setLastSpiritDistribution(spiritStatistics.lastDistribution));
  dispatch(
    setLastSpiritDistributionValue(spiritStatistics.lastDistributionValue),
  );
  dispatch(setNextSpiritDistribution(spiritStatistics.nextDistribution));
  dispatch(setTotalSpiritLocked(spiritStatistics.totalLocked));
  dispatch(setTotalSpiritLockedValue(spiritStatistics.totalLockedValue));
  dispatch(setSpiritValue(spiritStatistics.totalSpiritValue));
  dispatch(setSpiritSupply(spiritStatistics.totalSupply));
  dispatch(setAprPercentage(spiritStatistics.aprLDbased));
  dispatch(setStatisticsFrom(spiritStatistics.beginningPeriod));
  dispatch(setInSpiritPerSpirit(spiritStatistics.inspiritPerSpirit));
};

const updateBoostedFarmData = async dispatch => {
  const farmData = await getLiquidityPoolsData();
  const dataMapping = {};

  farmData.forEach(fd => {
    dataMapping[`${fd.exchange}`.toLowerCase()] = fd;
  });

  const generalBoostedFarms = await getBoostedFarms();

  generalBoostedFarms.forEach(bfarm => {
    bfarm.statistics = dataMapping[`${bfarm.tokenAddress}`.toLowerCase()];
  });

  dispatch(setBoostedFarms(generalBoostedFarms));
};

/**
 * Helper method - Updates portfolio related data
 *  - userWalletAddress: Address of user in the blockchain
 *  - dispatch: callback hook method that allows update of app state
 */
export const updateAppData = async (dispatch, block?, setLastBlockUpdate?) => {
  // Methods are called this way so they run asyncronously
  updateSpiritStatistics(dispatch);
  updateBoostedFarmData(dispatch);

  if (block && setLastBlockUpdate) {
    setLastBlockUpdate(block);
  } else if (setLastBlockUpdate) {
    // We keep record of the block where we updated last
    const { provider } = await connect();
    const lastBlock = await provider.getBlockNumber();
    setLastBlockUpdate(lastBlock);
  }
};

/**
 * Main Method - Sets up connection to rpc server and callback functions meant to update state of app
 *  - userWalletAddress: Address of user in the blockchain
 *  - dispatch: callback hook method that allows update of app state
 */
export const generalListeners = (
  dispatch,
  lastUpdate?,
  setLastBlockUpdate?,
) => {
  const updateCallback = (eventName: string) => {
    /* TODO: Remove after debuggin is done
    log(
      'Running general data updates in response to: ',
      eventName || 'initialization',
    ); */
    updateAppData(dispatch, lastUpdate, setLastBlockUpdate);
  };
  web3Listener({
    id: 'GeneralStatistics',
    update: updateCallback,
    lastUpdate: lastUpdate !== undefined ? lastUpdate || 1 : undefined,
  });
};

export default generalListeners;
