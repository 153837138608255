import contracts from '../contracts';
import { FarmConfig, QuoteToken } from '../types';

const farms: FarmConfig[] = [
  {
    pid: 0,
    isOldPsc: true,
    isTokenOnly: true,
    isStakingPool: true,
    isLPToken: false,
    lpSymbol: 'SPIRIT',
    lpAddresses: {
      4002: '',
      250: '0x30748322B6E34545DBe0788C421886AEB5297789', // SPIRIT-FTM LP
    },
    tokenSymbol: 'SPIRIT',
    tokenAddresses: {
      4002: '',
      250: '0x5Cc61A78F164885776AA610fb0FE1257df78E59B',
    },
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 1,
    isPsc: true,
    lpSymbol: 'SPIRIT-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0x30748322B6E34545DBe0788C421886AEB5297789',
    },
    tokenSymbol: 'SPIRIT',
    tokenAddresses: {
      4002: '',
      250: '0x5Cc61A78F164885776AA610fb0FE1257df78E59B',
    },
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 2,
    isPsc: true,
    lpSymbol: 'WBTC-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0x279b2c897737a50405ED2091694F225D83F2D3bA',
    },
    tokenSymbol: 'WBTC',
    tokenAddresses: {
      4002: '',
      250: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    },
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 3,
    isPsc: true,
    lpSymbol: 'WETH-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0x613BF4E46b4817015c01c6Bb31C7ae9edAadc26e',
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      4002: '',
      250: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    },
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 4,
    isPsc: true,
    lpSymbol: 'USDC-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0xe7E90f5a767406efF87Fdad7EB07ef407922EC1D',
    },
    tokenSymbol: 'FTM',
    tokenAddresses: contracts.wftm,
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 5,
    isPsc: true,
    lpSymbol: 'FTM-fUSD LP',
    lpAddresses: {
      4002: '',
      250: '0xBaf1B2fD16f7294ca158B3F1065e5f27F9c72b61',
    },
    tokenSymbol: 'FTM',
    tokenAddresses: contracts.wftm,
    quoteTokenSymbol: QuoteToken.fUSD,
    quoteTokenAdresses: contracts.fusd,
  },
  {
    pid: 6,
    isPsc: true,
    lpSymbol: 'ZOO-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0xDF18DD2631f02D930071DF7d6FF89bbc3718C62F',
    },
    tokenSymbol: 'ZOO',
    tokenAddresses: {
      4002: '',
      250: '0x09e145a1d53c0045f41aeef25d8ff982ae74dd56',
    },
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 7,
    isPsc: true,
    lpSymbol: 'ICE-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0x936D23C83c2469f6a14B9f5bEaec13879598A5aC',
    },
    tokenSymbol: 'ICE',
    tokenAddresses: contracts.ice,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 8,
    isPsc: true,
    lpSymbol: 'SUSHI-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0x9Fe4c0CE5F533e96C2b72d852f190961AD5a7bB3',
    },
    tokenSymbol: 'SUSHI',
    tokenAddresses: contracts.sushi,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 9,
    isPsc: true,
    lpSymbol: 'CREAM-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x040dd0d0f5e2a01fEb0C5457AbB588B23Cf4c43a',
    },
    tokenSymbol: 'CREAM',
    tokenAddresses: contracts.cream,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 10,
    isPsc: true,
    lpSymbol: 'CRV-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0x374C8ACb146407Ef0AE8F82BaAFcF8f4EC1708CF',
    },
    tokenSymbol: 'CRV',
    tokenAddresses: contracts.crv,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 11,
    isPsc: true,
    lpSymbol: 'LINK-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0xd061c6586670792331E14a80f3b3Bb267189C681',
    },
    tokenSymbol: 'LINK',
    tokenAddresses: contracts.link,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 12,
    isPsc: true,
    lpSymbol: 'COVER-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x5427E7AB61145c41b1e58453121245dAEF3929C3',
    },
    tokenSymbol: 'COVER',
    tokenAddresses: contracts.cover,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 13,
    isPsc: true,
    lpSymbol: 'YFI-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0x4fc38a2735C7da1d71ccAbf6DeC235a7DA4Ec52C',
    },
    tokenSymbol: 'YFI',
    tokenAddresses: contracts.yfi,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 14,
    isPsc: true,
    lpSymbol: 'FRAX-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x0eC0E1629E776272FA3E55548D4A656BE0EEdcF4',
    },
    tokenSymbol: 'FRAX',
    tokenAddresses: contracts.frax,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 15,
    isPsc: true,
    lpSymbol: 'BITB-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xF78d8D124DF289e8dd2725584f008AE2C6d27A96',
    },
    tokenSymbol: 'BITB',
    tokenAddresses: contracts.bitb,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 16,
    isPsc: true,
    lpSymbol: 'FXS-FRAX LP',
    lpAddresses: {
      4002: '',
      250: '0x100FcF27C87D1cc7b8D6c28b69b84A359e4fd377',
    },
    tokenSymbol: 'FXS',
    tokenAddresses: contracts.fxs,
    quoteTokenSymbol: QuoteToken.FRAX,
    quoteTokenAdresses: contracts.frax,
  },
  {
    pid: 17,
    isPsc: true,
    lpSymbol: 'fUSDT-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0xd14Dd3c56D9bc306322d4cEa0E1C49e9dDf045D4',
    },
    tokenSymbol: 'fUSDT',
    tokenAddresses: contracts.fusdt,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 18,
    isPsc: true,
    lpSymbol: 'ANY-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x26D583028989378Cc1b7CBC023f4Ae049d5e5899',
    },
    tokenSymbol: 'ANY',
    tokenAddresses: contracts.any,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 19,
    isPsc: true,
    lpSymbol: 'BITB-SPIRIT LP',
    lpAddresses: {
      4002: '',
      250: '0xa7d46F64875a82461058806B784AfB8F33c7Ea7C',
    },
    tokenSymbol: 'BITB',
    tokenAddresses: contracts.bitb,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 20,
    isPsc: true,
    lpSymbol: 'CZTEARS-SPIRIT LP',
    lpAddresses: {
      4002: '',
      250: '0xC66c0F0318e2777A53F884C8874B6CA3c2ED8f84',
    },
    tokenSymbol: 'CZTEARS',
    tokenAddresses: contracts.cztears,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 21,
    isPsc: true,
    lpSymbol: 'BNB-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x74fE5Ddc4c27F91a1898ccd5Ac62dFeb2d3dF726',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: contracts.bnb,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 22,
    isPsc: true,
    lpSymbol: 'BNB-SPIRIT LP',
    lpAddresses: {
      4002: '',
      250: '0x9D103Bc85A33B007B58bE2fDee3e1A64651f676d',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: contracts.bnb,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 23,
    isPsc: true,
    lpSymbol: 'YFI-WOOFY LP',
    lpAddresses: {
      4002: '',
      250: '0x287ebF376c59a037B8D8E0e987461b2fd8550D8c',
    },
    tokenSymbol: 'WOOFY',
    tokenAddresses: contracts.woofy,
    quoteTokenSymbol: QuoteToken.YFI,
    quoteTokenAdresses: contracts.yfi,
  },
  {
    pid: 24,
    isPsc: true,
    lpSymbol: 'SUPRA-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xa636bf8D0741b71aC8E71Ee8d83e5312fEbe7083',
    },
    tokenSymbol: 'SUPRA',
    tokenAddresses: contracts.supra,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 25,
    isPsc: true,
    lpSymbol: 'DAI-SPIRIT LP',
    lpAddresses: {
      4002: '',
      250: '0xfFbfc0446cA725b21256461e214E9D472f9be390',
    },
    tokenSymbol: 'DAI',
    tokenAddresses: contracts.dai,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 26,
    isPsc: true,
    lpSymbol: 'MM-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x971D049B7BDE4ca9192A461708eBB54C8EBC0843',
    },
    tokenSymbol: 'MM',
    tokenAddresses: contracts.mm,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 27,
    isPsc: true,
    lpSymbol: 'BUSD-fUSDT LP',
    lpAddresses: {
      4002: '',
      250: '0x1Ac51904cFAaD15679B3500F0fC41D2971657f80',
    },
    tokenSymbol: 'fUSDT',
    tokenAddresses: contracts.fusdt,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 28,
    isPsc: true,
    lpSymbol: 'GTON-USDC LP',
    lpAddresses: {
      4002: '',
      250: '0x8a5555c4996B72E5725Cf108Ad773Ce5E715DED4',
    },
    tokenSymbol: 'GTON',
    tokenAddresses: {
      4002: '',
      250: '0xC1Be9a4D5D45BeeACAE296a7BD5fADBfc14602C4',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 29,
    isPsc: true,
    lpSymbol: 'BIFI-USDC LP',
    lpAddresses: {
      4002: '',
      250: '0x777e64771AB893053D72047E5CD85847054816b8',
    },
    tokenSymbol: 'BIFI',
    tokenAddresses: {
      4002: '',
      250: '0xd6070ae98b8069de6B494332d1A1a81B6179D960',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 30,
    isPsc: true,
    lpSymbol: 'MIM-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0xB32b31DfAfbD53E310390F641C7119b5B9Ea0488',
    },
    tokenSymbol: 'MIM',
    tokenAddresses: contracts.mim,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 32,
    isPsc: true,
    lpSymbol: 'DIS-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xa6020794594568e2bf987718b6520C14b9C4Ccd9',
    },
    tokenSymbol: 'DIS',
    tokenAddresses: contracts.dis,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 33,
    isPsc: true,
    lpSymbol: 'LQDR-SPIRIT LP',
    lpAddresses: {
      4002: '',
      250: '0xFeBbfeA7674720cEdD35e9384d07F235365c1B3e',
    },
    tokenSymbol: 'LQDR',
    tokenAddresses: contracts.lqdr,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 34,
    isPsc: true,
    lpSymbol: 'JUST-FTM LP (Old)',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0xea294E3C837ef457530b916Accbc3e3444a75A72',
    },
    tokenSymbol: 'JUST',
    tokenAddresses: contracts.just,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 35,
    isPsc: true,
    lpSymbol: 'GRIM-SPIRIT LP',
    lpAddresses: {
      4002: '',
      250: '0xebe5174D117aF9C71D76b3138B16e743Df983c76',
    },
    tokenSymbol: 'GRIM',
    tokenAddresses: contracts.grim,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 36,
    isPsc: true,
    lpSymbol: 'ELK-SPIRIT LP',
    lpAddresses: {
      4002: '',
      250: '0xbc7984937c34d8234046eb52649683efc243cb5e',
    },
    tokenSymbol: 'ELK',
    tokenAddresses: contracts.elk,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 37,
    isPsc: true,
    lpSymbol: 'XSTEAK-SPIRIT LP',
    lpAddresses: {
      4002: '',
      250: '0xe276a71991a7a7b16cb453db2994ce9fcda2eda6',
    },
    tokenSymbol: 'XSTEAK',
    tokenAddresses: contracts.xsteak,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 38,
    isPsc: true,
    lpSymbol: 'JUST-SPIRIT LP',
    lpAddresses: {
      4002: '',
      250: '0x626963180c88F43AE7DbCc85eC04d70117873a7C',
    },
    tokenSymbol: 'JUST',
    tokenAddresses: contracts.just,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 39,
    isPsc: true,
    lpSymbol: 'CASPER-SPIRIT LP',
    lpAddresses: {
      4002: '',
      250: '0x9f5182fefa4092ca11f9dbeb383f314b6d3381d0',
    },
    tokenSymbol: 'CASPER',
    tokenAddresses: contracts.casper,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 42,
    isPsc: true,
    lpSymbol: 'UNIDX-SPIRIT LP',
    lpAddresses: {
      4002: '',
      250: '0x2a520963f332c347c83a6e20f4ca33e2f24b6305',
    },
    tokenSymbol: 'UNIDX',
    tokenAddresses: contracts.unidx,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 43,
    isPsc: true,
    lpSymbol: 'JUST-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x0133660d0578bf9d085033ea753a27f5aa2b9de1',
    },
    tokenSymbol: 'JUST',
    tokenAddresses: contracts.just,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 44,
    isPsc: true,
    lpSymbol: 'RAI-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x414f9dc80bfe3966d4d30878fd4ce9cac3ce63c4',
    },
    tokenSymbol: 'RAI',
    tokenAddresses: contracts.rai,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 45,
    isPsc: true,
    lpSymbol: 'NIPS-SPIRIT LP',
    lpAddresses: {
      4002: '',
      250: '0x594627998b11f4Cd13999e43198C16204D3265a3',
    },
    tokenSymbol: 'NIPS',
    tokenAddresses: contracts.nips,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 46,
    isPsc: true,
    lpSymbol: 'START-SPIRIT LP',
    lpAddresses: {
      4002: '',
      250: '0x466EB66161B2dfC000a37017896c7a5d846b0900',
    },
    tokenSymbol: 'START',
    tokenAddresses: contracts.start,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 47,
    isPsc: true,
    isTokenOnly: false,
    isStakingPool: true,
    lpSymbol: 'ginSPIRIT-SPIRIT LP',
    isLPToken: true,
    lpAddresses: {
      4002: '',
      250: '0xc6ED96EdC14a199bde017A273A2CBd4a129bdC65',
    },
    tokenSymbol: 'ginSPIRIT',
    tokenAddresses: contracts.ginspirit,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 48,
    isPsc: true,
    lpSymbol: 'ATRI-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x2ccc88B9EcbB477f68158187f616cFEcB2276641',
    },
    tokenSymbol: 'ATRI',
    tokenAddresses: contracts.atri,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 49,
    isPsc: true,
    lpSymbol: 'DEATH-SPIRIT LP',
    lpAddresses: {
      4002: '',
      250: '0x3a9297f6242805be0ec85ed34f7da308c859fe31',
    },
    tokenSymbol: 'DEATH',
    tokenAddresses: contracts.death,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 50,
    isPsc: true,
    lpSymbol: 'RNDM-SPIRIT LP',
    lpAddresses: {
      4002: '',
      250: '0x3f4c8f35f8829be98353775cdbe688a7528cd6ff',
    },
    tokenSymbol: 'RNDM',
    tokenAddresses: contracts.rndm,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 51,
    isPsc: true,
    lpSymbol: 'ELE-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x2c66C9B7916ef3282c7F66c95A0Ff3333e1Cf814',
    },
    tokenSymbol: 'ELE',
    tokenAddresses: contracts.ele,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 52,
    isPsc: true,
    lpSymbol: 'SPELL-fUSDT LP',
    lpAddresses: {
      4002: '',
      250: '0x31c0385DDE956f95D43Dac80Bd74FEE149961f4c',
    },
    tokenSymbol: 'SPELL',
    tokenAddresses: contracts.spell,
    quoteTokenSymbol: QuoteToken.FUSDT,
    quoteTokenAdresses: contracts.fusdt,
  },
  {
    pid: 53,
    isPsc: true,
    lpSymbol: 'PAPR-USDC LP',
    lpAddresses: {
      4002: '',
      250: '0x64bB8a5A4b2f7bd354876059c845c77Cb4554818',
    },
    tokenSymbol: 'PAPR',
    tokenAddresses: contracts.papr,
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 54,
    isPsc: true,
    lpSymbol: 'GRIM-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0x2c18c39622b90318B0124eCFd6d4aC81efcC51db',
    },
    tokenSymbol: 'GRIM',
    tokenAddresses: contracts.grim,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 40,
    isPsc: true,
    lpSymbol: 'TAROT-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0xF050133847bb537C7476D054B8bE6e30253Fbd05',
    },
    tokenSymbol: 'TAROT',
    tokenAddresses: contracts.tarot,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 55,
    isPsc: true,
    lpSymbol: 'LQDR-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0x4Fe6f19031239F105F753D1DF8A0d24857D0cAA2',
    },
    tokenSymbol: 'LQDR',
    tokenAddresses: contracts.lqdr,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 56,
    isPsc: true,
    lpSymbol: 'TOMB-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xd473ff135305f2fc7bc82d6b6831c911ebd55ed6',
    },
    tokenSymbol: 'TOMB',
    tokenAddresses: contracts.tomb,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 57,
    isPsc: true,
    lpSymbol: 'wMEMO-MIM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0xc9b98e4a4e306dfc24bc5b5f66e271e19fd74c5a',
    },
    tokenSymbol: 'wMEMO',
    tokenAddresses: contracts.wmemo,
    quoteTokenSymbol: QuoteToken.MIM,
    quoteTokenAdresses: contracts.mim,
  },
  {
    pid: 58,
    isPsc: true,
    isTokenOnly: false,
    isStakingPool: true,
    lpSymbol: 'linSPIRIT-SPIRIT LP',
    isLPToken: true,
    lpAddresses: {
      4002: '',
      250: '0x54d5b6881b429a694712fa89875448ca8adf06f4',
    },
    tokenSymbol: 'linSPIRIT',
    tokenAddresses: contracts.linspirit,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 59,
    isPsc: true,
    lpSymbol: 'YOSHI-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0x9d2489d0da3436445a0a5ef8515dc10b2d8b4eaa',
    },
    tokenSymbol: 'YOSHI',
    tokenAddresses: contracts.yoshi,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 60,
    isPsc: true,
    lpSymbol: 'sSPELL-SPELL LP',
    lpAddresses: {
      4002: '',
      250: '0x4f41D03631Ea4dC14016CcF90690d6D22b24C12D',
    },
    tokenSymbol: 'sSPELL',
    tokenAddresses: contracts.sspell,
    quoteTokenSymbol: QuoteToken.SPELL,
    quoteTokenAdresses: contracts.spell,
  },
  {
    pid: 61,
    isPsc: true,
    lpSymbol: 'FANG-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0x871dd566ab3de61e5cc8fb16fee82595b17e9cc6',
    },
    tokenSymbol: 'FANG',
    tokenAddresses: contracts.fang,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 62,
    isPsc: true,
    lpSymbol: 'DAI-USDC LP',
    lpAddresses: {
      4002: '',
      250: '0x9606D683d03f012DDa296eF0ae9261207C4A5847',
    },
    tokenSymbol: 'DAI',
    tokenAddresses: contracts.dai,
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 64,
    isPsc: true,
    lpSymbol: 'PILLS-FTM LP',
    isBoosted: true,
    lpAddresses: {
      4002: '',
      250: '0x9C775D3D66167685B2A3F4567B548567D2875350',
    },
    tokenSymbol: 'PILLS',
    tokenAddresses: contracts.pills,
    quoteTokenSymbol: QuoteToken.FTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 65,
    isPsc: true,
    lpSymbol: 'fUSDT-USDC LP',
    lpAddresses: {
      4002: '',
      250: '0xe7F86CEf8FEf60ce5050899D1F8e465C00D04a79',
    },
    tokenSymbol: 'fUSDT',
    tokenAddresses: contracts.fusdt,
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 66,
    isPsc: true,
    lpSymbol: 'FRAX-USDC LP',
    lpAddresses: {
      4002: '',
      250: '0x1478AEC7896e40aE5fB858C77D389F0B3e6CbC5d',
    },
    tokenSymbol: 'FRAX',
    tokenAddresses: contracts.frax,
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 67,
    isPsc: true,
    lpSymbol: 'MIM-USDC LP',
    lpAddresses: {
      4002: '',
      250: '0xc19C7615237f770179ed93d89126478c60742664',
    },
    tokenSymbol: 'MIM',
    tokenAddresses: contracts.mim,
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 68,
    isPsc: true,
    isStakingPool: true,
    isTokenOnly: false,
    lpSymbol: 'binSPIRIT-SPIRIT LP',
    isLPToken: true,
    lpAddresses: {
      4002: '',
      250: '0xd4f6574fd9ba1e218673971f92ad0f73abe31dee',
    },
    tokenSymbol: 'binSPIRIT',
    tokenAddresses: contracts.binSpirit,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 69,
    isPsc: true,
    isStakingPool: true,
    isTokenOnly: false,
    lpSymbol: 'sinSPIRIT-SPIRIT LP',
    isLPToken: true,
    lpAddresses: {
      4002: '',
      250: '0x66f0a5Fd09dF38416FfeC46735B4cb752c43FD13',
    },
    tokenSymbol: 'sinSPIRIT',
    tokenAddresses: contracts.sinSpirit,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
  {
    pid: 70,
    isPsc: true,
    isStakingPool: true,
    isTokenOnly: false,
    lpSymbol: 'BEETS-SPIRIT LP',
    isLPToken: true,
    lpAddresses: {
      4002: '',
      250: '0x99354C70d5dE875DA5a783470EE37ba8BE9fD6aF',
    },
    tokenSymbol: 'BEETS',
    tokenAddresses: contracts.beets,
    quoteTokenSymbol: QuoteToken.SPIRIT,
    quoteTokenAdresses: contracts.spirit,
  },
];

export default farms;
