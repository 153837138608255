export const HOME = {
  key: 'home',
  path: '/home',
};

export const SWAP = {
  key: 'swap',
  path: '/swap',
};

export const BRIDGE = {
  key: 'bridge',
  path: '/bridge',
};

export const LIQUIDITY = {
  key: 'liquidity',
  path: '/liquidity',
};

export const FARMS = {
  key: 'farms',
  path: '/farms',
};

export const INSPIRIT = {
  key: 'inSpirit',
  path: '/inspirit',
};

export const LENDANDBORROW = {
  key: 'lendBorrow',
  path: '',
  url: 'https://app.ola.finance/networks/0x892701d128d63c9856A9Eb5d967982F78FD3F2AE/markets',
};

export const STORE = {
  key: 'store',
  path: '',
  url: 'https://spiritswap.store/',
};

export const ANALYTICS = {
  key: 'analytics',
  path: '',
  url: 'https://info.spiritswap.finance/',
};

export const IDO = {
  key: 'ido',
  path: '',
  url: 'https://starter.investments/#/ftm',
};

export const NFTS = {
  key: 'nfts',
  path: '',
  url: 'https://pet.zoocoin.cash/collections/85',
};

export const DOCS = {
  key: 'docs',
  path: '',
  url: 'https://docs.spiritswap.finance/spirit-swap/',
};

export const APEMODE = {
  key: 'apemode',
  path: '/apemode',
  url: '',
};

export const GOVERNANCE = {
  key: 'governance',
  path: '',
  url: 'https://commonwealth.im/spiritswap/',
};

export const DSYNTHS = {
  key: 'dsynths',
  path: '',
  url: 'https://app.dsynths.com/trade?theme=jade',
};
