import { useSpring, animated } from '@react-spring/web';
import styled from '@emotion/styled';
import { NotificationsProps } from '.';
import { VStack, Text, HStack, Spinner, Flex, Box } from '@chakra-ui/react';
import { ErrorIcon, SuccessIcon } from 'app/assets/icons';
import { CloseIconButton, LinkIcon } from 'app/assets/icons';

import { NOTIFICATIONS_STATE } from 'constants/index';
import { openInNewTab } from 'app/utils/redirectTab';
import { useTranslation } from 'react-i18next';

const Fader = styled.div<{ colorLoader: string }>`
  width: 100%;
  height: 2px;
  background-color: ${({ colorLoader }) => colorLoader};
`;

const AnimatedFader = animated(Fader);

const IconHeader = ({ type }) => {
  switch (type) {
    case NOTIFICATIONS_STATE.PENDING:
      return <Spinner size="sm" color="warning" speed="1s" />;
    case NOTIFICATIONS_STATE.ERROR:
      return <ErrorIcon />;
    default:
      return <SuccessIcon />;
  }
};
const Notifications = ({
  title,
  type,
  closeNotification,
  id,
  uniqueMessage,
  inputValue,
  outputValue,
  inputSymbol,
  outputSymbol,
  icon,
  link,
}: NotificationsProps) => {
  const { t } = useTranslation();
  const options = (bgHeader: string, colorLoader: string) => {
    return {
      bgHeader: bgHeader,
      colorLoader: colorLoader,
    };
  };

  const colorNotifications = () => {
    switch (type) {
      case NOTIFICATIONS_STATE.PENDING:
        return options('pendingBg', 'yellow');
      case NOTIFICATIONS_STATE.ERROR:
        return options('errorBg', 'red');
      default:
        return options('successBg', 'teal');
    }
  };
  const scheme = colorNotifications();

  const faderStyle = useSpring({
    from: { width: '100%' },
    to: { width: '0%' },
    config: { duration: 10000 },
  });

  return (
    <VStack mr="10px" borderRadius="md" w="365px" spacing="0">
      <Flex
        borderTopRadius="md"
        py="spacing02"
        px="spacing03"
        bg={scheme.bgHeader}
        w="full"
        alignItems="center"
        justifyContent="space-between"
      >
        <HStack>
          <IconHeader type={type} />
          <Text color="white"> {title} </Text>
        </HStack>
        <CloseIconButton onClick={() => closeNotification(id)} />
      </Flex>
      <Box w="full" bg={scheme.bgHeader}>
        {type !== NOTIFICATIONS_STATE.PENDING && (
          <AnimatedFader style={faderStyle} colorLoader={scheme.colorLoader} />
        )}
      </Box>
      <HStack
        borderBottomRadius="md"
        py="spacing02"
        px="spacing03"
        pr="spacing05"
        bg="bgBoxDarker"
        w="full"
        justifyContent="space-between"
        alignItems="center"
      >
        <HStack>
          {inputSymbol ? (
            <>
              <Text color="grayDarker"> {inputValue} </Text>
              <Text color="white" fontWeight="bold">
                {inputSymbol}
              </Text>
              <>{icon && icon}</>
              {outputSymbol ? (
                <>
                  <Text color="grayDarker"> {outputValue} </Text>
                  <Text color="white" fontWeight="bold">
                    {outputSymbol}
                  </Text>
                </>
              ) : null}
            </>
          ) : null}
          {uniqueMessage?.text ? (
            <>
              <Text color="grayDarker"> {uniqueMessage.text} </Text>
              <Text color="white" fontWeight="bold">
                {uniqueMessage.secondText}
              </Text>
            </>
          ) : null}
        </HStack>
        {link ? (
          <LinkIcon
            _hover={{
              cursor: 'pointer',
            }}
            color="white"
            onClick={() => openInNewTab(link)}
          />
        ) : null}
      </HStack>
    </VStack>
  );
};

export default Notifications;
