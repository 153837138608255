import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectTotalSpiritValue } from 'store/general/selectors';
import client from 'utils/apollo/client';
import { GET_TOTAL_VALUE } from 'utils/apollo/queries/getTotalValue';
import { Contract } from 'utils/web3/contracts';
import contracts from 'constants/contracts';
import { CHAIN_ID } from 'constants/index';
import { formatEther } from 'ethers/lib/utils';
import { useRefresh } from './useRefresh';

const useMarketCapPrice = () => {
  const [TVLGRAPH, setTVLGRAPH] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const spiritLockedValue = useAppSelector(selectTotalSpiritValue);
  const { oneMinRefresh } = useRefresh();
  useEffect(() => {
    const init = async () => {
      try {
        const address = '0x892701d128d63c9856A9Eb5d967982F78FD3F2AE';
        setLoading(true);

        const { data } = await client.query({
          query: GET_TOTAL_VALUE,
          fetchPolicy: 'cache-first',
        });

        const olaLensContract = await Contract(
          contracts.olaLens[CHAIN_ID],
          'olaLens',
        );
        const olaResponse = await olaLensContract.callStatic.viewLendingNetwork(
          address,
        );

        const totalLiquidity = Number(
          data.spiritswapFactories[0].totalLiquidityUSD,
        );
        const TVL =
          totalLiquidity +
          spiritLockedValue +
          Number(formatEther(olaResponse.totalSupply));

        setTVLGRAPH(TVL);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    init();
  }, [spiritLockedValue, oneMinRefresh]);

  return {
    TVL: TVLGRAPH,
    loadingTVL: loading,
  };
};

export default useMarketCapPrice;
