import { ListToken } from 'app/interfaces/Bridge';

export const tokens: ListToken = {
  // Tokens from Fantom
  250: [
    {
      name: 'USD Coin',
      symbol: 'USDC',
      address: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
      chainId: 250,
      decimals: 6,
    },
    {
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      address: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
      chainId: 250,
      decimals: 18,
    },
    {
      name: 'Frapped USDT',
      symbol: 'fUSDT',
      address: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
      chainId: 250,
      decimals: 6,
    },
    {
      name: 'Ethereum',
      symbol: 'ETH',
      address: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
      chainId: 250,
      decimals: 18,
    },
  ],
  1: [
    {
      name: 'USD Coin',
      symbol: 'USDC',
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      chainId: 1,
      decimals: 6,
    },
    {
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      address: '0x6b175474e89094c44da98b954eedeac495271d0f',
      chainId: 1,
      decimals: 18,
    },
    {
      name: 'Tether USD',
      symbol: 'USDT',
      address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      chainId: 1,
      decimals: 6,
    },
    {
      name: 'Ethereum',
      symbol: 'ETH',
      address: '0x0000000000000000000000000000000000000000',
      chainId: 250,
      decimals: 18,
    },
  ],
  56: [
    {
      name: 'USD Coin',
      symbol: 'USDC',
      address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      chainId: 56,
      decimals: 18,
    },
    {
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
      chainId: 56,
      decimals: 18,
    },
    {
      name: 'Binance-Peg BSC-USD',
      symbol: 'USDT',
      address: '0x55d398326f99059fF775485246999027B3197955',
      chainId: 56,
      decimals: 18,
    },
  ],
  137: [
    {
      name: 'USD Coin',
      symbol: 'USDC',
      address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      chainId: 137,
      decimals: 6,
    },
    {
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      address: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
      chainId: 137,
      decimals: 18,
    },
    {
      name: '(PoS) Tether USD',
      symbol: 'USDT',
      address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      chainId: 137,
      decimals: 6,
    },
  ],
  // Arbitrum
  42161: [
    {
      name: 'Ethereum',
      symbol: 'ETH',
      address: '0x0000000000000000000000000000000000000000',
      chainId: 42161,
      decimals: 18,
    },
    {
      name: 'USD Coin',
      symbol: 'USDC',
      address: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
      chainId: 42161,
      decimals: 6,
    },
    {
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      address: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
      chainId: 42161,
      decimals: 18,
    },
    {
      name: 'Tether USD',
      symbol: 'USDT',
      address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      chainId: 42161,
      decimals: 6,
    },
    {
      name: 'Wrapped ETH',
      symbol: 'WETH',
      address: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
      chainId: 42161,
      decimals: 18,
    },
  ],
  // Avalanche
  43114: [
    {
      name: 'USD Coin',
      symbol: 'USDC.e',
      address: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
      chainId: 43114,
      decimals: 6,
    },
    {
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      address: '0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
      chainId: 43114,
      decimals: 18,
    },
    {
      name: 'TetherToken',
      symbol: 'USDt',
      address: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
      chainId: 43114,
      decimals: 6,
    },
    {
      name: 'Wrapped ETH',
      symbol: 'WETH',
      address: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
      chainId: 43114,
      decimals: 18,
    },
  ],
  // Optimistic
  10: [
    {
      name: 'USD Coin',
      symbol: 'USDC',
      address: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
      chainId: 10,
      decimals: 6,
    },
    {
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      address: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
      chainId: 10,
      decimals: 18,
    },
    {
      name: 'Tether USD',
      symbol: 'USDT',
      address: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
      chainId: 10,
      decimals: 6,
    },
    {
      name: 'ETH',
      symbol: 'ETH',
      address: '0x0000000000000000000000000000000000000000',
      chainId: 10,
      decimals: 18,
    },
  ],
  100: [
    {
      name: 'USD Coin',
      symbol: 'USDC',
      address: '0xddafbb505ad214d7b80b1f830fccc89b60fb7a83',
      chainId: 100,
      decimals: 6,
    },
    {
      name: 'Dai',
      symbol: 'xDAI',
      address: '0x0000000000000000000000000000000000000000',
      chainId: 100,
      decimals: 18,
    },
    {
      name: 'Frapped USDT',
      symbol: 'fUSDT',
      address: '0x4ecaba5870353805a9f068101a40e0f32ed605c6',
      chainId: 100,
      decimals: 6,
    },
    {
      name: 'ETH',
      symbol: 'ETH',
      address: '0x6a023ccd1ff6f2045c3309768ead9e68f978f6e1',
      chainId: 100,
      decimals: 18,
    },
  ],
  //Moonriver
  1285: [
    {
      name: 'USD Coin',
      symbol: 'USDC',
      address: '0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d',
      chainId: 1285,
      decimals: 6,
    },
    {
      name: 'Tether USD',
      symbol: 'USDT',
      address: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
      chainId: 1285,
      decimals: 6,
    },
  ],
  // Moonbeam
  1284: [
    {
      name: 'USD Coin',
      symbol: 'USDC',
      address: '0x8f552a71EFE5eeFc207Bf75485b356A0b3f01eC9',
      chainId: 1284,
      decimals: 6,
    },
    {
      name: 'Tether USD',
      symbol: 'USDT',
      address: '0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
      chainId: 1284,
      decimals: 6,
    },
  ],
};
