import { Helmet } from 'react-helmet-async';

import { GlobalStyle } from '../styles/global-styles';
import { useTranslation } from 'react-i18next';
import { SiteRouting } from 'app/router';

import Layers from './assets/background';

export function App() {
  const { i18n } = useTranslation();
  return (
    <>
      <Helmet
        titleTemplate="SpiritSwap"
        defaultTitle="SpiritSwap"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="SpiritSwap webapp" />
      </Helmet>

      <Layers />

      <SiteRouting />
      <GlobalStyle />
    </>
  );
}
