import { createSlice } from '@reduxjs/toolkit';
import { Token } from 'app/interfaces/General';
import { tokenData, BoostedFarmVoteData, FarmRewardInfo } from 'utils/data';
import { GelattoLimitOrder } from 'utils/swap/types';
import { Web3TxData } from 'utils/web3/types';

const initialState: {
  address: string;
  spirit_balance: number;
  tokens: Array<tokenData>;
  wallet: Array<tokenData>;
  liquidity_wallet: Array<tokenData>;
  sob_liquidity_wallet: Array<tokenData>;
  liquidity: Array<tokenData>;
  staked: Array<tokenData>;
  tokens_value: number;
  liquidity_value: number;
  staked_value: number;
  portfolio_value: number;
  portfolio_percent_value: number;
  inspirit_locked_amount: number;
  inspirit_locked_end_date: number;
  inspirit_balance: number;
  spirit_claimable_amount: string;
  bribes_claimable_amount: string;
  boosted_farm_votes: Array<BoostedFarmVoteData>;
  inspirit_allowance: number;
  pending_transactions: Web3TxData[];
  farm_rewards: FarmRewardInfo[];
  limit_orders: GelattoLimitOrder[];
  tracked_tokens: Token[];
  bridge_chains: string[];
  bridge_wallet_from: Array<tokenData>;
  bridge_wallet_to: Array<tokenData>;
} = {
  address: '',
  spirit_balance: 0,
  tokens: [],
  liquidity: [],
  liquidity_wallet: [],
  sob_liquidity_wallet: [],
  staked: [],
  tokens_value: 0,
  wallet: [],
  liquidity_value: 0,
  staked_value: 0,
  portfolio_value: 0,
  portfolio_percent_value: 0,
  inspirit_locked_amount: 0,
  inspirit_locked_end_date: 0,
  inspirit_balance: 0,
  spirit_claimable_amount: '0',
  bribes_claimable_amount: '0',
  boosted_farm_votes: [],
  inspirit_allowance: 0,
  pending_transactions: [],
  limit_orders: [],
  farm_rewards: [],
  tracked_tokens: [],
  bridge_chains: [],
  bridge_wallet_from: [],
  bridge_wallet_to: [],
};

export const userReducer = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setBridgeChains: (state, action) => {
      state.bridge_chains = action.payload;
    },
    setTokens: (state, action) => {
      state.tokens = action.payload;
    },
    setLiquidity: (state, action) => {
      state.liquidity = action.payload;
    },
    setStaked: (state, action) => {
      state.staked = action.payload;
    },
    setTokensValue: (state, action) => {
      state.tokens_value = action.payload;
    },
    setLiquidityValue: (state, action) => {
      state.liquidity_value = action.payload;
    },
    setStakedValue: (state, action) => {
      state.staked_value = action.payload;
    },
    setPortfolioValue: (state, action) => {
      state.portfolio_value = action.payload;
    },
    setPortfolioPercentValue: (state, action) => {
      state.portfolio_percent_value = action.payload;
    },
    setLockedInSpiritAmount: (state, action) => {
      state.inspirit_locked_amount = action.payload;
    },
    setLockedInSpiritEndDate: (state, action) => {
      state.inspirit_locked_end_date = action.payload;
    },
    setInspiritUserBalance: (state, action) => {
      state.inspirit_balance = action.payload;
    },
    setSpiritClaimableAmount: (state, action) => {
      state.spirit_claimable_amount = action.payload;
    },
    setBribesClaimableAmount: (state, action) => {
      state.bribes_claimable_amount = action.payload;
    },
    setBoostedFarmVotes: (state, action) => {
      state.boosted_farm_votes = action.payload;
    },
    setSpiritBalance: (state, action) => {
      state.spirit_balance = action.payload;
    },
    setUserWallet: (state, action) => {
      state.wallet = action.payload;
    },
    setBridgeWalletFrom: (state, action) => {
      state.bridge_wallet_from = action.payload;
    },
    setBridgeWalletTo: (state, action) => {
      state.bridge_wallet_to = action.payload;
    },
    setUserLiquidityWallet: (state, action) => {
      state.liquidity_wallet = action.payload;
    },
    setUserSobLiquidityWallet: (state, action) => {
      state.sob_liquidity_wallet = action.payload;
    },
    setInspiritAllowance: (state, action) => {
      state.inspirit_allowance = action.payload;
    },
    setPendingTransactions: (state, action) => {
      state.pending_transactions = action.payload;
    },
    setNewPendingTransactions: (state, action) => {
      if (state.pending_transactions) {
        state.pending_transactions.push(action.payload);
      } else {
        state.pending_transactions = [action.payload];
      }
    },
    removePendingTransactions: (state, action) => {
      const hashes = action.payload;

      if (state.pending_transactions) {
        const newState = state.pending_transactions.filter(
          tx => !hashes.includes(`${tx.hash}`),
        );

        state.pending_transactions = newState;
      }
    },
    setLimitOrders: (state, action) => {
      state.limit_orders = action.payload;
    },
    setFarmRewards: (state, action) => {
      state.farm_rewards = action.payload;
    },
    setTrackedTokens: (state, action) => {
      state.tracked_tokens = action.payload;
    },
    setNewTrackedToken: (state, action) => {
      if (state.tracked_tokens) {
        state.tracked_tokens.push(action.payload);
      } else {
        state.tracked_tokens = [action.payload];
      }
    },
    resetUserStatistics: state => {
      state.tokens = [];
      state.spirit_balance = 0;
      state.tokens = [];
      state.liquidity = [];
      state.staked = [];
      state.tokens_value = 0;
      state.wallet = [];
      state.liquidity_value = 0;
      state.staked_value = 0;
      state.portfolio_value = 0;
      state.portfolio_percent_value = 0;
      state.inspirit_locked_amount = 0;
      state.inspirit_locked_end_date = 0;
      state.inspirit_balance = 0;
      state.spirit_claimable_amount = '0';
      state.bribes_claimable_amount = '0';
      state.boosted_farm_votes = [];
      state.address = '';
      state.inspirit_allowance = 0;
      state.pending_transactions = [];
      state.liquidity_wallet = [];
      state.limit_orders = [];
      state.tracked_tokens = [];
      state.bridge_chains = [];
      state.bridge_wallet_from = [];
      state.bridge_wallet_to = [];
    },
  },
});

// Actions
export const {
  setAddress,
  setTokens,
  setLiquidity,
  setStaked,
  setTokensValue,
  setLiquidityValue,
  setStakedValue,
  setPortfolioValue,
  setPortfolioPercentValue,
  setLockedInSpiritAmount,
  setLockedInSpiritEndDate,
  setInspiritUserBalance,
  setSpiritClaimableAmount,
  setBribesClaimableAmount,
  setBoostedFarmVotes,
  setSpiritBalance,
  setUserWallet,
  setBridgeChains,
  setBridgeWalletFrom,
  setBridgeWalletTo,
  resetUserStatistics,
  setInspiritAllowance,
  setPendingTransactions,
  setNewPendingTransactions,
  removePendingTransactions,
  setUserLiquidityWallet,
  setUserSobLiquidityWallet,
  setLimitOrders,
  setFarmRewards,
  setTrackedTokens,
  setNewTrackedToken,
} = userReducer.actions;

export default userReducer.reducer;
