import {
  Box,
  HStack,
  Text,
  Grid,
  VStack,
  SimpleGrid,
  useMediaQuery,
  Flex,
  Link,
} from '@chakra-ui/react';
import {
  DiscordSvg,
  GitHubSvg,
  MediumSvg,
  HourGlassPng,
  TwitterSvg,
  YouTubeSvg,
} from 'app/layouts/Footer';
import { AuditedByDiv, HourGlassImg } from 'app/layouts/Footer/styles';

import { useTranslation } from 'react-i18next';

export default function PageFooter() {
  const { t } = useTranslation();
  const translationPath = 'common.footer';
  const isMobile = useMediaQuery(`(max-width: 820px)`)[0];

  return (
    <>
      <Box p="30px" background="rgba(100, 221, 192, 0.05)">
        <Box maxW="1280px" margin="0 auto">
          <Grid
            templateColumns={'repeat(1, 1fr)'}
            gridTemplateColumns={!isMobile ? '1.4fr 1.4fr' : '1fr'}
            gap="10px"
          >
            <VStack align="stretch">
              <HStack gap="15px" alignSelf={isMobile ? 'center' : 'left'}>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/Spirit_Swap"
                  _hover={{ color: 'ci' }}
                >
                  <TwitterSvg />
                </Link>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://discord.gg/8FGd4nFQdT"
                  _hover={{ color: 'ci' }}
                >
                  <DiscordSvg />
                </Link>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/Layer3Org/spiritswap-core/"
                  _hover={{ color: 'ci' }}
                >
                  <GitHubSvg />
                </Link>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/channel/UCrKLtNctO0obN4-bDMGlFuQ"
                  _hover={{ color: 'ci' }}
                >
                  <YouTubeSvg />
                </Link>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://spiritswap.medium.com/"
                  _hover={{ color: 'ci' }}
                >
                  <MediumSvg />
                </Link>
              </HStack>
              <Box
                display={isMobile ? 'flex' : 'block'}
                alignSelf={isMobile ? 'center' : 'left'}
                m={isMobile ? '0px' : '10px'}
              >
                <Box m={isMobile ? '0px 5px' : '5px 0px'}>
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href="https://fantom.foundation/blog/fantom-ecosystem-spotlight-spiritswap/"
                  >
                    About us
                  </Link>
                </Box>
                <Box m={isMobile ? '0px 5px' : '5px 0px'}>
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href="https://info.spiritswap.finance/home"
                  >
                    Analytics
                  </Link>
                </Box>
                <Box m={isMobile ? '0px 5px' : '5px 0px'}>
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.spiritswap.finance/spirit-swap/"
                  >
                    Docs
                  </Link>
                </Box>
              </Box>
            </VStack>

            <SimpleGrid
              columns={isMobile ? 2 : 4}
              gap="20px"
              mt={isMobile ? 10 : 0}
            >
              <VStack align="stretch">
                <Box color="ci" fontWeight="bold">
                  DEX
                </Box>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://defillama.com/protocol/spiritswap"
                >
                  Defi Llama
                </Link>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.coingecko.com/en/exchanges/spiritswap"
                >
                  CoinGecko
                </Link>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://coinmarketcap.com/exchanges/spiritswap/"
                >
                  CoinMarketCap
                </Link>
              </VStack>

              <VStack align="stretch">
                <Box color="ci" fontWeight="bold">
                  SPIRIT Token
                </Box>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.coingecko.com/en/coins/spiritswap"
                >
                  CoinGecko
                </Link>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://coinmarketcap.com/exchanges/spiritswap/"
                >
                  CoinMarketCap
                </Link>
              </VStack>
              <VStack align="stretch">
                <Box color="ci" fontWeight="bold">
                  Exchanges
                </Box>

                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.gate.io/es/trade/SPIRIT_USDT"
                >
                  Gate.io
                </Link>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.mexc.com/exchange/SPIRIT_USDT"
                >
                  MEXC
                </Link>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.hotbit.io/exchange?symbol=SPIRIT_USDT"
                >
                  Hotbit
                </Link>
              </VStack>

              <VStack align="stretch" mt="30px">
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://poloniex.com/"
                >
                  Poloniex
                </Link>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://latoken.com/"
                >
                  LAToken
                </Link>
              </VStack>
            </SimpleGrid>
          </Grid>

          <Flex p="40px 0px 0px 0px" alignItems="center">
            <Text mr="15px" minW="fit-content">
              © 2022 SpiritSwap
            </Text>
            <Box bg="ciTrans15" h="2px" width="100%" />

            <AuditedByDiv>
              <span>{t(`${translationPath}.auditedBy`)}</span>
              <HourGlassImg src={HourGlassPng} alt="hour-glass img" />
            </AuditedByDiv>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
