import { FC, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { NavigationDropdown } from 'app/components/NavigationDropdown';
import {
  HourGlassPng,
  DiscordSvg,
  TwitterSvg,
  GitHubSvg,
  MediumSvg,
  YouTubeSvg,
} from './index';

import { Icon } from 'app/components/Icon';
import type { NavMenuProps } from './Footer.d';
import {
  Wrapper,
  FooterDiv,
  AuditedByDiv,
  HourGlassImg,
  ThirdPartyItemsDiv,
  MenuWrapper,
  StyledNavLink,
  HomeIcon,
  SwapIcon,
  LiquidityIcon,
  DotsIcon,
  MenuLabel,
  MoreButtonWrapper,
  MoreButtonLabel,
  NavDropdownWrapper,
  InSpiritIcon,
} from './styles';

import {
  ANALYTICS,
  BRIDGE,
  DOCS,
  DSYNTHS,
  FARMS,
  GOVERNANCE,
  HOME,
  INSPIRIT,
  LENDANDBORROW,
  LIQUIDITY,
  NFTS,
  STORE,
  SWAP,
} from 'app/router/routes';
import { useAppDispatch } from 'store/hooks';
import { setUnexpectedError } from 'store/errors';

const navMenus = [
  { ...HOME, image: HomeIcon },
  { ...SWAP, image: SwapIcon },
  { ...LIQUIDITY, image: LiquidityIcon },
  { ...INSPIRIT, image: InSpiritIcon },
];

const navDropdownMenus = [
  GOVERNANCE,
  DOCS,
  NFTS,
  ANALYTICS,
  STORE,
  DSYNTHS,
  LENDANDBORROW,
  BRIDGE,
  FARMS,
];

const NavMenuItem: FC<NavMenuProps> = ({ menu, isActive }) => {
  const Image = menu.image;
  const dispatch = useAppDispatch();
  const handleResetError = () => dispatch(setUnexpectedError(false));
  return (
    <StyledNavLink to={menu.path} onClick={handleResetError}>
      <Icon size="24px" icon={<Image selected={isActive} />} />
      <MenuLabel>{menu.title}</MenuLabel>
    </StyledNavLink>
  );
};

const Footer = () => {
  const { t } = useTranslation();
  const translationPath = 'common.footer';
  const menuTranslationPath = 'common.menu';
  const translatedNavMenus = navMenus.map(menu => ({
    title: t(`${menuTranslationPath}.${menu.key}`),
    image: menu.image,
    path: menu.path,
  }));
  const translatedDropdownMenus = navDropdownMenus.map((menu: any) => ({
    title: t(`${menuTranslationPath}.${menu.key}`),
    path: menu.path,
    url: menu.url,
  }));

  const [showDropdown, setShowDropdown] = useState(false);
  const [outsideClicked, setOutsideClicked] = useState(false);

  const location = useLocation();
  const [menuIndex, setMenuIndex] = useState(0);

  const onHideDropdownCallback = useCallback(() => {
    setShowDropdown(false);
    setOutsideClicked(true);
  }, [setShowDropdown, setOutsideClicked]);

  const onDropdownCallback = useCallback(() => {
    !outsideClicked && setShowDropdown(!showDropdown);
  }, [outsideClicked, showDropdown, setShowDropdown]);

  useEffect(() => {
    setMenuIndex(
      [...translatedNavMenus, ...translatedDropdownMenus].findIndex(
        menu => location.pathname === menu.path,
      ),
    );
  }, [location, setMenuIndex, translatedDropdownMenus, translatedNavMenus]);

  useEffect(() => {
    outsideClicked &&
      setTimeout(() => {
        setOutsideClicked(false);
      }, 200);
  }, [outsideClicked]);

  return (
    // menuIndex >= 0 ? (
    <Wrapper>
      <FooterDiv>
        <AuditedByDiv
          target="_blank"
          rel="noreferrer"
          href="https://github.com/Layer3Org/spiritswap-core/blob/main/SpiritSwap-Core%20Security%20Audit%20Report.pdf"
        >
          <span>{t(`${translationPath}.auditedBy`)}</span>
          <HourGlassImg src={HourGlassPng} alt="hour-glass Img" />
        </AuditedByDiv>

        <ThirdPartyItemsDiv>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://discord.gg/8FGd4nFQdT"
          >
            <DiscordSvg />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/Spirit_Swap"
          >
            <TwitterSvg />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/Layer3Org/spiritswap-core/"
          >
            <GitHubSvg />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://spiritswap.medium.com/"
          >
            <MediumSvg />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCrKLtNctO0obN4-bDMGlFuQ"
          >
            <YouTubeSvg />
          </a>
        </ThirdPartyItemsDiv>
      </FooterDiv>
      <MenuWrapper>
        {translatedNavMenus.map((menu, index) => {
          const active = menuIndex === index;
          return (
            <NavMenuItem
              key={`${menu.path}-${active}`}
              menu={menu}
              isActive={active}
            />
          );
        })}
        <MoreButtonWrapper selected={showDropdown} onClick={onDropdownCallback}>
          <Icon size="24px" icon={<DotsIcon selected={showDropdown} />} />
          <MoreButtonLabel>More</MoreButtonLabel>
          <NavDropdownWrapper>
            {showDropdown && (
              <NavigationDropdown
                items={translatedDropdownMenus}
                onClickOutside={onHideDropdownCallback}
              />
            )}
          </NavDropdownWrapper>
        </MoreButtonWrapper>
      </MenuWrapper>
    </Wrapper>
  );
  // ) : null)
};

export default Footer;
