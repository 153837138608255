export { NETWORK } from './networks';
export * from './tokens';

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  Coin98 = 'coin98',
  WalletLink = 'walletlink',
  TrustWallet = 'trustwallet',
  CloverWallet = 'cloverwallet',
}

export const CHAIN_ID = 250; // I think this should be refactored
export const DEFAULT_HANDLER = 'spiritswap';
export const NODE = 'https://rpc.ankr.com/fantom';
export const COVALENT_API_KEY = 'ckey_590efabe5d5e4512b707d50bf8e';
export const GELATO_ADDRESS = '0x59e61b95f20e940ac777e88fa2dfa0a6a4c40fa0';
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;
export const CONNECTOR_LOCAL_STORAGE_KEY = 'SPIRIT_CONNECTOR_KEY';
export const SWAP_SLIPPAGE_TOLERANCE_INDEX_KEY = 'SPIRIT_SWAP_SLIPPAGE_INDEX';
export const SWAP_SLIPPAGE_TOLERANCE_VALUE_KEY = 'SPIRIT_SWAP_SLIPPAGE_VALUE';
export const SWAP_SLIPPAGE_TOLERANCE_CUSTOM_KEY = 'SPIRIT_SWAP_SLIPPAGE_CUSTOM';
export const SWAP_TRANSACTION_DEADLINE_IN_MINUTES_KEY =
  'SPIRIT_SWAP_TX_DEADLINE';
export const SWAP_CHART_MODE_KEY = 'SPIRIT_SWAP_CHART_MODE';
export const SWAP_TRADE_MODE_KEY = 'SPIRIT_SWAP_TRADE_MODE';
export const SAFE_EXECUTE_NUMBER_OF_ATTEMPTS = 5;
export const POLLING_INTERVAL = 1000 * 20; // web3 apis
export const DEFAULT_PROVIDER_NAME = 'ethers.js' || 'web3.js';

export const THROTTLE_TIME = 1000 * 20; // throttle waiting time
export const THROTTLE_CLEAR_TIME = 1000 * 60; // throttle clean time
export const LOADING_TIME = 1000 * 10; // loading time to unthrottle API calls
export const FAST_REFRESH_INTERVAL = 1000 * 10;
export const SLOW_REFRESH_INTERVAL = 1000 * 60;
export const SLOWEST_REFRESH_INTERVAL = 1000 * 60 * 60; // one hour
export const BLOCK_UPDATE_INTERVAL = 70; // How many blocks do we wait for updates to take place
export const ALLOW_V1_V2_MIGRATION = true;
export const WARNSLIP = 10;

export const BASE_TOKEN_ADDRESS = '0x0000000000000000000000000000000000000000';
export const NOTIFICATIONS_STATE = {
  SUCCESS: 'SUCESS',
  PENDING: 'PENDING',
  ERROR: 'ERROR',
};

export const BRIDGE_NAMES = {
  nxtp: 'Connext',
  hop: 'Hop',
  debridge: 'Debridge',
  anyswap: 'Anyswap',
  hyphen: 'Hyphen',
  across: 'across',
  wormhole: 'Wormhole',
};
