import web3Listener from './_web3Listener';
import { CHAIN_ID } from 'constants/index';
import addresses from 'constants/contracts';
import { checkSpiritAllowance, formatFrom } from 'utils/web3';
import { getLimitOrders } from 'utils/swap/gelato';
import {
  getUserBalances,
  getStakedBalances,
  getUserInspiritBalances,
  getBoostedFarmVotes,
  getPendingRewards,
  reconciliateBalances,
  getClaimableBribeRewards,
} from 'utils/data';
import {
  setTokens,
  setLiquidity,
  setStaked,
  setPortfolioValue,
  setPortfolioPercentValue,
  setLockedInSpiritAmount,
  setLockedInSpiritEndDate,
  setInspiritUserBalance,
  setSpiritClaimableAmount,
  setBoostedFarmVotes,
  setSpiritBalance,
  setUserWallet,
  setInspiritAllowance,
  setUserLiquidityWallet,
  setUserSobLiquidityWallet,
  setLimitOrders,
  setFarmRewards,
  setBribesClaimableAmount,
} from 'store/user';
import { safeExecuteNotAsync as safeExecute } from 'utils/safeExecute';
import { connect } from 'utils/web3/connection';

/**
 * Helper method - Checks for existing limit orders associated with the user in gelato
 *  - userWalletAddress: Address of user in the blockchain
 *  - dispatch: callback hook method that allows update of app state
 */
export const checkLimitOrders = async (userWalletAddress, dispatch) => {
  // Checks if allowance exists for
  const limitOrders = await getLimitOrders(userWalletAddress);

  dispatch(setLimitOrders(limitOrders));
};

/**
 * Helper method - Updates allowance related data
 *  - userWalletAddress: Address of user in the blockchain
 *  - dispatch: callback hook method that allows update of app state
 */
export const checkAllowances = async (userWalletAddress, dispatch) => {
  // Checks if allowance exists for
  const inspiritAllowance = await checkSpiritAllowance(
    userWalletAddress,
    addresses.inspirit[CHAIN_ID],
  );

  dispatch(setInspiritAllowance(parseFloat(formatFrom(inspiritAllowance))));
};

/**
 * Helper method - Checks user rewards
 *  - userWalletAddress: Address of user in the blockchain
 *  - dispatch: callback hook method that allows update of app state
 */

export const checkRewards = async (
  userWalletAddress,
  userLpTokens,
  dispatch,
) => {
  const promises: Promise<any>[] = [];
  userLpTokens.forEach(pool => {
    promises.push(getPendingRewards(userWalletAddress, pool.address));
  });

  const results = await Promise.all(promises);

  dispatch(setFarmRewards(results));
};

/**
 * Helper method - Updates portfolio related data
 *  - userWalletAddress: Address of user in the blockchain
 *  - dispatch: callback hook method that allows update of app state
 */
export const updatePortfolioData = async (
  userWalletAddress,
  trackedTokens,
  dispatch,
) => {
  const covalentRawData = await getUserBalances(userWalletAddress);

  if (covalentRawData) {
    const { sobLiquidity } = covalentRawData;
    dispatch(setUserSobLiquidityWallet(sobLiquidity));
  }

  // Verifies tracked tokens are part of the portfolio data from covalent
  // Also multicalls blockchain for balances
  const portfolio = await reconciliateBalances(
    userWalletAddress,
    covalentRawData,
    trackedTokens,
  );

  if (!portfolio) {
    return null;
  }

  const { liquidity, tokens } = portfolio;
  const liquidityWallet = JSON.parse(JSON.stringify(liquidity.farmList));

  dispatch(setTokens(tokens));
  dispatch(setUserWallet(tokens.tokenList));
  dispatch(setUserLiquidityWallet(liquidityWallet));

  let spiritData;

  if (tokens.tokenList) {
    [spiritData] = tokens.tokenList?.filter(token => token.symbol === 'SPIRIT');

    if (spiritData) {
      dispatch(setSpiritBalance(parseFloat(`${spiritData?.amount || 0}`)));
    }
  }

  const stakedBalance = await getStakedBalances(userWalletAddress, liquidity);
  dispatch(setLiquidity(stakedBalance));

  // We run this one to calculate rewards so it runs in the background
  const pureStakesData =
    stakedBalance.farmList?.filter(farm => !farm.staked) || [];
  checkRewards(userWalletAddress, pureStakesData, dispatch);
  dispatch(setStaked(stakedBalance.farmList));

  dispatch(
    setPortfolioValue(tokens.totalValueNumber + stakedBalance.totalValueNumber),
  );
  dispatch(setPortfolioPercentValue(tokens.diffPercentValue));

  const inspiritUserStatistics = await getUserInspiritBalances(
    userWalletAddress,
  );

  dispatch(setInspiritUserBalance(inspiritUserStatistics.userBalance));
  dispatch(setLockedInSpiritAmount(inspiritUserStatistics.userLocked));
  dispatch(
    setLockedInSpiritEndDate(
      parseInt(inspiritUserStatistics.userLockEndDate.toString()),
    ),
  );
  dispatch(
    setSpiritClaimableAmount(inspiritUserStatistics.userClaimableAmount),
  );

  const boostedFarmVotes = await getBoostedFarmVotes({
    userAddress: userWalletAddress,
  });
  dispatch(setBoostedFarmVotes(boostedFarmVotes));

  const claimableBribesRewards = await getClaimableBribeRewards(
    userWalletAddress,
  );
  dispatch(setBribesClaimableAmount(claimableBribesRewards));
};

export const updateUserBalances = async (
  userWalletAddress,
  trackedTokens,
  dispatch,
  block?,
  setLastBlockUpdate?,
) => {
  updatePortfolioData(userWalletAddress, trackedTokens, dispatch);
  checkAllowances(userWalletAddress, dispatch);
  // One limit order check right away
  checkLimitOrders(userWalletAddress, dispatch);
  // Another limit check 15 seconds afterwards
  setTimeout(() => checkLimitOrders(userWalletAddress, dispatch), 15000);

  if (block && setLastBlockUpdate) {
    setLastBlockUpdate(block);
  } else if (setLastBlockUpdate) {
    // We keep record of the block where we updated last
    const { provider } = await connect();
    const lastBlock = await provider.getBlockNumber();
    setLastBlockUpdate(lastBlock);
  }
};

/**
 * Main Method - Sets up connection to rpc server and callback functions meant to update state of app
 *  - userWalletAddress: Address of user in the blockchain
 *  - dispatch: callback hook method that allows update of app state
 */
export const userBalanceListeners = (
  userWalletAddress,
  dispatch,
  trackedTokens,
  lastUpdate?,
  setLastBlockUpdate?,
) => {
  // General updates updating user balance and data
  const updateCallback = (eventName: string, block?: number) => {
    /* TODO: Remove after debugging
    log(
      'Running user balance updates in response to: ',
      eventName || 'initialization',
    ); */
    safeExecute(
      // Initial Attempt to execute balance listener methods immediately
      () =>
        updateUserBalances(
          userWalletAddress,
          trackedTokens,
          dispatch,
          block,
          setLastBlockUpdate,
        ),
      // onFail function
      () => {
        setTimeout(() => {
          updateUserBalances(
            userWalletAddress,
            trackedTokens,
            dispatch,
            block,
            setLastBlockUpdate,
          );
        }, 5000);
      },
    );
  };

  return web3Listener({
    id: 'userBalanceUpdate',
    address: userWalletAddress,
    update: updateCallback,
    lastUpdate: lastUpdate !== undefined ? lastUpdate || 1 : undefined,
  });
};

export default userBalanceListeners;
