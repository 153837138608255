import React, { FC } from 'react';
import { useOnClickOutside } from 'app/hooks/useOnClickOutSide';
import { Props } from './NavigationDropdown.d';
import { TopWrapper, StyledNavLink, CommonLink } from './styles';
import { onClickUrl } from 'app/utils/redirectTab';

const NavigationDropdown: FC<Props> = ({
  items,
  onClickOutside,
  ...props
}: Props) => {
  const ref = useOnClickOutside<HTMLInputElement>(onClickOutside);

  return (
    <TopWrapper ref={ref} {...props}>
      {items.map((item, index) =>
        item.path ? (
          <StyledNavLink
            key={item.path}
            to={item.path}
            onClick={onClickOutside}
          >
            {item.title}
          </StyledNavLink>
        ) : (
          <CommonLink
            key={`external-link-${index}`}
            onClick={onClickUrl(item.url)}
          >
            {item.title}
          </CommonLink>
        ),
      )}
    </TopWrapper>
  );
};

export default NavigationDropdown;
