/**
 * Asynchronously loads the component for LiquidityPage
 */

import { lazyLoad } from 'utils/loadable';

export const BridgePage = lazyLoad(
  () => import('./index'),
  module => module.BridgePage,
);
