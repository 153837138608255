import Notifications from './Notifications/Notifications';
import { useToast } from '@chakra-ui/react';
import { useProgressToastProps } from './Notifications';
import { NOTIFICATIONS_STATE } from 'constants/index';

// TODO [DEV2-273]: make the position customizable

export function useProgressToast() {
  const toast = useToast();
  const showToast = ({
    title,
    type,
    id,
    inputSymbol,
    uniqueMessage,
    outputSymbol,
    inputValue,
    outputValue,
    icon,
    duration = 10000,
    link,
  }: useProgressToastProps) => {
    const closeNotification = (id: string) => {
      toast.close(id);
    };

    const time = type === NOTIFICATIONS_STATE.PENDING ? null : duration;

    if (!toast.isActive(id)) {
      return toast({
        id: id,
        position: 'top-right',
        duration: time,
        render: () => (
          <Notifications
            title={title}
            id={id}
            uniqueMessage={uniqueMessage}
            link={link}
            type={type}
            closeNotification={closeNotification}
            duration={duration}
            inputSymbol={inputSymbol}
            inputValue={inputValue}
            outputSymbol={outputSymbol}
            outputValue={outputValue}
            icon={icon}
          />
        ),
      });
    }
  };
  return { showToast };
}
