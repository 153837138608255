import BigNumber from 'bignumber.js';
import { formatUnits } from 'ethers/lib/utils';
import { CHAIN_ID } from 'constants/index';
import allFarms from 'constants/farms';
import Contracts from 'constants/contracts';
import { IFarm } from 'app/interfaces/Farm';
import { getLiquidityPoolsData, FarmConfigWithStatistics } from 'utils/data';
import { Multicall, Call, Contract } from 'utils/web3';
import ApolloClient from 'utils/apollo/client';
import { gql } from '@apollo/client';
import { PoolDataSummary, QuoteToken } from './types';
import { request } from './covalent';
import { stableSobPools } from 'constants/sobpools';

import {
  stakeGaugePoolToken,
  unstakeGaugePoolToken,
  gaugeHarvest,
  stakePoolToken,
  unstakePoolToken,
  harvest,
  unstakeGaugePoolTokenAll,
  approveFarm,
} from 'utils/web3/actions/farm';
import { SobTokenPool, TokenAmount } from 'app/interfaces/General';
import { GetWhiteListedTokenFromAddres } from 'app/utils/methods';
import { tokenData } from 'utils/data/types';

const FARMS = allFarms;
FARMS.shift();

export const whitelistedLpTokenData = () => {
  const addressMapping = {};

  FARMS.forEach(a => {
    if (a?.lpAddresses[CHAIN_ID]) {
      addressMapping[`${a.lpAddresses[CHAIN_ID]}`.toLowerCase()] = a;
    }
  });

  return addressMapping;
};

export const getLiquityPoolMultiplierWithMultiCall = async (
  farms: IFarm[] = [],
  chainId = CHAIN_ID,
) => {
  const gaugeCalls: Call[] = [];

  const masterChefAddress = Contracts.masterchef[chainId];
  const gaugeProxyAddress = Contracts.gauge[chainId];

  const masterChefContract = await Contract(masterChefAddress, 'masterchef');
  const gaugeProxyContract = await Contract(gaugeProxyAddress, 'gaugeproxy');

  const totalAllocPoint = await masterChefContract.totalAllocPoint();
  const guagePid = await gaugeProxyContract.pid();
  const totalWeight = await gaugeProxyContract.totalWeight();
  const gaugePoolInfo = await masterChefContract.poolInfo(guagePid);
  const gaugeAllocPoint = new BigNumber(gaugePoolInfo.allocPoint._hex).div(
    new BigNumber(totalAllocPoint._hex),
  );

  farms.forEach(farm => {
    const lpAddress = farm.lpAddress;

    gaugeCalls.push(
      // Gauge pool information
      {
        address: gaugeProxyAddress,
        name: 'weights',
        params: [lpAddress], // to LP contract, not GaugeContract (as in InSpirit voting page)
      },
    );
  });

  const gaugeResponse = await Multicall(gaugeCalls, 'gaugeproxy');

  const gatheredMultipliers: string[] = [];

  farms.forEach((farm, index) => {
    const gaugeWeight = new BigNumber(gaugeResponse[index].response[0]._hex);
    const poolWeight = gaugeWeight.div(new BigNumber(totalWeight._hex));
    const muliplier = gaugeAllocPoint.times(poolWeight).times(100).toString();

    gatheredMultipliers.push(muliplier);
  });

  return gatheredMultipliers;
};

export const getLiquityPoolStatisticsWithMulticall = async (
  chainId = CHAIN_ID,
) => {
  const farmdataCalls: Call[] = [];
  const masterChefContract = Contracts.masterchef[chainId];

  FARMS.forEach(farm => {
    const lpAddress = farm?.lpAddresses[chainId];
    const tokenAddress = farm?.tokenAddresses[chainId];
    const quoteTokenAddress = farm?.quoteTokenAdresses[chainId];

    // Push group of requests per farm. We bundle all of them and sort out data after.
    // KEEP TRACK OF NUMBER OF REQUESTS FOR NEXT SECTION
    // CURRENT COUNT = 7
    farmdataCalls.push(
      // Balance of token in LP contract
      {
        address: tokenAddress,
        name: 'balanceOf',
        params: [lpAddress],
      },
      // Balance of quote token on LP contract
      {
        address: quoteTokenAddress,
        name: 'balanceOf',
        params: [lpAddress],
      },
      // Balance of LP tokens in the master chef contract
      {
        address: farm?.isTokenOnly ? tokenAddress : lpAddress,
        name: 'balanceOf',
        params: [masterChefContract],
      },
      // Total supply of LP tokens;
      {
        address: lpAddress,
        name: 'totalSupply',
      },
      // Token decimals
      {
        address: tokenAddress,
        name: 'decimals',
      },
      // Quote token decimals
      {
        address: quoteTokenAddress,
        name: 'decimals',
      },
      // Farm pool information
      {
        address: masterChefContract,
        name: 'poolInfo',
        params: [farm.pid],
      },
    );
  });

  // We retrieve ALL data from here
  const multicallResponse = await Multicall(farmdataCalls, 'erc20');

  // We now loop through the responses to format the data per farm
  const statistics: PoolDataSummary[] = [];

  //  Every 6 pieces of data represents a single farm in order
  for (let x = 0; x < multicallResponse.length; x += 7) {
    const tokenBalanceLP = multicallResponse[x].response[0];
    const quoteTokenBalanceLP = multicallResponse[x + 1]?.response[0];
    const lpTokenBalanceMC = multicallResponse[x + 2]?.response[0];
    const lpTotalSupply = multicallResponse[x + 3]?.response[0];
    const tokenDecimals = multicallResponse[x + 4]?.response[0];
    const quoteTokenDecimals = multicallResponse[x + 5]?.response[0];
    const poolInfo = multicallResponse[x + 6]?.response[0];

    statistics.push({
      tokenBalanceLP,
      quoteTokenBalanceLP,
      lpTokenBalanceMC,
      lpTotalSupply,
      tokenDecimals,
      quoteTokenDecimals,
      poolInfo,
    });
  }

  // Now that we have the grouped data for each farm so we attach it
  const addressMapping = {};

  FARMS.forEach((farm, index) => {
    const copy = JSON.parse(JSON.stringify(farm));
    copy.statistics = statistics[index];
    const {
      tokenBalanceLP,
      quoteTokenBlanceLP,
      lpTokenBalanceMC,
      lpTotalSupply,
      tokenDecimals,
      quoteTokenDecimals,
    } = copy.statistics;

    let tokenAmount;
    let lpTotalInQuoteToken;
    let tokenPriceVsQuote;

    if (farm.isTokenOnly && !farm.isLPToken) {
      tokenAmount = new BigNumber(lpTokenBalanceMC).div(
        new BigNumber(10).pow(tokenDecimals),
      );
      if (
        farm.tokenSymbol === QuoteToken.fUSD &&
        farm.quoteTokenSymbol === QuoteToken.fUSD
      ) {
        tokenPriceVsQuote = new BigNumber(1);
      } else {
        tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(
          new BigNumber(tokenBalanceLP),
        );
      }
      lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote);
    } else {
      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(
        new BigNumber(lpTotalSupply),
      );

      // Total value in staking in quote token value
      lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(new BigNumber(2))
        .times(lpTokenRatio);

      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      tokenAmount = new BigNumber(tokenBalanceLP)
        .div(new BigNumber(10).pow(tokenDecimals))
        .times(lpTokenRatio);
      const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(lpTokenRatio);

      if (tokenAmount.comparedTo(0) > 0) {
        tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount);
      } else {
        tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(
          new BigNumber(tokenBalanceLP),
        );
      }
    }

    copy.statistics.tokenAmount = tokenAmount;
    copy.statistics.lpTotalInQuoteToken = lpTotalInQuoteToken;
    copy.statistics.tokenPriceVsQuote = tokenPriceVsQuote;

    addressMapping[`${farm.lpAddresses[CHAIN_ID]}`.toLowerCase()] = copy;
  });

  return addressMapping;
};

export const getLiquidityPoolFromApollo = async (chainId = CHAIN_ID) => {
  const savedFarms = whitelistedLpTokenData();

  const farmIds = Object.keys(savedFarms).map(address => address.toLowerCase());

  let pairsString = `[`;
  farmIds.map(pair => {
    return (pairsString += `"${pair}"`);
  });
  pairsString += ']';
  const results = await ApolloClient.query({
    query: gql(`
      query pairs {
        pairs (first: 100, where: {
          id_in: ${pairsString}
        }) {
          id,
          token0 {
            name
          },
          token1 {
            name
          },
          reserve0,
          reserve1,
          token0Price,
          token1Price,
          totalSupply 
        }
      }
    `),
    // pollInterval: 500,
  });

  return results;
};

export const getLiquityPoolStatistics = async (chainId = CHAIN_ID) => {
  const poolDataFromCovalent = await getLiquidityPoolsData(chainId);
  const savedFarms = whitelistedLpTokenData();

  poolDataFromCovalent.forEach(data => {
    const farm = savedFarms[`${data.exchange}`.toLowerCase()];

    if (farm) {
      // We add additional data here we'll keep in the statistics object
      const total = data.total_liquidity_quote;
      // const volume = pool.volume_24h_quote;
      const fee = parseFloat(data.fee_24h_quote);

      const lpApyNumber =
        fee && total ? ((fee / total) * 365 * 100 * 5) / 6 : 0;

      const lpApy =
        lpApyNumber && lpApyNumber.toLocaleString('en-US').slice(0, -1);

      const fullApy = new BigNumber(lpApyNumber);

      const fullAPYMin = fullApy.isGreaterThan(0)
        ? fullApy.times(0.4)
        : new BigNumber(0);

      const GaugeAPYMin = fullAPYMin.plus(lpApyNumber);
      const GaugeAPYMax = fullApy.plus(lpApyNumber);
      const AprRange = [GaugeAPYMin.toFormat(2), GaugeAPYMax.toFormat(2)];

      data.lpApyNumber = lpApyNumber;
      data.lpApy = `${lpApy}`;
      data.aprRange = AprRange;
      farm.statistics = data;
    }
  });

  return savedFarms;
};

export const loadFarmsList = async (
  mergeFarms: FarmConfigWithStatistics[] = [],
  chainId = CHAIN_ID,
) => {
  const rawData: FarmConfigWithStatistics[] = Object.values(
    await getLiquityPoolStatistics(),
  );

  const totalData = rawData.concat(mergeFarms);

  const farms: IFarm[] = totalData
    .filter(farm => farm.statistics)
    .map(farm => {
      const { statistics } = farm;
      const { lpApy, lpApyNumber } = statistics;

      const tokens = Object.keys(statistics)
        .filter(key => key.includes('token_'))
        .map(key => statistics[key]);

      const fullApy = new BigNumber(lpApyNumber);

      const fullAPYMin = fullApy.isGreaterThan(0)
        ? fullApy.times(0.4)
        : new BigNumber(0);

      const GaugeAPYMin = fullAPYMin.plus(lpApyNumber);
      const GaugeAPYMax = fullApy.plus(lpApyNumber);
      const AprRange = [GaugeAPYMin.toFormat(2), GaugeAPYMax.toFormat(2)];

      const { isGauge } = farm;

      const lp: IFarm = {
        title: farm?.lpSymbol.replace(' LP', '').replace('-', ' + '),
        tokens: [...tokens.map(token => token.contract_ticker_symbol)],
        lpAddress: farm?.lpAddresses[chainId],
        gaugeAddress: farm?.gaugeAddress,
        aprLabel: 'APY',
        apr: lpApy,
        boosted: false,
        ecosystem: farm?.ecosystem,
        rewardToken: farm?.rewardToken,
        totalLiquidity: statistics.total_liquidity_quote || 0,
        aprRange: AprRange,
        votingWeight: '%',
        boostFactor: '1',
        yourApr: '',
        holdAmountForBoost: 12,
        holdAmountForBoostFactor: 2.5,
        progress: 0,
        spiritEarned: '0',
        spiritEarnedMoney: '0',
        lpTokens: '0',
        lpTokensMoney: '0',
        onConnectWallet: () => {},
        onApprove: async (address: string) => {
          if (farm && farm.gaugeAddress) {
            const tx = await approveFarm(address, farm.gaugeAddress, 250);
            return tx;
          }
          return null;
        },
        onWithdraw: async (_liquidityPoolAmount: string, isMax = false) => {
          if (!_liquidityPoolAmount) {
            // TODO: Possibly add a message for user input error
            return null;
          }
          let tx;

          if (isGauge && farm.gaugeAddress) {
            if (isMax) {
              tx = await unstakeGaugePoolTokenAll(farm.gaugeAddress);
            } else {
              tx = await unstakeGaugePoolToken(
                farm.gaugeAddress,
                _liquidityPoolAmount,
              );
            }
          } else {
            tx = await unstakePoolToken(farm.pid, _liquidityPoolAmount);
          }

          return tx;
        },
        onDeposit: async (_liquidityPoolAmount: string | undefined) => {
          if (!_liquidityPoolAmount) {
            // TODO: Possibly add a message for user input error
            return null;
          }

          let tx;

          if (isGauge && farm.gaugeAddress) {
            tx = await stakeGaugePoolToken(
              farm?.gaugeAddress,
              _liquidityPoolAmount,
            );
          } else {
            tx = await stakePoolToken(farm.pid, _liquidityPoolAmount);
          }

          return tx;
        },
        onClaim: async () => {
          let tx;

          if (isGauge && farm.gaugeAddress) {
            tx = await gaugeHarvest(farm?.gaugeAddress);
          } else {
            tx = await harvest(farm.pid);
          }

          return tx;
        },
      };

      return lp;
    });

  farms.sort((first, second) => second.totalLiquidity - first.totalLiquidity);

  return farms;
};

// TODO: [DEV2-619] analyze from where to get soob pooled data
export const getSobPoolsData = async (userAddres: string) => {
  const source = `https://api.debank.com/portfolio/list?user_addr=${userAddres}&project_id=ftm_beethovenx`;
  const { data } = await request(source);

  if (data && data.portfolio_list) {
    const balancesAndSupply = await getSobPoolUserBalanceAndSupply(userAddres);
    const formattedData = formatSobPoolData(
      data.portfolio_list,
      balancesAndSupply,
    );
    return formattedData;
  }

  return [];
};

export const getSobPoolUserBalanceAndSupply = async (userAddres: string) => {
  let sobPoolCalls: Call[] = [];
  let sobPoolResponse: { balanceOf: string; totalSupply: string }[] = [];
  let sobPoolsAddresses: string[] = [];
  stableSobPools.forEach(stableSobPool => {
    const call = [
      {
        address: stableSobPool.address,
        name: 'balanceOf',
        params: [userAddres],
      },
      {
        address: stableSobPool.address,
        name: 'totalSupply',
      },
    ];
    sobPoolCalls = [...sobPoolCalls, ...call];
    sobPoolResponse[stableSobPool.address] = [
      { balanceOf: '0', totalSupply: '0' },
    ];
    sobPoolsAddresses = [...sobPoolsAddresses, stableSobPool.address];
  });

  if (sobPoolCalls.length > 1) {
    const multicallResponse = await Multicall(sobPoolCalls, 'sobPool');
    let index = 0;
    for (let x = 0; x < multicallResponse.length; x += 2) {
      const balanceOf = multicallResponse[x].response[0];
      const totalSupply = multicallResponse[x + 1]?.response[0];
      sobPoolResponse[sobPoolsAddresses[index]] = {
        balanceOf: balanceOf,
        totalSupply: totalSupply,
      };
      index++;
    }
  }

  return sobPoolResponse;
};

export const formatSobPoolData = (portfolioList, balancesAndSupply) => {
  const sobPoolsAddresses = stableSobPools.map(sobPool => sobPool.address);
  const sobPoolData = portfolioList
    .filter(item => {
      return sobPoolsAddresses.includes(item.pool_id);
    })
    .map(pool => {
      const sobPoolFarm = getSobPoolFarm(pool.pool_id);
      const tokensSymbol = sobPoolFarm.tokens.map(token => token.symbol);
      const tokenAmounts = getDebankTokenAmounts(pool.detail.supply_token_list);
      const poolFormated = {
        name: sobPoolFarm.symbol,
        title: sobPoolFarm.name, // yeap in this way
        full_name: sobPoolFarm.symbol,
        address: pool.pool_id,
        amount: formatUnits(
          balancesAndSupply[pool.pool_id].balanceOf,
          sobPoolFarm.decimals,
        ),
        symbol: sobPoolFarm.symbol,
        rate_24: 0,
        tokens: tokensSymbol,
        liquidity: true,
        staked: true, // no idea of this by now, to search for
        rate: 0, // probably for portfolio related to todo on line 374
        usd: 0,
        usd_24: 0,
        tokensAmounts: tokenAmounts,
        lpSupply: balancesAndSupply[pool.pool_id].totalSupply,
      } as tokenData;
      return poolFormated;
    });
  return sobPoolData;
};

const getSobPoolFarm = (address: string): SobTokenPool => {
  return stableSobPools.find(
    sobPool => sobPool.address === address,
  ) as SobTokenPool;
};

const getDebankTokenAmounts = (tokensDArray): TokenAmount[] => {
  return tokensDArray.map(tokenD => {
    const tokenS = GetWhiteListedTokenFromAddres(tokenD.id);
    const tokenAmount = {
      token: tokenS,
      amount: tokenD.amount as string,
    };
    return tokenAmount;
  });
};
