import { useEffect, useState, useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { setAddress, resetUserStatistics } from 'store/user';
import safeExecute from 'utils/safeExecute';
import { connect } from 'utils/web3';
import {
  CONNECTOR_LOCAL_STORAGE_KEY,
  ConnectorNames,
  CHAIN_ID,
} from 'constants/index';
import { setupNetwork } from 'utils/web3';
import WalletConnectProvider from '@walletconnect/web3-provider';

declare global {
  interface Window {
    coin98: any;
    clover: any;
  }
}

export const walletConnectProvider = new WalletConnectProvider({
  rpc: { [CHAIN_ID]: 'https://rpc.ftm.tools/' },
  chainId: CHAIN_ID,
});

export const CONNECTIONS = [window.ethereum, walletConnectProvider];

export const providersByConnectorName = {
  [ConnectorNames.WalletConnect]: walletConnectProvider,
  [ConnectorNames.Injected]: window.ethereum,
};

export const getProvider = () => {
  const connector =
    localStorage.getItem('CONNECTOR') || ConnectorNames.Injected;
  return providersByConnectorName[connector];
};

const useLogin = () => {
  const [connectorState, setConnectorStatus] = useState<string | null>(() => {
    // Getting local storage variable determining if the app is logged into the app workflow
    const sessionIdentifier = localStorage.getItem(CONNECTOR_LOCAL_STORAGE_KEY);
    return sessionIdentifier;
  });

  const [isLoggedIn, setLoggedIn] = useState<boolean>();

  const dispatch = useAppDispatch();

  const updateLogin = useCallback(
    async (_signer, _login = false) => {
      if (_login) {
        setupNetwork();
        const address = await _signer.getAddress();
        dispatch(setAddress(address));
        setConnectorStatus('active');
        setLoggedIn(true);
      } else {
        dispatch(setAddress(null));
        setConnectorStatus('');
        setLoggedIn(false);
        localStorage.setItem('CONNECTOR', '');
        dispatch(resetUserStatistics());
      }
    },
    [dispatch, setLoggedIn],
  );

  const connectWallet = useCallback(
    (_connector = localStorage.getItem('CONNECTOR') || '') => {
      safeExecute(async () => {
        // This is the user's wallet. ConnectorNames represent the plugin used

        let localConnector;
        if (_connector === ConnectorNames.Coin98) {
          localConnector = window.coin98?.provider;
        }
        if (_connector === ConnectorNames.CloverWallet) {
          localConnector = window.clover;
        }
        if (_connector === ConnectorNames.WalletConnect) {
          return;
          // localConnector = walletConnectProvider
        }

        if (!localConnector) {
          localConnector = window.ethereum;
        }

        localStorage.setItem('CONNECTOR', _connector);
        connect(localConnector, _signer => updateLogin(_signer, true));
      });
    },
    [updateLogin],
  );

  useEffect(() => {
    // Effect is updated if there's an update in the connectorState local storage
    // If localStorge indicate session is still active, connection will be attempted automatically
    if (connectorState) {
      connectWallet();
    }
  }, [connectorState, connectWallet]);

  const handleLogout = () => updateLogin(undefined);
  const handleLogin = (
    _connector: ConnectorNames = ConnectorNames.Injected,
  ) => {
    connectWallet(_connector);
  };

  const refreshWallet = (
    _connector: ConnectorNames = ConnectorNames.Injected,
  ) => connectWallet(_connector);

  return {
    isLoggedIn,
    handleLogin,
    handleLogout,
    refreshWallet,
    _connector: localStorage.getItem('CONNECTOR') || '',
  };
};

export default useLogin;
