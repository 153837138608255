import { createSlice } from '@reduxjs/toolkit';
import { UserCustomToken } from 'app/interfaces/General';

const initialState: {
  spirit_price: number;
  liquidity_pools: Array<any>;
  boosted_farms: Array<any>;
  total_spirit_supply: string;
  total_spirit_value: number;
  average_spirit_unluck_time: string;
  last_spirit_distribution: string;
  last_spirit_distribution_value: 0;
  next_spirit_distribution: '';
  total_spirit_locked: number;
  total_spirit_locked_value: number;
  apr_percentage: number;
  statistics_from: number;
  inspirit_per_spirit: number;
  userCustomTokens: UserCustomToken[];
} = {
  spirit_price: 0,
  liquidity_pools: [],
  boosted_farms: [],
  total_spirit_supply: '',
  total_spirit_value: 0,
  average_spirit_unluck_time: '0',
  last_spirit_distribution: '',
  last_spirit_distribution_value: 0,
  next_spirit_distribution: '',
  total_spirit_locked: 0,
  total_spirit_locked_value: 0,
  apr_percentage: 0,
  inspirit_per_spirit: 0,
  statistics_from: Date.now(),
  userCustomTokens: [],
};

export const generalReducer = createSlice({
  name: 'generalReducer',
  initialState,
  reducers: {
    setSpiritPrice: (state, action) => {
      state.spirit_price = action.payload;
    },
    setLiquidityPools: (state, action) => {
      state.liquidity_pools = action.payload;
    },
    setSpiritSupply: (state, action) => {
      state.total_spirit_supply = action.payload;
    },
    setSpiritValue: (state, action) => {
      state.total_spirit_value = action.payload;
    },
    setAverageSpiritUnlockTime: (state, action) => {
      state.average_spirit_unluck_time = action.payload;
    },
    setLastSpiritDistribution: (state, action) => {
      state.last_spirit_distribution = action.payload;
    },
    setLastSpiritDistributionValue: (state, action) => {
      state.last_spirit_distribution_value = action.payload;
    },
    setNextSpiritDistribution: (state, action) => {
      state.next_spirit_distribution = action.payload;
    },
    setTotalSpiritLocked: (state, action) => {
      state.total_spirit_locked = action.payload;
    },
    setTotalSpiritLockedValue: (state, action) => {
      state.total_spirit_locked_value = action.payload;
    },
    setAprPercentage: (state, action) => {
      state.apr_percentage = action.payload;
    },
    setStatisticsFrom: (state, action) => {
      state.statistics_from = action.payload;
    },
    setInSpiritPerSpirit: (state, action) => {
      state.inspirit_per_spirit = action.payload;
    },
    setBoostedFarms: (state, action) => {
      state.boosted_farms = action.payload;
    },

    setUserCustomTokens: (state, action) => {
      state.userCustomTokens = [...state.userCustomTokens, action.payload];
    },
    removeUserCustomToken: (state, action) => {
      state.userCustomTokens = state.userCustomTokens.filter(
        token => token.address !== action.payload,
      );
    },
  },
});

// Actions
export const {
  setSpiritPrice,
  setLiquidityPools,
  setSpiritSupply,
  setSpiritValue,
  setAverageSpiritUnlockTime,
  setLastSpiritDistribution,
  setLastSpiritDistributionValue,
  setNextSpiritDistribution,
  setTotalSpiritLocked,
  setTotalSpiritLockedValue,
  setAprPercentage,
  setStatisticsFrom,
  setInSpiritPerSpirit,
  setBoostedFarms,
  setUserCustomTokens,
  removeUserCustomToken,
} = generalReducer.actions;

export default generalReducer.reducer;
