import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Box } from '@chakra-ui/react';
import { InspiritPage } from 'app/pages/Inspirit/Loadable';
import { LiquidityPage } from 'app/pages/Liquidity/Loadable';
import { FarmsPage } from 'app/pages/Farms/Loadable';
import { SwapPage } from 'app/pages/Swap/Loadable';
import { BridgePage } from 'app/pages/Bridge/Loadable';
import { HomePage } from 'app/pages/Home/Loadable';
import { ApeModePage } from 'app/pages/ApeMode/Loadable';
import { TopBar } from 'app/layouts/TopBar';
import { Footer } from 'app/layouts/Footer';
import {
  APEMODE,
  BRIDGE,
  FARMS,
  HOME,
  INSPIRIT,
  LIQUIDITY,
  SWAP,
} from './routes';
import ScrollToTop from 'app/components/ScrollToTop/ScrollToTop';
import PageFooter from 'app/components/PageFooter';

import { UnexpectedErrorPage, NotFoundPage } from 'app/pages/Errors';
import { selectUnexpectedError } from 'store/errors/selectors';
import { useAppSelector } from 'store/hooks';

const SiteRouting = () => {
  const unexpectedError = useAppSelector(selectUnexpectedError);
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <TopBar />
        <Box minH="100vh">
          <ErrorBoundary
            FallbackComponent={UnexpectedErrorPage}
            resetKeys={[unexpectedError]}
          >
            <Routes>
              <Route path="/" element={<Navigate to={HOME.path} />} />
              <Route path={HOME.path} element={<HomePage />} />
              <Route path={SWAP.path} element={<SwapPage />} />
              <Route
                path={`${SWAP.path}/address/:address`}
                element={<SwapPage />}
              />
              <Route path={`${SWAP.path}/:token1`} element={<SwapPage />} />{' '}
              <Route
                path={`${SWAP.path}/:token1/:token2`}
                element={<SwapPage />}
              />
              <Route path={BRIDGE.path} element={<BridgePage />} />
              <Route path={LIQUIDITY.path} element={<LiquidityPage />} />
              <Route
                path={`${LIQUIDITY.path}/:token1/:token2`}
                element={<LiquidityPage />}
              />
              <Route
                path={`${LIQUIDITY.path}/:token1/:token2/remove`}
                element={<LiquidityPage />}
              />
              <Route path={FARMS.path} element={<FarmsPage />} />
              <Route path={INSPIRIT.path} element={<InspiritPage />} />
              <Route path={APEMODE.path} element={<ApeModePage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </ErrorBoundary>
        </Box>
        <Footer />
      </BrowserRouter>
      <PageFooter />
    </>
  );
};

export default SiteRouting;
