import { getProvider } from 'app/connectors/EthersConnector/login';
import { Token } from 'app/interfaces/General';
import { formatAmount } from 'app/utils';
import { BigNumber, utils } from 'ethers';
import { SwapQuote } from 'utils/swap';
import { placeLimitOrder } from 'utils/swap/gelato';
import { GeletoLimitParams } from 'utils/swap/types';
import { connect } from '../connection';
import { transactionResponse } from './utils';

export const swapTransaction = async (
  senderAddress,
  quote: SwapQuote,
  firstToken?: Token, // Not filtering directly from whitelist for when using non-whitelisted tokens
  secondToken?: Token,
) => {
  const _connection = getProvider();
  const { signer } = await connect(_connection);
  const MIN_GAS_LIMIT = BigNumber.from('300000');
  const txGas = BigNumber.from(quote.gas);
  // These are the amounts that make the unidex api work every time
  // TODO: Sort out a more dynamic way to set these ones

  const tx = {
    from: senderAddress,
    gasPrice: BigNumber.from(quote.gasPrice),
    gasLimit: MIN_GAS_LIMIT > txGas ? MIN_GAS_LIMIT : txGas,
    to: quote.to,
    value: BigNumber.from(quote.value),
    data: quote.data,
    chainId: quote.chainId,
  };

  const transaction = await signer.sendTransaction(tx);

  return transactionResponse('swap.process', {
    operation: 'SWAP',
    tx: transaction,
    inputSymbol: firstToken ? firstToken.symbol : '',
    inputValue: firstToken
      ? formatAmount(quote.sellAmount, firstToken.decimals)
      : '',
    outputSymbol: secondToken ? secondToken.symbol : '',
    outputValue: secondToken
      ? formatAmount(quote.buyAmount, secondToken.decimals)
      : '',
  });
};

export const placeOrderLimit = async (
  _userAddress,
  _trade: GeletoLimitParams,
  _handler = undefined,
  _chainId = undefined,
) => {
  const _connection = getProvider();
  const { signer } = await connect(_connection);

  const inputAmount = utils.parseUnits(
    _trade.inputAmount,
    _trade.inputDecimals,
  );
  const outputAmount = utils.parseUnits(
    _trade.minReturn,
    _trade.outputDecimals,
  );

  return placeLimitOrder(
    _userAddress,
    signer,
    _trade.inputToken,
    _trade.outputToken,
    inputAmount,
    outputAmount,
    parseFloat(_trade.inputAmount),
  );
};
